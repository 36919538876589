import { toast } from 'react-toastify'
import { put, takeEvery } from 'redux-saga/effects'

import { keyBy, omit } from 'lodash'

import { RESOURCES } from './spec'
import { closeModal } from './modals'
import { getPages } from './utils'

const { resourceCategories: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	isLoading: false,
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	currentResourceCategory: {}
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
			currentPendingRequests = state.pendingRequests + 1
			
			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')

			return {
				...state,
				data: resultsByKey,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				currentResourceCategory: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateResourceCategorySuccess() {
	yield put(closeModal())
	toast.success('Categoria de resurse a fost adăugată cu succes!')
}

function* handleUpdateResourceCategorySuccess() {
	yield put(closeModal())
	toast.success('Modificările au fost salvate!')
}

function* handleDestroyResourceCategorySuccess() {
	yield put(closeModal())
	toast.success('Categoria de resurse a fost ștersă cu succes!')
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateResourceCategorySuccess)
	yield takeEvery(resource.actions.update.success, handleUpdateResourceCategorySuccess)
	yield takeEvery(resource.actions.destroy.success, handleDestroyResourceCategorySuccess)
}
