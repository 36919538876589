import React from 'react'
import { Form, Formik } from 'formik'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { objectKeysToSnakeCase } from '../../../../utils'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

import { validations } from '../../../../assets/validations'

import './AddUserCategoryModal.scss'

export const AddUserCategoryModal = ({ open, userCategoriesErrors, isLoading, createUserCategory }) => {
	return (
		<Modal open={open} title='Adaugă categorie de utilizator'>
			<Formik
				initialValues={{ name: '', discount: 0, daysBeforeAccess: 0 }}
				validationSchema={validations.userCategories}
				onSubmit={(values) => createUserCategory(objectKeysToSnakeCase(values))}
			>
				{({ handleChange, handleBlur, values, handleSubmit, errors, touched }) => (
					<Form className='add-user-category-modal-form-container'>
						<Input
							label='Nume'
							value={values.name}
							placeholder='Numele categoriei'
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
							size='large'
							name='name'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.name}
							fullWidth
						/>
						<Input
							label='Zile prioritare acces'
							value={values.daysBeforeAccess}
							onChange={handleChange('daysBeforeAccess')}
							onBlur={handleBlur('daysBeforeAccess')}
							size='large'
							name='daysBeforeAccess'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.daysBeforeAccess}
							fullWidth
						/>
						<Input
							label='Discount'
							value={values.discount}
							placeholder='Discountul categoriei'
							onChange={handleChange('discount')}
							onBlur={handleBlur('discount')}
							size='large'
							name='discount'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.discount}
							fullWidth
						/>
						<Button
							title='Salvează categoria de utilizator'
							onClick={handleSubmit}
							loading={isLoading}
							type='submit'
							size='large'
							fullWidth
						/>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.ADD_USER_CATEGORY,
	userCategoriesErrors: state.userCategories.errors,
	isLoading: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createUserCategory: (values) => dispatch(RESOURCES.userCategories.create(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserCategoryModal)
