export const columns = [
	{
		Header: 'Nume categorie',
		accessor: 'name'
	},
	{
		Header: 'Brand',
		accessor: 'brand',
		Cell: ({ value: brand }) => brand?.name || ''
	},
	{
		Header: 'Imagine',
		accessor: ({ image_url, icon_url }) => image_url ? image_url : icon_url,
		Cell: ({ value: image_url}) => <img src={image_url} alt='' className='category-icon' />
	},
	{
		Header: 'Nr. ordine',
		accessor: 'order'
	}
]
