import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'

import { isEmpty, values as _values } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes } from '../../redux/modals'
import { createFolder } from '../../redux/folders'

import { objectKeysToSnakeCase } from '../../utils'
import { validations } from '../../assets/validations'

import { Modal } from '../Modal'
import { Input } from '../Input'
import { Toggle } from '../Toggle'
import { Select } from '../Select'
import { Button } from '../Button'
import { FoldersTree } from '../FoldersTree'

import './AddFolderModal.scss'

export const AddFolderModal = ({
	open,
	foldersErrors,
	selectedCategory,
	folders,
	createFolder,
	users,
	isLoadingUsers,
	listUsers
}) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(listUsers, [open])

	return (
		<Modal open={open} title='Adaugă folder'>
			<Formik
				initialValues={{
					name: '',
					parentID: null,
					private: false,
					users: []
				}}
				validationSchema={validations.folders}
				onSubmit={(values) => {
					const folderData = {
						...objectKeysToSnakeCase(values),
						parent_id: Number.parseInt(values.parentID),
						users: values.users.map((user) => user.id)
					}

					if (values.parentID) {
						createFolder(selectedCategory.id, folderData)
					}
				}}
			>
				{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
					<Form className='add-folder-modal-form-container'>
						<Input
							label='Nume folder'
							value={values.name}
							placeholder='Introdu numele folderului'
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
							size='large'
							name='name'
							errors={foldersErrors}
							frontendErrors={errors}
							touched={touched.name}
							fullWidth
						/>
						<div className='choose-parent-folder-container'>
							<p className='choose-parent-folder-label'>Alegeți folderul parinte</p>
							{!isEmpty(folders) && (
								<FoldersTree
									folders={folders}
									onSelect={(selectedFolderID) => setFieldValue('parentID', selectedFolderID)}
									disableAdding
								/>
							)}
							{!values.parentID && <p className='disclaimer-text'>Folderul parinte este obligatoriu!</p>}
						</div>
						<Toggle
							label='Folder privat'
							checked={values.private}
							onChange={(checked) => setFieldValue('private', checked)}
						/>
						{values.private && (
							<Select
								label='Utilizatori acces'
								value={values.users}
								options={users}
								onChange={(option) => setFieldValue('users', option)}
								getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
								getOptionValue={(option) => option.id}
								loading={isLoadingUsers}
								isMulti
							/>
						)}
						<Button title='Salvează folderul' onClick={handleSubmit} type='submit' size='large' fullWidth />
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.ADD_FOLDER,
	folders: _values(state.folders.data),
	foldersErrors: state.folders.errors,
	users: _values(state.users.data),
	isLoadingUsers: state.users.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createFolder: (resourceCategoryID, data) => dispatch(createFolder(resourceCategoryID, data)),
	listUsers: () => dispatch(RESOURCES.users.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFolderModal)
