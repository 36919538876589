import React, { useEffect, useState } from 'react'
import {
	ArrowsPointingOutIcon,
	CheckIcon,
	FolderPlusIcon,
	PencilSquareIcon,
	TrashIcon
} from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'

import { values, isEmpty, isNull, find, toString, flatMapDeep } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { listFolders, updateFolder, destroyFolder, createFolder, moveFolder } from '../../../../redux/folders'
import { modalTypes } from '../../../../redux/modals'

import { objectKeysToSnakeCase } from '../../../../utils'

import { Tabs } from '../../../../components/Tabs'
import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Toggle } from '../../../../components/Toggle'
import { Select } from '../../../../components/Select'
import { Button } from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'
import { FoldersTree } from '../../../../components/FoldersTree'
import { AddFolderModal } from '../../../../components/AddFolderModal'

import './ManageFoldersModal.scss'

const tabTypes = {
	ADD_FOLDER: 'ADD_FOLDER',
	EDIT_FOLDER: 'EDIT_FOLDER',
	MOVE_FOLDER: 'MOVE_FOLDER',
	DELETE_FOLDER: 'DELETE_FOLDER'
}

const readOnlyTabs = [
	{
		title: (
			<div className='tab-title'>
				Folder nou <FolderPlusIcon className='tab-icon' />
			</div>
		),
		type: tabTypes.ADD_FOLDER
	}
]

const tabs = [
	{
		title: (
			<div className='tab-title'>
				Folder nou <FolderPlusIcon className='tab-icon' />
			</div>
		),
		type: tabTypes.ADD_FOLDER
	},
	{
		title: (
			<div className='tab-title'>
				Editează folder <PencilSquareIcon className='tab-icon' />
			</div>
		),
		type: tabTypes.EDIT_FOLDER
	},
	{
		title: (
			<div className='tab-title'>
				Mută folder <ArrowsPointingOutIcon className='tab-icon' />
			</div>
		),
		type: tabTypes.MOVE_FOLDER
	},
	{
		title: (
			<div className='tab-title delete'>
				Șterge folder <TrashIcon className='tab-icon' />
			</div>
		),
		color: 'red',
		type: tabTypes.DELETE_FOLDER
	}
]

export const ManageFoldersModal = ({
	open,
	resourceCategory,
	folders,
	foldersErrors,
	isLoadingFolders,
	createFolder,
	listFolders,
	updateFolder,
	moveFolder,
	deleteFolder,
	users,
	isLoadingUsers,
	listUsers
}) => {
	const [selectedFolderID, setSelectedFolderID] = useState(null)
	const [selectedTab, setSelectedTab] = useState(null)
	const [selectedFolder, setSelectedFolder] = useState(null)

	useEffect(() => {
		const allFolders = flatMapDeep(folders, getFolders)
		if (!isNull(selectedFolderID) && selectedFolder?.id !== selectedFolderID) {
			setSelectedFolder(find(allFolders, (folder) => toString(selectedFolderID) === toString(folder.id)))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFolderID])

	useEffect(() => {
		if (!isEmpty(resourceCategory)) {
			listFolders(resourceCategory.id)
			resetSelectedFolder()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resourceCategory])

	useEffect(() => {
		resetSelectedFolder()
		listUsers()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	useEffect(() => {
		if (!isLoadingFolders && !foldersErrors) {
			resetSelectedFolder()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingFolders])

	const resetSelectedFolder = () => {
		setSelectedFolderID(null)
		setSelectedTab(null)
	}

	const getFolders = (f) => {
		const folder = { ...f } // copy
		delete folder.children

		if (!f.children || !f.children.length) {
			return folder // return copied
		}

		// return copied, but pass original to flatMapDeep
		return [folder, flatMapDeep(f.children, getFolders)]
	}

	return (
		<Modal open={open} title='Administrează foldere' onClose={() => resetSelectedFolder()}>
			<div className='manage-folders-modal-container'>
				{!isEmpty(folders) && !isLoadingFolders ? (
					<>
						{isNull(selectedFolderID) && (
							<p className='select-folder'>Selectează un folder pentru a vedea acțiunile disponibile</p>
						)}
						<FoldersTree
							folders={folders}
							isLoading={isLoadingFolders}
							onSelect={(folderID) => {
								setSelectedTab(null)
								setSelectedFolderID(selectedFolderID === folderID ? null : folderID)
							}}
							selectedKey={String(selectedFolderID)}
							disableAdding
						/>
						<AddFolderModal selectedCategory={resourceCategory} />
						{!isNull(selectedFolderID) ? (
							<div className='actions-container'>
								<Tabs
									tabs={!selectedFolder?.read_only ? tabs : readOnlyTabs}
									onChangeTab={(tab) => setSelectedTab(tab)}
									initialSelectedTab={selectedTab}
								/>
								{selectedTab?.type === tabTypes.ADD_FOLDER && (
									<Formik
										initialValues={{
											name: '',
											private: false,
											users: []
										}}
										onSubmit={(values) => {
											const folderData = {
												...objectKeysToSnakeCase(values),
												parent_id: Number.parseInt(selectedFolderID),
												users: values.users.map((user) => user.id)
											}

											createFolder(resourceCategory.id, folderData)
										}}
									>
										{({ handleChange, setFieldValue, values, handleSubmit }) => (
											<Form className='folder-form-container'>
												<Input
													label='Denumire folder'
													value={values.name}
													onChange={handleChange('name')}
													placeholder='Introdu denumirea folderului'
													size='large'
													name='name'
													errors={foldersErrors}
													fullWidth
												/>
												<Toggle
													label='Folder privat'
													checked={values.private}
													onChange={(checked) => setFieldValue('private', checked)}
												/>
												{values.private && (
													<Select
														label='Utilizatori acces'
														value={values.users}
														options={users}
														onChange={(option) => setFieldValue('users', option)}
														getOptionLabel={(option) =>
															`${option.first_name} ${option.last_name}`
														}
														getOptionValue={(option) => option.id}
														loading={isLoadingUsers}
														size='large'
														isMulti
														fullWidth
													/>
												)}
												<div className='button-container'>
													<Button
														title='Salvează'
														onClick={handleSubmit}
														loading={isLoadingFolders}
														disabled={isEmpty(values.name)}
														icon={() => <CheckIcon />}
														type='submit'
														size='large'
													/>
												</div>
											</Form>
										)}
									</Formik>
								)}
								{selectedTab?.type === tabTypes.EDIT_FOLDER && (
									<Formik
										initialValues={{
											name: selectedFolder.name || '',
											private: selectedFolder.private,
											users: selectedFolder.users || []
										}}
										onSubmit={(values) => {
											updateFolder(resourceCategory.id, selectedFolderID, {
												...objectKeysToSnakeCase(values),
												users: values.users.map((user) => user.id)
											})
										}}
									>
										{({ handleChange, setFieldValue, values, handleSubmit }) => (
											<Form className='folder-form-container'>
												<Input
													label='Denumire folder'
													value={values.name}
													onChange={handleChange('name')}
													placeholder='Introdu denumirea folderului'
													size='large'
													name='name'
													errors={foldersErrors}
													fullWidth
												/>
												<Toggle
													label='Folder privat'
													checked={values.private}
													onChange={(checked) => setFieldValue('private', checked)}
												/>
												{values.private && (
													<Select
														label='Utilizatori acces'
														value={values.users}
														options={users}
														onChange={(option) => setFieldValue('users', option)}
														getOptionLabel={(option) =>
															`${option.first_name} ${option.last_name}`
														}
														getOptionValue={(option) => option.id}
														loading={isLoadingUsers}
														size='large'
														name='users'
														errors={foldersErrors}
														isMulti
														fullWidth
													/>
												)}
												<div className='button-container'>
													<Button
														title='Salvează'
														onClick={handleSubmit}
														loading={isLoadingFolders}
														disabled={isEmpty(values.name)}
														icon={() => <CheckIcon />}
														type='submit'
														size='large'
													/>
												</div>
											</Form>
										)}
									</Formik>
								)}
								{selectedTab?.type === tabTypes.MOVE_FOLDER && (
									<Formik
										initialValues={{ parentID: null }}
										onSubmit={(values) => {
											moveFolder(resourceCategory.id, selectedFolderID, values.parentID)
										}}
									>
										{({ handleChange, setFieldValue, values, handleSubmit }) => (
											<Form className='move-folder-section'>
												<div className='select-parent-folder-text'>
													Selectează folderul în care vrei să muți folderul selectat
												</div>
												<FoldersTree
													folders={folders}
													isLoading={isLoadingFolders}
													onSelect={(folderID) => setFieldValue('parentID', folderID)}
													selectedKey={String(values.parentID)}
													disableAdding
												/>
												<div className='button-container'>
													<Button
														title='Salvează'
														onClick={handleSubmit}
														loading={isLoadingFolders}
														disabled={isNull(values.parentID)}
														icon={() => <CheckIcon />}
														type='submit'
														size='large'
													/>
												</div>
											</Form>
										)}
									</Formik>
								)}
								{selectedTab?.type === tabTypes.DELETE_FOLDER && (
									<div className='delete-folder-section'>
										<p className='delete-text'>
											Ești sigur că vrei să ștergi folderul selectat?
											<br />
											<span>Tot conținutul acestuia va fi șters împreună cu el!</span>
										</p>
										<Button
											title='Da, șterge'
											onClick={() => {
												deleteFolder(resourceCategory.id, selectedFolderID)
												resetSelectedFolder()
											}}
											icon={() => <TrashIcon />}
											color='red'
										/>
									</div>
								)}
							</div>
						) : selectedFolder?.read_only ? (
							<p className='read-only-disclaimer'>Folderele părinte nu pot fi editate</p>
						) : null}
					</>
				) : isLoadingFolders ? (
					<div className='loader-container'>
						<Loader />
					</div>
				) : (
					<p>Nu s-au gasit foldere pentru categoria selectată!</p>
				)}
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.MANAGE_FOLDERS,
	folders: values(state.folders.data),
	foldersErrors: state.folders.errors,
	isLoadingFolders: state.folders.isLoading,
	users: values(state.users.data),
	isLoadingUsers: state.users.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listFolders: (resourceCategoryID, params) => dispatch(listFolders(resourceCategoryID, params)),
	createFolder: (resourceCategoryID, data) => dispatch(createFolder(resourceCategoryID, data)),
	updateFolder: (resourceCategoryID, folderID, data) => dispatch(updateFolder(resourceCategoryID, folderID, data)),
	moveFolder: (resourceCategoryID, folderID, parentID) =>
		dispatch(moveFolder(resourceCategoryID, folderID, parentID)),
	deleteFolder: (resourceCategoryID, folderID) => dispatch(destroyFolder(resourceCategoryID, folderID)),
	listUsers: () => dispatch(RESOURCES.users.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageFoldersModal)
