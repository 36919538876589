import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { isEmpty, values, debounce, isNull, orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { shopAdminSidebarItems } from '../../utils'

import { columns } from './constants'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { PageHeader } from '../../components/PageHeader'
import { Datatable } from '../../components/Datatable'
import { Sidebar } from '../../components/Sidebar'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'

import './Orders.scss'

export class Orders extends Component {
	constructor() {
		super()

		this.state = {
			ordersQuery: '',
			status: null
		}
	}

	componentDidMount = () => {
		this.handleListOrders()
		this.props.listOrderStatuses()
	}

	handleListOrders = (search = this.state.ordersQuery, status = this.state.status, page = 1) => {
		const { listOrders, isLoading } = this.props

		let params = {}

		if (page >= 1) {
			params = { ...params, page }
		}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isEmpty(status)) {
			params = { ...params, status_id: status.id }
		}

		if (!isLoading) {
			listOrders(params)
		}
	}

	debounceSearch = debounce((query) => this.handleListOrders(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ ordersQuery: query })
		this.debounceSearch(query)
	}

	render() {
		const { ordersQuery, status } = this.state
		const { user, orders, isLoading, pages, orderStatuses, isLoadingStatuses } = this.props

		return (
			<div className='orders-page-container'>
				{user.is_admin && <Sidebar items={shopAdminSidebarItems} />}
				<div className='orders-page-content-container'>
					<div className='orders-page-header-container'>
						<PageHeader pageTitle='Comenzi Shop' disableShadow />
						{isLoading && <Loader />}
					</div>
					<div className='search-container'>
						<div className='filters-left'>
							<Input
								value={ordersQuery}
								placeholder='Caută comenzi'
								onChange={(e) => this.handleChangeSearch(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
								size='large'
							/>
						</div>

						<div className='filters-right'>
							<Select
								label='Status'
								value={status}
								options={orderStatuses}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => {
									this.setState({ status: option })
									this.handleListOrders(ordersQuery, option)
								}}
								disabled={isLoadingStatuses}
								labelInside
								isClearable
								fullWidth
							/>
						</div>
					</div>
					{!isEmpty(orders) ? (
						<div className='orders-page-content'>
							<Datatable
								data={orders}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'details',
										Cell: ({ row: { original: order } }) => (
											<Link to={`/orders/${order.id}`}>
												<Button title='Vezi detalii' />
											</Link>
										)
									}
								]}
								hasNextPage={!isNull(pages.next)}
								loadMoreText='Încarcă mai multe comenzi'
								onLoadMore={() => this.handleListOrders(ordersQuery, status, pages.next)}
							/>
						</div>
					) : (
						<div className='no-orders-container'>
							<p className='no-orders-text'>Nu există comenzi</p>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	orders: orderBy(values(state.orders.data), 'id', 'desc'),
	pages: state.orders.pages,
	isLoading: state.orders.isLoading,
	orderStatuses: values(state.orderStatuses.data),
	isLoadingStatuses: state.orderStatuses.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listOrders: (params) => dispatch(RESOURCES.orders.list(params)),
	listOrderStatuses: () => dispatch(RESOURCES.orderStatuses.list()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
