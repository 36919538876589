import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { connect } from 'react-redux'
import { closeModal } from '../../redux/modals'

import { Button } from '../Button'

import './Modal.scss'

export const Modal = ({ open, onClose, closeModal, disableClosing, title, children, removeHeader, className }) => {
	const handleClose = () => {
		onClose && onClose()
		!disableClosing && closeModal()
	}

	return (
		<div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as='div' className='fixed z-10 inset-0' onClose={handleClose}>
					<div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<div className={`edy-optic-modal-container ${className}`}>
								{!removeHeader && (
									<div className='edy-optic-modal-header'>
										<p className='edy-optic-modal-title'>{title}</p>
										<div className='edy-optic-modal-close-button-container'>
											<Button
												icon={() => <XMarkIcon className='edy-optic-modal-close-button-icon' />}
												onClick={handleClose}
											/>
										</div>
									</div>
								)}
								<div className='edy-optic-modal-content-container'>{children}</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(closeModal())
})

export default connect(null, mapDispatchToProps)(Modal)
