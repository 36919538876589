import React, { Component } from 'react'
import { Form, Formik } from 'formik'

import { values as _values, isEmpty, find, isNull } from 'lodash'

import { connect } from 'react-redux'
import { modalTypes } from '../../../../redux/modals'
import { RESOURCES } from '../../../../redux/spec'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Select } from '../../../../components/Select'
import { Button } from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'
import { IconSelector } from '../../../../components/IconSelector'

import { snakeCase } from 'lodash'
import { validations } from '../../../../assets/validations'

import { ImageDropzone } from '../../../../components/ImageDropzone'

import './EditProductCategoryModal.scss'
import { Toggle } from '../../../../components/Toggle'
import { Textarea } from '../../../../components/Textarea'

class EditProductCategoryModal extends Component {
	componentDidMount = () => {
		const { listBrands, isLoadingBrands } = this.props
		if(!isLoadingBrands) {
			listBrands()		
		}
	}

	componentDidUpdate = (prevProps) => {
		const { productCategory, isLoadingResourceCategories, listResourceCategories } = this.props

		if(!isLoadingResourceCategories && prevProps.productCategory?.brand_id !== productCategory.brand_id) {
			if(productCategory?.brand_id) {
				listResourceCategories(productCategory.brand_id)
			}
		}
	}

	findBrand = () => {
		const { productCategory, brands } = this.props

		return find(brands, (brand) => brand.id === productCategory.brand.id)
	}

	render() {
		const {
			open,
			productCategoriesErrors,
			isLoading,
			brands,
			isLoadingBrands,
			productCategory,
			updateProductCategory,
			listResourceCategories,
			isLoadingResourceCategories,
			resourceCategories
		} = this.props

		return (
			<Modal open={open && !isEmpty(productCategory)} title='Editează categorie de produse'>
				{!isEmpty(productCategory) && !isEmpty(brands) && !isLoadingBrands ? (
					<Formik
						initialValues={{
							name: productCategory.name,
							order: productCategory.order,
							brand: this.findBrand(),
							resource_category: find(resourceCategories, ['id', productCategory.resource_category_id]),
							icon: { name: productCategory.icon, url: productCategory.icon_url },
							image: null,
							uploadImage: !!productCategory.image_url,
							site_published: !!productCategory.site_published,
							external_link: productCategory.external_link || '',
							short_description: productCategory.short_description || ''
						}}
						enableReinitialize
						validationSchema={validations.productCategories}
						onSubmit={(values) => {
							let formData = new FormData()
							const keys = Object.keys(values)
							
							keys.forEach(key => {
								if(key === 'icon') {
									if(values.icon) {
										formData.append('icon', values.icon.name)
									}
								}
								else if(key === 'brand') {
									if(values.brand) {
										formData.append('brand_id', values.brand?.id || productCategory.brand_id)
									}
								}
								else if(key === 'resource_category') {
									if(values.resource_category) {
										formData.append('resource_category_id', values.resource_category?.id || null)
									}
								}
								else if(key === 'image') {
									if(values.image) {
										formData.append('image', values.image, values.image.name)
									}
								}
								else {
									formData.append(snakeCase(key), values[key])
								}
							})

							updateProductCategory(formData, productCategory.id)
						}}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<Form className='edit-product-category-modal-form-container'>
								<Input
									label='Nr. Ordine'
									value={values.order}
									onChange={handleChange('order')}
									onBlur={handleBlur('order')}
									size='large'
									name='order'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.order}
									type='number'
									fullWidth
								/>
								<Input
									label='Nume'
									value={values.name}
									placeholder='Numele categoriei'
									onChange={handleChange('name')}
									onBlur={handleBlur('name')}
									size='large'
									name='name'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.name}
									fullWidth
								/>
								<Select
									label='Brand'
									placeholder='Alege brand-ul'
									size='large'
									value={values.brand}
									options={brands}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
									onChange={(option) => {
										setFieldValue('brand', option)
										setFieldValue('resource_category', null)

										if (!isNull(option)) {
											listResourceCategories(option.id)
										}
									}}
									onBlur={handleBlur('brand')}
									name='brand'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.brand}
									fullWidth
								/>
								<Select
									label='Categorie resurse'
									placeholder='Alege categoria'
									size='large'
									value={values.resource_category}
									options={resourceCategories}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
									onChange={(option) => {
										setFieldValue('resource_category', option)
									}}
									onBlur={handleBlur('resource_category')}
									disabled={isNull(values.brand) || isLoadingResourceCategories}
									name='resource_category'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.resource_category}
									isClearable
									fullWidth
								/>
								<Toggle
									label='Afișare pe site'
									checked={values.site_published}
									onChange={(value) => {
										setFieldValue('site_published', value)
									}}
								/>
								<Input
									label='Link extern'
									value={values.external_link}
									placeholder='Link extern'
									onChange={handleChange('external_link')}
									onBlur={handleBlur('external_link')}
									size='large'
									name='external_link'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.external_link}
									fullWidth
								/>
								<Textarea
									label='Descriere scurta'
									value={values.short_description}
									onChange={handleChange('short_description')}
									placeholder='Introdu descrirea scurta brandului'
									onBlur={handleBlur('short_description')}
									size='large'
									name='short_description'
									errors={productCategoriesErrors}
									frontendErrors={errors}
									touched={touched.short_description}
									fullWidth
								/>
								<Button
									title={values.uploadImage ? 'Alege iconita' : 'Incarca imagine'}
									onClick={() => setFieldValue('uploadImage', !values.uploadImage)}
									type='button'
								/>
								{!values.uploadImage ? (
									<>
										<div className='select-icon-container'>
											<p className='select-icon-text'>Imagine categorie selectată:</p>
											<div className='selected-icon-container'>
												{!isNull(values.icon) ? (
													<div className='icon-info'>
														<img src={values.icon.url} alt={values.icon.name} className='icon-image' />
														<p className='icon-name'>{values.icon.name}</p>
													</div>
												) : (
													<span>Selectează o imagine mai jos</span>
												)}
											</div>
										</div>
										<IconSelector 
											initialSelectedIcon={productCategory.icon}
											onSelectIcon={(icon) => setFieldValue('icon', icon)} 
										/>
									</>
								) : (
									<ImageDropzone
										label='Incarca imagine'
										initialImageUrl={productCategory.image_url}
										onChangeFiles={(files) => setFieldValue('image', files[0])}
									/>
								)}
								<Button
									title='Salvează categoria de produse'
									onClick={handleSubmit}
									loading={isLoading}
									type='submit'
									size='large'
									fullWidth
								/>
							</Form>
						)}
					</Formik>
				) : isLoadingBrands ? (
					<div className='loader-container'>
						<Loader />
					</div>
				) : null}
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.EDIT_PRODUCT_CATEGORY,
	productCategoriesErrors: state.productCategories.errors,
	isLoading: state.productCategories.isLoading,
	brands: _values(state.brands.data),
	isLoadingBrands: state.brands.isLoading,
	resourceCategories: _values(state.resourceCategories.data),
	isLoadingResourceCategories: state.resourceCategories.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listResourceCategories: (brandID) => dispatch(RESOURCES.resourceCategories.list({ brand_id: brandID })),
	updateProductCategory: (productCategoryData, productCategoryID) =>
		dispatch(RESOURCES.productCategories.update(productCategoryData, productCategoryID, { 'content-type': 'multipart/form-data' }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProductCategoryModal)
