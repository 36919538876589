const twColors = require('tailwindcss/colors')

const edyOpticBlue = {
	900: '#001945',
	800: '#00286E',
	700: '#0C3F97',
	600: '#215BC0',
	500: '#3E7CE9',
	400: '#649CFC',
	300: '#87B3FF',
	200: '#A9C8FF',
	100: '#CADEFF',
	50: '#ECF3FF'
}

const colors = {
	blue: edyOpticBlue,
	yellow: twColors.amber,
	green: twColors.emerald,
	gray: twColors.gray,
	red: twColors.red,
	primary: {
		light: edyOpticBlue[600],
		DEFAULT: edyOpticBlue[700],
		dark: edyOpticBlue[800]
	},
	error: {
		light: twColors.red[400],
		DEFAULT: twColors.red[500],
		dark: twColors.red[600]
	},
	success: {
		light: twColors.emerald[400],
		DEFAULT: twColors.emerald[500],
		dark: twColors.emerald[600]
	},
	warning: {
		light: twColors.amber[400],
		DEFAULT: twColors.amber[500],
		dark: twColors.amber[600]
	}
}

module.exports = colors
