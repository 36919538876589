import { createStore as createReduxStore, applyMiddleware, combineReducers } from 'redux'

import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter } from 'connected-react-router'

import { all } from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'

import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

import * as api from './api'
import * as app from './app'
import * as auth from './auth'
import * as brands from './brands'
import * as cart from './cart'
import * as folders from './folders'
import * as icons from './icons'
import * as modals from './modals'
import * as news from './news'
import * as orders from './orders'
import * as orderStatuses from './orderStatuses'
import * as productCategories from './productCategories'
import * as products from './products'
import * as resourceCategories from './resourceCategories'
import * as resources from './resources'
import * as userCategories from './userCategories'
import * as users from './users'
import routerLocations from './router'

import { env, environmentTypes } from '../assets/utils'

function* rootSaga() {
	yield all([
		api.saga(),
		app.saga(),
		auth.saga(),
		brands.saga(),
		cart.saga(),
		folders.saga(),
		news.saga(),
		orders.saga(),
		productCategories.saga(),
		products.saga(),
		resourceCategories.saga(),
		resources.saga(),
		userCategories.saga(),
		users.saga()
	])
}

const persistConfig = {
	timeout: 0,
	key: 'portaledyoptic',
	storage: storage,
	whitelist: ['users', 'cart']
}

const rootReducer = (history) =>
	combineReducers({
		api: api.reducer,
		app: app.reducer,
		auth: auth.reducer,
		brands: brands.reducer,
		cart: cart.reducer,
		folders: folders.reducer,
		icons: icons.reducer,
		modals: modals.reducer,
		news: news.reducer,
		orders: orders.reducer,
		orderStatuses: orderStatuses.reducer,
		productCategories: productCategories.reducer,
		products: products.reducer,
		resourceCategories: resourceCategories.reducer,
		resources: resources.reducer,
		router: connectRouter(history),
		routerLocations: routerLocations,
		userCategories: userCategories.reducer,
		users: users.reducer
	})

export const history = createBrowserHistory()

const persistedRootReducer = persistReducer(persistConfig, rootReducer(history))

export function createRootStore(initialState = {}) {
	const sagaMiddleware = createSagaMiddleware()

	const store = createReduxStore(
		persistedRootReducer,
		initialState,
		applyMiddleware(routerMiddleware(history), sagaMiddleware)
	)

	sagaMiddleware.run(rootSaga)

	if (env === environmentTypes.DEV) {
		// store.subscribe(() => console.log(store.getState()))
	}

	return store
}

export const store = createRootStore()
export const persistor = persistStore(store)
