import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
	name: Yup.string().label('Nume').required('Numele este obligatoriu!'),
	description: Yup.string().label('Descriere').notRequired(),
	brand: Yup.object().label('Brand').required('Brand-ul este obligatoriu!').nullable(),
	category: Yup.object().label('Categorie').required('Categoria e obligatorie!').nullable(),
	orderOnly: Yup.boolean(),
	price: Yup.number()
		.label('Preț')
		.when('orderOnly', {
			is: false,
			then: Yup.number().min(1, 'Prețul minim este de 1 RON').required('Prețul e obligatoriu!')
		})
})
