import React, { Component } from 'react'
import { PlusIcon, PencilSquareIcon, TrashIcon, CircleStackIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { columns } from './constants'

import { brandsSidebarItems, navigate } from '../../utils'

import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Sidebar } from '../../components/Sidebar'
import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import { AddBrandModal, DeleteBrandModal, EditBrandModal } from './partials'

import './Brands.scss'

export class Brands extends Component {
	constructor() {
		super()

		this.state = {
			selectedBrand: {}
		}
	}

	componentDidMount() {
		const { listBrands } = this.props

		listBrands()
	}

	render() {
		const { selectedBrand } = this.state
		const { brands, isLoading, openModal } = this.props

		return (
			<div className='brands-page-container'>
				<Sidebar items={brandsSidebarItems} />
				<div className='brands-page-content-container'>
					<div className='brands-page-header-container'>
						<PageHeader pageTitle='Branduri' parentRoute='/home' disableShadow />
						{!isLoading ? (
							<Link to='/add-brand'>
								<Button
									title='Adaugă Brand'
									icon={() => <PlusIcon />}
									className='add-button'
									size='large'
									iconLeft
								/>
							</Link>
						) : (
							<Loader />
						)}
					</div>
					{!isLoading && (
						<div className='brands-page-content'>
							<Datatable
								data={brands}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'id',
										Cell: ({ row, value: id }) => (
											<div className='brand-actions-column-container'>
												<Button
													icon={() => <TrashIcon />}
													onClick={() => {
														openModal(modalTypes.DELETE_BRAND)
														this.setState({ selectedBrand: row.original })
													}}
													color='red'
												/>
												<Link to={`/brands/${id}/edit`}>
													<Button
														title='Modifică brand'
														icon={() => <PencilSquareIcon />}
														iconLeft
													/>
												</Link>
												<Button
													title='Vezi categorii'
													icon={() => <CircleStackIcon />}
													onClick={() => navigate(`/brands/${id}/resource-categories-list`)}
													iconLeft
												/>
											</div>
										)
									}
								]}
							/>
						</div>
					)}
				</div>
				<AddBrandModal />
				<EditBrandModal brand={selectedBrand} />
				<DeleteBrandModal brand={selectedBrand} />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	brands: orderBy(state.brands.data, ['order', 'name'], 'asc'),
	isLoading: state.brands.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	openModal: (type) => dispatch(openModal(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Brands)
