import React from 'react'

import { Navbar } from '../../components/Navbar'

import './MainLayout.scss'

export default function MainLayout({ component }) {
	return (
		<div className='edy-optic-main-layout-container'>
			<Navbar />
			<div className='edy-optic-main-layout-content-container'>{component()}</div>
		</div>
	)
}
