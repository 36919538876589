import React, { Component } from 'react'
import { Form, Formik } from 'formik'

import { values, find, isNull, snakeCase } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Select } from '../../../../components/Select'
import { IconSelector } from '../../../../components/IconSelector'
import { Button } from '../../../../components/Button'

import { validations } from '../../../../assets/validations'

import './EditResourceCategoryModal.scss'
import { ImageDropzone } from '../../../../components/ImageDropzone'
import { Toggle } from '../../../../components/Toggle'

export class EditResourceCategoryModal extends Component {
	componentDidMount() {
		const { listBrands } = this.props

		listBrands()
	}

	render() {
		const { open, resourceCategory, resourceCategoriesErrors, isLoading, brands, updateResourceCategory } =
			this.props

		return (
			<Modal open={open} title='Editează categoria de resurse'>
				<Formik
					initialValues={{
						brand: find(brands, ['id', resourceCategory.brand_id]),
						name: resourceCategory.name,
						order: resourceCategory.order,
						icon: { name: resourceCategory.icon, url: resourceCategory.icon_url },
						image: null,
						uploadImage: !!resourceCategory.image_url,
						site_published: resourceCategory.site_published || false
					}}
					enableReinitialize
					validationSchema={validations.resourceCategories}
					onSubmit={(values) => {
						let formData = new FormData()
						const keys = Object.keys(values)
						
						keys.forEach(key => {
							if(key === 'icon') {
								if(values.icon) {
									formData.append('icon', values.icon.name)
								}
							}
							else if(key === 'brand') {
								if(values.brand) {
									formData.append('brand_id', values.brand?.id || resourceCategory.brand_id)
								}
							}
							else {
								formData.append(snakeCase(key), values[key])
							}
						})

						updateResourceCategory(formData, resourceCategory.id)
					}}
				>
					{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
						<Form className='edit-resource-category-modal-form-container'>
							<Input
								label='Nr. Ordine'
								value={values.order}
								onChange={handleChange('order')}
								onBlur={handleBlur('order')}
								size='large'
								name='order'
								errors={resourceCategoriesErrors}
								frontendErrors={errors}
								touched={touched.order}
								type='number'
								fullWidth
							/>
							<Select
								label='Brand'
								placeholder='Selectează brand-ul'
								value={values.brand}
								options={brands}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => setFieldValue('brand', option)}
								onBlur={handleBlur('brand')}
								name='brand'
								errors={resourceCategoriesErrors}
								frontendErrors={errors}
								size='large'
								disabled={resourceCategory.read_only}
								fullWidth
							/>
							<Input
								label='Nume'
								value={values.name}
								onChange={handleChange('name')}
								onBlur={handleBlur('name')}
								placeholder='Introdu numele categoriei'
								size='large'
								name='name'
								errors={resourceCategoriesErrors}
								frontendErrors={errors}
								touched={touched.name}
								disabled={resourceCategory.read_only}
								fullWidth
							/>

							<Toggle
								label='Afișare pe site'
								checked={values.site_published}
								onChange={(value) => {
									setFieldValue('site_published', value)
								}}
							/>		


							<Button
								title={values.uploadImage ? 'Alege iconita' : 'Incarca imagine'}
								onClick={() => setFieldValue('uploadImage', !values.uploadImage)}
								type='button'
							/>
							{!values.uploadImage ? (
								<>
									<div className='select-icon-container'>
										<p className='select-icon-text'>Imagine categorie selectată:</p>
										<div className='selected-icon-container'>
											{!isNull(values.icon) ? (
												<div className='icon-info'>
													<img src={values.icon.url} alt={values.icon.name} className='icon-image' />
													<p className='icon-name'>{values.icon.name}</p>
												</div>
											) : (
												<span>Selectează o imagine mai jos</span>
											)}
										</div>
									</div>
									<IconSelector 
										initialSelectedIcon={resourceCategory.icon}
									 	onSelectIcon={(icon) => setFieldValue('icon', icon)} 
									/>
								</>
							) : (
								<ImageDropzone
									label='Incarca imagine'
									initialImageUrl={resourceCategory.image_url}
									onChangeFiles={(files) => setFieldValue('image', files[0])}
								/>
							)}
							<Button
								title='Salvează categoria de resurse'
								onClick={handleSubmit}
								loading={isLoading}
								type='submit'
								size='large'
								fullWidth
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.EDIT_RESOURCE_CATEGORY,
	resourceCategoriesErrors: state.resourceCategories.errors,
	isLoading: state.resourceCategories.isLoading,
	brands: values(state.brands.data)
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	updateResourceCategory: (values, resourceCategoryID) =>
		dispatch(RESOURCES.resourceCategories.update(values, resourceCategoryID, { 'content-type': 'multipart/form-data' }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditResourceCategoryModal)
