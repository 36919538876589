import React, { Component } from 'react'
import FormData from 'form-data'
import { Form, Formik } from 'formik'

import { isEmpty } from 'lodash'

import { brandsSidebarItems, generateSlug } from '../../utils'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { Textarea } from '../../components/Textarea'
import { SingleImageDropzone } from '../../components/SingleImageDropzone'
import { ImageDropzone } from '../../components/ImageDropzone'

import { Sidebar } from '../../components/Sidebar'
import { PageHeader } from '../../components/PageHeader'

import { validations } from '../../assets/validations'

import './AddBrand.scss'
import { Toggle } from '../../components/Toggle'

export class AddBrand extends Component {
	constructor() {
		super()

		this.state = {
			
		}
	}

	componentDidMount = () => {
		
	}

	render() {

		const {
			brandsErrors,
			isLoading,
		} = this.props

		return (
			<div className='add-brand-page-container'>
				<Sidebar items={brandsSidebarItems} />
				<div className='add-brand-page-content-container'>
					<PageHeader pageTitle='Adaugă Brand' parentRoute='/brands' />
					<Formik
						initialValues={{ 
							name: '',
							slug: '',
							subtitle: '',
							link: '',
							link_text: '',
							categories_description: '',
							short_description: '',
							description1: '',
							description2: '',
							logo: {}, 
							image: {}, 
							logo_crop: '', 
							imge_crop: '' ,
							sales_image: {},
							resources_image: {},
							news_image: {},
							products_image: {},
							site_published: false
						}}
						validationSchema={validations.brands}
						onSubmit={async (values) => {
							const { createBrand } = this.props
							let brandData = new FormData()
							brandData.append('name', values.name)
							brandData.append('slug', values.slug)
							brandData.append('subtitle', values.subtitle)
							brandData.append('link', values.link)
							brandData.append('link_text', values.link_text)
							brandData.append('categories_description', values.categories_description)
							brandData.append('short_description', values.short_description)
							brandData.append('description1', values.description1)
							brandData.append('description2', values.description2)
							brandData.append('site_published', values.site_published)

							brandData.append('logo', values.logo, values.logo.name)
							brandData.append('logo_crop', values.logo_crop)

							brandData.append('image', values.image, values.image.name)
							brandData.append('image_crop', values.image_crop)

							brandData.append('sales_image', values.sales_image, values.sales_image.name)
							brandData.append('resources_image', values.resources_image, values.resources_image.name)
							brandData.append('news_image', values.news_image, values.news_image.name)
							brandData.append('products_image', values.products_image, values.products_image.name)

							createBrand(brandData)
						}}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<Form className='add-brand-form-container'>
								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<Input
											label='Nume'
											value={values.name}
											onChange={(e) => {
												setFieldValue('name', e.target.value)
												setFieldValue('slug', generateSlug(e.target.value))
											}}
											placeholder='Introdu numele brandului'
											onBlur={handleBlur('name')}
											size='large'
											name='name'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.name}
											fullWidth
										/>
									</div>
									<div className='w-1/2'>
										<Input
											label='Slug'
											value={values.slug}
											onChange={handleChange('slug')}
											placeholder='Introdu numele brandului'
											onBlur={handleBlur('slug')}
											size='large'
											name='slug'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.slug}
											fullWidth
										/>
									</div>
								</div>
								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<Textarea
											label='Descriere categorii'
											value={values.categories_description}
											onChange={handleChange('categories_description')}
											placeholder='Introdu descrirea categoriilor'
											onBlur={handleBlur('categories_description')}
											size='large'
											name='categories_description'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.categories_description}
											fullWidth
										/>
									</div>
									<div className='w-1/2'>
										<Textarea
											label='Descriere scurta'
											value={values.short_description}
											onChange={handleChange('short_description')}
											placeholder='Introdu descrirea scurta brandului'
											onBlur={handleBlur('short_description')}
											size='large'
											name='short_description'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.short_description}
											fullWidth
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<Input
											label='Subtitlu'
											value={values.subtitle}
											onChange={handleChange('subtitle')}
											placeholder='Introdu subtitlul brandului'
											onBlur={handleBlur('subtitle')}
											size='large'
											name='subtitle'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.subtitle}
											fullWidth
										/>
									</div>
									<div className='w-1/2'>
										<Toggle
											label='Afișare pe site'
											checked={values.site_published}
											onChange={(value) => {
												setFieldValue('site_published', value)
											}}
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<Input
											label='Text link'
											value={values.link_text}
											onChange={handleChange('link_text')}
											placeholder='Introdu textul link-ul brandului'
											onBlur={handleBlur('link_text')}
											size='large'
											name='link_text'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.link_text}
											fullWidth
										/>
									</div>
									<div className='w-1/2'>
										<Input
											label='Link'
											value={values.link}
											onChange={handleChange('link')}
											placeholder='Introdu link-ul brandului'
											onBlur={handleBlur('link')}
											size='large'
											name='link'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.link}
											fullWidth
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<Textarea
											label='Descriere 1'
											value={values.description1}
											onChange={handleChange('description1')}
											placeholder='Introdu descrirea brandului'
											onBlur={handleBlur('description1')}
											size='large'
											name='description1'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.description1}
											fullWidth
										/>
									</div>
									<div className='w-1/2'>
										<Textarea
											label='Descriere 2'
											value={values.description2}
											onChange={handleChange('description2')}
											placeholder='Introdu descrirea brandului'
											onBlur={handleBlur('description2')}
											size='large'
											name='description2'
											errors={brandsErrors}
											frontendErrors={errors}
											touched={touched.description2}
											fullWidth
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<label className="edy-optic-label">Logo</label>
										<SingleImageDropzone
											label='Logo Brand'
											onChange={(file) => setFieldValue('logo', file)}
											onCrop={(crop) => setFieldValue('logo_crop', JSON.stringify(crop))}
											name="logo"								
										/>
									</div>
									<div className='w-1/2'>
										<label className="edy-optic-label">Imagine Homepage</label>
										<SingleImageDropzone
											label='Imagine Homepage'
											onChange={(file) => setFieldValue('image', file)}
											onCrop={(crop) => setFieldValue('image_crop', JSON.stringify(crop))}
											aspectRatio={0.85}
											name="image"
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<label className="edy-optic-label">Imagine Noutati</label>
										<ImageDropzone
											label='Incarca imagine'
											onChangeFiles={(files) => setFieldValue('news_image', files[0])}
										/>
									</div>

									<div className='w-1/2'>
										<label className="edy-optic-label">Imagine Oferte</label>
										<ImageDropzone
											label='Incarca imagine'
											onChangeFiles={(files) => setFieldValue('sales_image', files[0])}
										/>
									</div>
								</div>

								<div className='add-brand-form-row'>
									<div className='w-1/2'>
										<label className="edy-optic-label">Imagine Produse</label>
										<ImageDropzone
											label='Incarca imagine'
											onChangeFiles={(files) => setFieldValue('products_image', files[0])}
										/>
									</div>
									<div className='w-1/2'>
										<label className="edy-optic-label">Imagine Resurse</label>
										<ImageDropzone
											label='Incarca imagine'
											onChangeFiles={(files) => setFieldValue('resources_image', files[0])}
										/>
									</div>
								</div>

								<Button
									title='Salvează brandul'
									onClick={handleSubmit}
									loading={isLoading}
									disabled={isEmpty(values.name) || isEmpty(values.logo) || isEmpty(values.image)}
									type='submit'
									size='large'
									fullWidth
								/>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	brandsErrors: state.brands.errors,
	isLoading: state.brands.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createBrand: (values) => dispatch(RESOURCES.brands.create(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand)
