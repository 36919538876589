export const fileTypes = {
	FOLDER: 'FOLDER',
	PDF: 'PDF',
	JPG: 'JPG',
	MP4: 'MP4',
	PNG: 'PNG'
}

export const sortingDirections = {
	ASC: 'asc',
	DESC: 'desc'
}
