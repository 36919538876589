import React from 'react'
import { Form, Formik } from 'formik'

import { isNull } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { snakeCase } from 'lodash'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { IconSelector } from '../../../../components/IconSelector'

import { validations } from '../../../../assets/validations'

import './AddResourceCategoryModal.scss'
import { ImageDropzone } from '../../../../components/ImageDropzone'
import { Toggle } from '../../../../components/Toggle'

export const AddResourceCategoryModal = ({
	open,
	resourceCategoriesErrors,
	isLoading,
	createResourceCategory,
	brandID
}) => {
	return (
		<Modal open={open} title='Adaugă categorie de resurse'>
			<Formik
				initialValues={{ name: '', icon: null, image: null, brandID: brandID, uploadImage: true, site_published: false }}
				validationSchema={validations.resourceCategories}
				onSubmit={(values) => {
					let formData = new FormData()
					const keys = Object.keys(values)
					
					keys.forEach(key => {
						if(key === 'icon') {
							if(values.icon) {
								formData.append('icon', values.icon.name)
							}
						}
						else {
							formData.append(snakeCase(key), values[key])
						}
					})

					createResourceCategory(formData)
				}}
			>
				{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
					<Form className='add-resource-category-modal-form-container'>
						<Input
							label='Nume'
							value={values.name}
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
							placeholder='Introdu numele categoriei'
							size='large'
							name='name'
							errors={resourceCategoriesErrors}
							frontendErrors={errors}
							touched={touched.name}
							fullWidth
						/>
						<Toggle
							label='Afișare pe site'
							checked={values.site_published}
							onChange={(value) => {
								setFieldValue('site_published', value)
							}}
						/>	
						<Button
							title={values.uploadImage ? 'Alege iconita' : 'Incarca imagine'}
							onClick={() => setFieldValue('uploadImage', !values.uploadImage)}
							type='button'
						/>
						{!values.uploadImage ? (
							<>
								<div className='select-icon-container'>
									<p className='select-icon-text'>Imagine categorie selectată:</p>
									<div className='selected-icon-container'>
										{!isNull(values.icon) ? (
											<div className='icon-info'>
												<img src={values.icon.url} alt={values.icon.name} className='icon-image' />
												<p className='icon-name'>{values.icon.name}</p>
											</div>
										) : (
											<span>Selectează o imagine mai jos</span>
										)}
									</div>
								</div>
								<IconSelector onSelectIcon={(icon) => setFieldValue('icon', icon)} />
							</>
						) : (
							<ImageDropzone
								label='Incarca imagine'
								onChangeFiles={(files) => setFieldValue('image', files[0])}
							/>
						)}
						<Button
							title='Salvează categoria de resurse'
							onClick={handleSubmit}
							loading={isLoading}
							type='submit'
							size='large'
							fullWidth
						/>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.ADD_RESOURCE_CATEGORY,
	resourceCategoriesErrors: state.resourceCategories.errors,
	isLoading: state.resourceCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createResourceCategory: (values) => dispatch(RESOURCES.resourceCategories.create(values, { 'content-type': 'multipart/form-data' }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceCategoryModal)
