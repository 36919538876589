import React, { Component } from 'react'

import { values, isEmpty, filter, includes, find } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../Input'
import { Loader } from '../Loader'

import './IconSelector.scss'

export class IconSelector extends Component {
	constructor() {
		super()

		this.state = {
			iconsQuery: '',
			filteredIcons: [],
			selectedIcon: null
		}
	}

	componentDidMount() {
		const { listIcons } = this.props

		listIcons()
	}

	componentDidUpdate(prevProps) {
		const { icons, isLoadig, initialSelectedIcon } = this.props

		if (!isEmpty(icons) && prevProps.icons !== icons && !isLoadig) {
			this.setState({ filteredIcons: icons })

			if (initialSelectedIcon) {
				this.setState({ selectedIcon: find(icons, ['name', initialSelectedIcon]) })
			}
		}
	}

	handleFilterIcons = ({ target: { value: query } }) => {
		const { icons } = this.props
		const filteredIcons = filter(icons, (i) => includes(i.name, query))

		this.setState({ iconsQuery: query, filteredIcons: filteredIcons })
	}

	handleSelectIcon = (icon) => {
		const { onSelectIcon } = this.props

		this.setState({ selectedIcon: icon })
		onSelectIcon(icon)
	}

	render() {
		const { iconsQuery, filteredIcons, selectedIcon } = this.state
		const { isLoading } = this.props

		return (
			<div className='edy-icon-selector-container'>
				<Input
					value={iconsQuery}
					onChange={this.handleFilterIcons}
					placeholder='Caută o imagine'
					size='large'
					fullWidth
				/>
				{!isEmpty(filteredIcons) && !isLoading ? (
					<div className='icons-container'>
						<div className='icons-list'>
							{filteredIcons.map((icon) => (
								<IconCard
									icon={icon}
									onSelect={this.handleSelectIcon}
									isSelected={selectedIcon === icon}
									key={icon.name}
								/>
							))}
						</div>
					</div>
				) : isEmpty(filteredIcons) && !isLoading ? (
					<div className='icons-container'>
						<p className='no-icons-text'>Fără rezultate</p>
					</div>
				) : isLoading ? (
					<div className='loader-container'>
						<Loader />
					</div>
				) : null}
			</div>
		)
	}
}

function IconCard({ icon, onSelect, isSelected }) {
	return (
		<div className={`icon-card-container ${isSelected ? 'selected' : ''}`} onClick={() => onSelect(icon)}>
			<img src={icon.url} alt={icon.name} className='icon-image' />
			<p className='icon-name'>{icon.name}</p>
		</div>
	)
}

const mapStateToProps = (state) => ({
	icons: values(state.icons.data),
	isLoading: state.icons.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listIcons: () => dispatch(RESOURCES.icons.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(IconSelector)
