import { isNull } from 'lodash'

export const columns = [
	{
		Header: 'Produs',
		accessor: 'name',
		Cell: ({ row, value: name }) => {
			const { image_url } = row.original

			return (
				<div className='datatable-product-cell'>
					<img src={image_url} alt='' className='product-image' />
					<p className='product-name'>{name}</p>
				</div>
			)
		}
	},
	{
		Header: 'Brand',
		accessor: 'brand',
		Cell: ({ row: { original: product } }) => product.category?.brand?.name || ''
	},
	{
		Header: 'Categorie',
		accessor: 'category',
		Cell: ({ value: category }) => category?.name || ''
	},
	{
		Header: 'Preț',
		accessor: 'price',
		Cell: ({ value: price }) => (isNull(price) ? 'Pe comandă' : `${price.toLocaleString()} RON`)
	},
	{
		Header: 'Com.Min.',
		accessor: 'min_order',
		Cell: ({ value: minOrder }) => `${minOrder} buc.`
	}
]
