import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { persistor, store, history } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import { MainLayout } from './layouts/MainLayout'

// Auth
import { Login } from './pages/Login'
import { ForgotPassword } from './pages/ForgotPassword'
import { ResetPassword } from './pages/ResetPassword'

// Brand
import { Brands } from './pages/Brands'
import { AddBrand } from './pages/AddBrand'
import { EditBrand } from './pages/EditBrand'
import { BrandDetails } from './pages/BrandDetails'
import { BrandProductCategories } from './pages/BrandProductCategories'
import { BrandResourceCategories } from './pages/BrandResourceCategories'
import { BrandResourceCategoriesList } from './pages/BrandResourceCategoriesList'
import { BrandResources } from './pages/BrandResources'
import { AddResources } from './pages/AddResources'
import { EditResource } from './pages/EditResource'

// News
import { News } from './pages/News'
import { AddNews } from './pages/AddNews'
import { EditNews } from './pages/EditNews'
import { BrandNews } from './pages/BrandNews'
import { BrandNewsArticle } from './pages/BrandNewsArticle'

// Shop
import { Shop } from './pages/Shop'
import { Cart } from './pages/Cart'
import { Orders } from './pages/Orders'
import { OrderDetails } from './pages/OrderDetails'
import { Products } from './pages/Products'
import { AddProduct } from './pages/AddProduct'
import { EditProduct } from './pages/EditProduct'
import { ProductCategories } from './pages/ProductCategories'

// Users
import { Users } from './pages/Users'
import { UserCategories } from './pages/UserCategories'

// Other
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'

// Components
import { CustomToastContainer } from './components/CustomToastContainer'
import { PersonalFolder } from './pages/PersonalFolder'

function App() {
	const addMaximumScaleToMetaViewport = () => {
		const el = document.querySelector('meta[name=viewport]')

		if (el !== null) {
			let content = el.getAttribute('content')
			let re = /maximum-scale=[0-9.]+/g

			if (re.test(content)) {
				content = content.replace(re, 'maximum-scale=1.0')
			} else {
				content = [content, 'maximum-scale=1.0'].join(',')
			}

			el.setAttribute('content', content)
		}
	}

	const disableIosTextFieldZoom = addMaximumScaleToMetaViewport

	// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
	const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

	if (checkIsIOS()) {
		disableIosTextFieldZoom()
	}

	return (
		<Provider store={store}>
			<CustomToastContainer />
			<PersistGate loading={null} persistor={persistor}>
				<div className='App'>
					<ConnectedRouter history={history}>
						<Switch>
							<Route path='/login' exact component={Login} />
							<Route path='/password-reset/:token' exact component={ResetPassword} />
							<Route path='/forgot-password' exact component={ForgotPassword} />
							<Redirect exact from='/' to='/home' />
							<Route
								path='/home'
								render={(props) => <MainLayout component={() => <Home {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID'
								render={(props) => <MainLayout component={() => <BrandDetails {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/news'
								render={(props) => <MainLayout component={() => <BrandNews {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/news/:articleID'
								render={(props) => <MainLayout component={() => <BrandNewsArticle {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/news/:articleID/edit'
								render={(props) => <MainLayout component={() => <EditNews {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/resource-categories'
								render={(props) => (
									<MainLayout component={() => <BrandResourceCategories {...props} />} />
								)}
								exact
							/>
							<Route
								path='/brands/:brandID/product-categories'
								render={(props) => (
									<MainLayout component={() => <BrandProductCategories {...props} />} />
								)}
								exact
							/>
							<Route
								path='/brands/:brandID/resource-categories/:resourceCategoryID/:folderID'
								render={(props) => <MainLayout component={() => <BrandResources {...props} />} />}
								exact
							/>
							<Route
								path='/personal-folder'
								render={(props) => <MainLayout component={() => <PersonalFolder {...props} />} />}
								exact
							/>
							<Route
								path='/shop'
								render={(props) => <MainLayout component={() => <Shop {...props} />} />}
								exact
							/>
							<Route
								path='/cart'
								render={(props) => <MainLayout component={() => <Cart {...props} />} />}
								exact
							/>
							<Route
								path='/orders'
								render={(props) => <MainLayout component={() => <Orders {...props} />} />}
								exact
							/>
							<Route
								path='/orders/:orderID'
								render={(props) => <MainLayout component={() => <OrderDetails {...props} />} />}
								exact
							/>
							<Route
								path='/brands'
								render={(props) => <MainLayout component={() => <Brands {...props} />} />}
								exact
							/>
							<Route
								path='/add-brand'
								render={(props) => <MainLayout component={() => <AddBrand {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/edit'
								render={(props) => <MainLayout component={() => <EditBrand {...props} />} />}
								exact
							/>
							<Route
								path='/add-news'
								render={(props) => <MainLayout component={() => <AddNews {...props} />} />}
								exact
							/>
							<Route
								path='/news'
								render={(props) => <MainLayout component={() => <News {...props} />} />}
								exact
							/>
							<Route
								path='/brands/:brandID/resource-categories-list'
								render={(props) => (
									<MainLayout component={() => <BrandResourceCategoriesList {...props} />} />
								)}
								exact
							/>
							<Route
								path='/resources/:resourceID/edit'
								render={(props) => <MainLayout component={() => <EditResource {...props} />} />}
								exact
							/>
							<Route
								path='/add-resources'
								render={(props) => <MainLayout component={() => <AddResources {...props} />} />}
								exact
							/>
							<Route
								path='/products'
								render={(props) => <MainLayout component={() => <Products {...props} />} />}
								exact
							/>
							<Route
								path='/products/:productID/edit'
								render={(props) => <MainLayout component={() => <EditProduct {...props} />} />}
								exact
							/>
							<Route
								path='/add-product'
								render={(props) => <MainLayout component={() => <AddProduct {...props} />} />}
								exact
							/>
							<Route
								path='/product-categories'
								render={(props) => <MainLayout component={() => <ProductCategories {...props} />} />}
								exact
							/>
							<Route
								path='/users'
								render={(props) => <MainLayout component={() => <Users {...props} />} />}
								exact
							/>
							<Route
								path='/user-categories'
								render={(props) => <MainLayout component={() => <UserCategories {...props} />} />}
								exact
							/>
							<Route component={NotFound} />
						</Switch>
					</ConnectedRouter>
				</div>
			</PersistGate>
		</Provider>
	)
}

export default App
