import React, { Component } from 'react'
import { DocumentIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { values, isEmpty, isUndefined, find, without } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { closeModal, modalTypes } from '../../redux/modals'

import { Input } from '../Input'
import { Modal } from '../Modal'
import { Loader } from '../Loader'
import { Button } from '../Button'

import './ConnectResourcesModal.scss'

export class ConnectResourcesModal extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedResources: props.initialSelectedResources
		}
	}

	componentDidUpdate = (prevProps) => {
		const { selectedResources } = this.state
		const { initialSelectedResources } = this.props

		if (
			initialSelectedResources !== prevProps.initialSelectedResources &&
			initialSelectedResources !== selectedResources
		) {
			this.setState({ selectedResources: initialSelectedResources })
		}
	}

	handleSelectResource = (resource) =>
		this.setState((prevState) =>
			this.isSelected(resource, prevState.selectedResources)
				? {
						selectedResources: without(prevState.selectedResources, resource)
				  }
				: {
						selectedResources: [...prevState.selectedResources, resource]
				  }
		)

	handleConnectResources = () => {
		const { selectedResources } = this.state
		const { closeModal, onConnectResources } = this.props

		onConnectResources(selectedResources)
		closeModal()
	}

	isSelected = (resource, selectedResources = this.state.selectedResources) =>
		!isUndefined(find(selectedResources, ['id', resource.id]))

	render() {
		const { selectedResources } = this.state
		const { open, resources, isLoading, resourceQuery, onSearchResources } = this.props

		return (
			<Modal open={open} title='Selectează resurse'>
				<div className='choose-resources-modal-container'>
					{!isUndefined(resourceQuery) && !isUndefined(onSearchResources) ? (
						<div className='search-container'>
							<Input
								value={resourceQuery}
								placeholder='Caută după nume'
								onChange={(e) => onSearchResources(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
							/>
						</div>
					) : null}
					{!isEmpty(resources) && !isLoading ? (
						<>
							<div className='resources-list'>
								{resources.map((resource) => (
									<ResourceCard
										resource={resource}
										isSelected={this.isSelected(resource)}
										handleSelectResource={this.handleSelectResource}
										key={resource.id}
									/>
								))}
							</div>
							<Button
								title='Conectează resursele selectate'
								onClick={this.handleConnectResources}
								disabled={isEmpty(selectedResources)}
								size='large'
								fullWidth
							/>
						</>
					) : isLoading ? (
						<div className='loader-container'>
							<Loader />
						</div>
					) : (<div className='resources-list'></div>)}
				</div>
			</Modal>
		)
	}
}

function ResourceCard({ resource, isSelected, handleSelectResource }) {
	const onSelectResource = () => handleSelectResource(resource)

	return (
		<div className={`resource-card-container ${isSelected ? 'selected' : ''}`} onClick={onSelectResource}>
			<div className='texts-container'>
				<div className='resource-name-container'>
					<div className='icon-container'>
						<DocumentIcon className='document-icon' />
					</div>
					<p className='resouce-name'>{resource.name}</p>
				</div>
				<div className='secondary-texts'>
					<p>{resource?.folder?.path}</p>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.CONNECT_RESOURCES,
	resources: values(state.resources.data),
	isLoading: state.resources.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listResources: (brandID) => dispatch(RESOURCES.resources.list({ brand_id: brandID })),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectResourcesModal)
