import { validationSchema as brandsSchema } from './brands'
import { validationSchema as foldersSchema } from './folders'
import { validationSchema as forgotPasswordSchema } from './forgotPassword'
import { validationSchema as newsSchema } from './news'
import { validationSchema as productCategoriesSchema } from './productCategories'
import { validationSchema as productsSchema } from './products'
import { validationSchema as resetPasswordSchema } from './resetPassword'
import { validationSchema as resourceCategoriesSchema } from './resourceCategories'
import { validationSchema as resourcesSchema } from './resources'
import { validationSchema as userCategoriesSchema } from './userCategories'
import { validationSchema as usersSchema } from './users'

export const validations = {
	brands: brandsSchema,
	folders: foldersSchema,
	forgotPassword: forgotPasswordSchema,
	news: newsSchema,
	productCategories: productCategoriesSchema,
	products: productsSchema,
	resetPassword: resetPasswordSchema,
	resourceCategories: resourceCategoriesSchema,
	resources: resourcesSchema,
	userCategories: userCategoriesSchema,
	users: usersSchema
}
