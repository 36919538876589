import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
	ArrowRightIcon,
	NewspaperIcon,
	ArrowLeftIcon,
	ShoppingCartIcon,
	DocumentIcon,
	TagIcon
} from '@heroicons/react/24/outline'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import './BrandDetails.scss'

export class BrandDetails extends Component {
	componentDidMount() {
		const {
			retrieveBrands,
			match: { params }
		} = this.props

		const { brandID } = params

		retrieveBrands(brandID)
	}

	render() {
		const { brand, isLoading } = this.props

		const newsCategory = {
			id: 0,
			name: 'Noutăți',
			image: brand.news_image_url,
			link: `/brands/${brand.id}/news`,
			getIcon: () => <NewspaperIcon className='category-icon' />
		}

		const salesCategory = {
			id: 1,
			name: 'Oferte',
			image: brand.sales_image_url,
			link: `/brands/${brand.id}/resource-categories/${brand?.sale_category?.id}/${brand?.sale_category?.folder?.id}`,
			getIcon: () => <TagIcon className='category-icon' />
		}

		const productsCategory = {
			id: 2,
			name: 'Produse',
			image: brand.products_image_url,
			link: `/brands/${brand.id}/product-categories`,
			getIcon: () => <ShoppingCartIcon className='category-icon' />
		}

		const resourcesCategory = {
			id: 3,
			name: 'Resurse',
			image: brand.resources_image_url,
			link: `/brands/${brand.id}/resource-categories`,
			getIcon: () => <DocumentIcon className='category-icon' />
		}

		return (
			<div className='brand-details-container'>
				<div className='brand-details-header'>
					<div className='left'>
						<Link to='/home'>
							<Button
								icon={() => <ArrowLeftIcon className='back-button-icon' />}
								size='large'
								color='primaryLight'
								className='back-button'
							/>
						</Link>
						<p className='brand-name'>{brand.name}</p>
					</div>
					{isLoading && <Loader />}
				</div>
				<div className='resources-container-outter'>
					<div className='resources-container'>
						<NewBrandResourceCategory category={newsCategory} />
						<NewBrandResourceCategory category={salesCategory} />
						<NewBrandResourceCategory category={productsCategory} />
						<NewBrandResourceCategory category={resourcesCategory} />
					</div>
				</div>
			</div>
		)
	}
}

// function BrandResourceCategory({ category }) {
// 	const getResorceCategoryLink = () =>
// 		category.link
// 			? category.link
// 			: `/brands/${category.brand_id}/resource-categories/${category.id}/${category.folder.id}`
// 	return (
// 		<Link to={getResorceCategoryLink()} className='brand-resource-container'>
// 			<div className='brand-resource-icon-container'>
// 				{category.getIcon ? category.getIcon() : <img src={category.icon_url} alt='' />}
// 			</div>
// 			<div className='brand-resource-title-container'>
// 				<p className='brand-resource-title'>{category.name}</p>
// 			</div>
// 			<ArrowRightIcon className='brand-resource-arrow' />
// 		</Link>
// 	)
// }

function NewBrandResourceCategory({ category }) {
	const getResorceCategoryLink = () =>
		category.link
			? category.link
			: `/brands/${category.brand_id}/resource-categories/${category.id}/${category.folder.id}`
			
	return (
		<Link to={getResorceCategoryLink()} className="new-brand-resource-container">
			{
				category.image ? (
					<img src={category.image} alt={category.name} className="category-image" />
				)
				: (
					<>
					<div className='brand-resource-icon-container'>
						{category.getIcon ? category.getIcon() : <img src={category.icon_url} alt='' />}
					</div>
					<div className='brand-resource-title-container'>
						<p className='brand-resource-title'>{category.name}</p>
					</div>
					<ArrowRightIcon className='brand-resource-arrow' />
					</>
				)
			}
			
		</Link>
	)
}

const mapStateToProps = (state) => {
	return {
		brand: state.brands.currentBrand,
		isLoading: state.brands.isLoading
	}
}

const mapDispatchToProps = (dispatch) => ({
	retrieveBrands: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID))
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetails)
