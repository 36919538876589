import React, { Component } from 'react'
import { DocumentIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Formik } from 'formik'

import axios from 'axios'
import { REACT_APP_API_URL } from '../../settings'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { isEmpty, values as _values, omit, isNull, forEach, without, debounce } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { NumberInput } from '../../components/NumberInput'
import { MultiImageDropzone } from '../../components/MultiImageDropzone'
import { ConnectResourcesModal } from '../../components/ConnectResourcesModal'
import { PageHeader } from '../../components/PageHeader'

import { validations } from '../../assets/validations'

import './AddProduct.scss'
import { Toggle } from '../../components/Toggle'
import { Textarea } from '../../components/Textarea'
import { navigate, objectKeysToSnakeCase } from '../../utils'

export class AddProduct extends Component {
	constructor() {
		super()

		this.state = {
			selectedBrandID: null,
			resourceQuery: '',
			images: [],
			site_images: []
		}
	}

	componentDidMount = () => {
		const { listBrands } = this.props

		listBrands()
	}

	componentDidUpdate = (prevProps) => {
		const { product, token } = this.props
		const { images, site_images } = this.state

		
		if (!isEmpty(product) && product !== prevProps.product && token) {
			let productData = new FormData()

			forEach(images, (image) => {
				productData.append('images[]', image, image.name)
			})

			forEach(site_images, (image) => {
				productData.append('site_images[]', image, image.name)
			})

			axios.post(`${REACT_APP_API_URL}products/${product.id}/upload`, 
				productData, {
					headers: {
						'Access-Control-Allow-Origin': '*',
					  	'Content-Type': 'multipart/form-data',
					  	'Authorization': `Bearer ${token}`
					}
				}).then(function () {
					navigate(`/products/${product.id}/edit`)
			  	}).catch(function (error) {
					console.log(error);
				});
		}
	}

	handleListResources = (brandID = this.state.selectedBrandID, search = this.state.resourceQuery) => {
		const { listResources, isLoading } = this.props

		let params = { brand_id: brandID }

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading) {
			listResources(params)
		}
	}

	debounceSearch = debounce((query) => this.handleListResources(this.state.selectedBrandID, query), 300)

	handleChangeSearch = (query) => {
		this.setState({ resourceQuery: query })
		this.debounceSearch(query)
	}

	render() {
		const { resourceQuery, selectedBrandID, images, site_images } = this.state

		const {
			productsErrors,
			isLoading,
			brands,
			listProductCategories,
			productCategories,
			isLoadingCategories,
			createProduct,
			openConnectResourcesModal
		} = this.props

		return (
			<Formik
				initialValues={{
					name: '',
					description: '<p>Descrierea acestui produs...</p>',
					site_description: '',
					long_description1: '',
					long_description2: '',
					images: images,
					site_images: site_images,
					brand: null,
					category: null,
					price: '',
					orderOnly: false,
					site_published: false,
					popular: false,
					minOrder: 1,
					connectedResources: [],
					features: [],
					tags: [],
					video: [],
					benefits_description: '',
					benefits: [],
					external_links: []
				}}
				validationSchema={validations.products}
				onSubmit={(values) => {
					let productData = {
						...objectKeysToSnakeCase(omit(values, ['images', 'site_images'])),
						price: values.orderOnly ? null : values.price,
						category_id: values.category.id,
						remove_images: values.remove_images,
						remove_site_images: values.remove_site_images,
					}

					if (!isEmpty(values.connectedResources)) {
						productData['connected_resources'] = values.connectedResources.map(
							(resource) => resource.id
						)
					}

					createProduct(productData)
				}}
			>
				{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
					<div className='add-product-container'>
						<div className='add-product-left'>
							<PageHeader pageTitle='Adaugă Produs' parentRoute='/products' />
							<div className='add-product-form-container'>
								<Input
									value={values.name}
									placeholder='Numele produsului'
									onChange={handleChange('name')}
									onBlur={handleBlur('name')}
									size='large'
									name='name'
									errors={productsErrors}
									frontendErrors={errors}
									touched={touched.name}
									fullWidth
								/>
								<div className='add-product-form-row'>
									<div className='product-description-editor-container'>
										<CKEditor
											editor={Editor}
											className='ckeditor'
											data={values.description}
											onChange={(_event, editor) => {
												const data = editor.getData()

												setFieldValue('description', data)
											}}
										/>
									</div>
									<MultiImageDropzone
										label='Imagini Produs'
										onChangeFiles={(files, removeImages) => {
											setFieldValue('images', files)
											this.setState({images: files})
										}}
										getImageSrc={(image) => image.url}
									/>
								</div>
								
								<div className='add-product-form-row'>
									<div className='description-header'>
										<p className='description-title'>Descriere site</p>
									</div>
								</div>
								<div className='add-product-form-row'>
									<div className='product-description-editor-container'>
										<Textarea
											label='Descriere'
											value={values.site_description}
											onChange={handleChange('site_description')}
											placeholder='Introdu o descrire'
											onBlur={handleBlur('site_description')}
											size='large'
											name='site_description'
											errors={productsErrors}
											frontendErrors={errors}
											touched={touched.site_description}
											fullWidth
										/>
									</div>
								</div>
								<div className='add-product-form-row'>
									<div className='product-description-editor-container'>
										<Textarea
											label='Descriere 1'
											value={values.long_description1}
											onChange={handleChange('long_description1')}
											placeholder='Introdu o descrirea'
											onBlur={handleBlur('long_description1')}
											size='large'
											name='long_description1'
											errors={productsErrors}
											frontendErrors={errors}
											touched={touched.long_description1}
											fullWidth
										/>
									</div>
									<div className='product-description-editor-container'>
										<Textarea
											label='Descriere 2'
											value={values.long_description2}
											onChange={handleChange('long_description2')}
											placeholder='Introdu o descrirea'
											onBlur={handleBlur('long_description2')}
											size='large'
											name='long_description2'
											errors={productsErrors}
											frontendErrors={errors}
											touched={touched.long_description2}
											fullWidth
										/>
									</div>
								</div>

								<div className='add-product-form-row tags-container'>
									<div className='tags-header'>
										<p className='tags-title'>Tag-uri</p>

										<Button
											icon={() => <PlusIcon />}
											onClick={() => {
												setFieldValue('tags', [...values.tags, {value: ''}])													
											}}
											size='small'
										/>
									</div>
									{!isEmpty(values.tags) ? (
										<div className='tags-list-container'>
											{values.tags.map((tag, index) => (
												<div className='tag-card-container' key={'tag_' + index}>
													<Input
														value={tag.value}
														placeholder='Tag'
														onChange={handleChange('tags.' + index + '.value')}
														onBlur={handleBlur('tag_' + index)}
														size='small'
														name={'tag_' + index}
														fullWidth
													/>

													<Button
														icon={() => <TrashIcon />}
														onClick={() =>
															setFieldValue(
																'tags',
																without(values.tags, tag)
															)
														}
														color='red'
														size='medium'
													/>
												</div>
											))}
										</div>
									) : (
										<p>Nu exista tag-uri</p>
									)}
								</div>

								<div className='add-product-form-row features-container'>
									<div className='features-header'>
										<p className='features-title'>Caracteristici excelente</p>

										<Button
											icon={() => <PlusIcon />}
											onClick={() => {
												setFieldValue('features', [...values.features, {value: ''}])													
											}}
											size='small'
										/>
									</div>
									{!isEmpty(values.features) ? (
										<div className='features-list-container'>
											{values.features.map((feature, index) => (
												<div className='feature-card-container' key={'feature_' + index}>
													<Input
														value={feature.value}
														placeholder='Text caracteristica'
														onChange={handleChange('features.' + index + '.value')}
														onBlur={handleBlur('feature_' + index)}
														size='small'
														name={'feature_' + index}
														fullWidth
													/>

													<Button
														icon={() => <TrashIcon />}
														onClick={() =>
															setFieldValue(
																'features',
																without(values.features, feature)
															)
														}
														color='red'
														size='medium'
													/>
												</div>
											))}
										</div>
									) : (
										<p>Nu exista caracteristici</p>
									)}
								</div>

								<div className='add-product-form-row images-container'>
									<div className='images-header'>
										<p className='images-title'>Imagini produs</p>
									</div>
									<MultiImageDropzone
										label='Imagini Produs'
										onChangeFiles={(files) => {
											setFieldValue('site_images', files)
											this.setState({site_images: files})
										}}
										getImageSrc={(image) => image.url}
									/>
								</div>

								<div className='add-product-form-row video-container'>
									<div className='video-header'>
										<p className='video-title'>Video produs</p>

										<Button
											icon={() => <PlusIcon />}
											onClick={() => {
												setFieldValue('video', [...values.video, {title: '', iframe: ''}])													
											}}
											size='small'
										/>
									</div>
									{!isEmpty(values.video) ? (
										<div className='video-list-container'>
											{values.video.map((video, index) => (
												<div className='video-card-container' key={'video_' + index}>
													<div className='flex flex-col w-full gap-2'>
														<Input
															value={video.title}
															placeholder='Titlu video'
															onChange={handleChange('video.' + index + '.title')}
															onBlur={handleBlur('video_title_' + index)}
															size='small'
															name={'video_title_' + index}
															fullWidth
														/>
														<Textarea
															value={video.iframe}
															placeholder='Embeded iframe'
															onChange={handleChange('video.' + index + '.iframe')}
															onBlur={handleBlur('video_iframe_' + index)}
															size='large'
															name={'video_iframe_' + index}
															errors={productsErrors}
															frontendErrors={errors}
															fullWidth
														/>
													</div>

													<Button
														icon={() => <TrashIcon />}
														onClick={() =>
															setFieldValue(
																'video',
																without(values.video, video)
															)
														}
														color='red'
														size='medium'
													/>
												</div>
											))}
										</div>
									) : (
										<p>Nu exista video</p>
									)}
								</div>

								<div className='add-product-form-row benefits-container'>
											<div className='benefits-header'>
												<p className='benefits-title'>Link-uri externe</p>

												<Button
													icon={() => <PlusIcon />}
													onClick={() => {
														setFieldValue('external_links', [...values.external_links, {title: '', url: '', description: ''}])													
													}}
													size='small'
												/>
											</div>
											{!isEmpty(values.external_links) ? (
												<div className='benefits-list-container'>
													{values.external_links.map((link, index) => (
														<div className='benefits-card-container' key={'benefit_' + index}>
															<div className='flex flex-col w-full gap-2'>
																<Input
																	value={link.title}
																	placeholder='Titlu link'
																	onChange={handleChange('external_links.' + index + '.title')}
																	onBlur={handleBlur('link_title_' + index)}
																	size='small'
																	name={'link_title_' + index}
																	fullWidth
																/>
																<Input
																	value={link.url}
																	placeholder='URL'
																	onChange={handleChange('external_links.' + index + '.url')}
																	onBlur={handleBlur('link_url_' + index)}
																	size='small'
																	name={'link_url_' + index}
																	fullWidth
																/>
																<Textarea
																	value={link.description}
																	placeholder='Descriere link'
																	onChange={handleChange('external_links.' + index + '.description')}
																	onBlur={handleBlur('link_description_' + index)}
																	size='large'
																	name={'link_description_' + index}
																	errors={productsErrors}
																	frontendErrors={errors}
																	fullWidth
																/>
															</div>

															<Button
																icon={() => <TrashIcon />}
																onClick={() =>
																	setFieldValue(
																		'external_links',
																		without(values.external_links, link)
																	)
																}
																color='red'
																size='medium'
															/>
														</div>
													))}
												</div>
											) : (
												<p>Nu exista avantaje</p>
											)}
										</div>

								<div className='add-product-form-row benefits-container'>
									<div className='benefits-header'>
										<p className='benefits-title'>Avantaje produs</p>

										<Button
											icon={() => <PlusIcon />}
											onClick={() => {
												setFieldValue('benefits', [...values.benefits, {title: '', description: ''}])													
											}}
											size='small'
										/>
									</div>
									<div className='benefits-description'>
										<Textarea
											value={values.benefits_description}
											placeholder='Descriere avantaje'
											onChange={handleChange('benefits_description')}
											onBlur={handleBlur('benefits_description')}
											size='large'
											name={'benefits_description'}
											errors={productsErrors}
											frontendErrors={errors}
											fullWidth
										/>
									</div>
									{!isEmpty(values.benefits) ? (
										<div className='benefits-list-container'>
											{values.benefits.map((benefit, index) => (
												<div className='benefits-card-container' key={'benefit_' + index}>
													<div className='flex flex-col w-full gap-2'>
														<Input
															value={benefit.title}
															placeholder='Titlu avantaj'
															onChange={handleChange('benefits.' + index + '.title')}
															onBlur={handleBlur('benefit_title_' + index)}
															size='small'
															name={'benefit_title_' + index}
															fullWidth
														/>
														<Textarea
															value={benefit.description}
															placeholder='Descriere avantaj'
															onChange={handleChange('benefits.' + index + '.description')}
															onBlur={handleBlur('benefit_description_' + index)}
															size='large'
															name={'benefit_description_' + index}
															errors={productsErrors}
															frontendErrors={errors}
															fullWidth
														/>
													</div>

													<Button
														icon={() => <TrashIcon />}
														onClick={() =>
															setFieldValue(
																'benefits',
																without(values.benefits, benefit)
															)
														}
														color='red'
														size='medium'
													/>
												</div>
											))}
										</div>
									) : (
										<p>Nu exista avantaje</p>
									)}
								</div>
							</div>
						</div>
						<div className='add-product-right'>
							<div className='add-product-right-form-container'>
								<div className='form-inputs-container'>
									<Select
										label='Brand'
										placeholder='Alege brand-ul'
										value={values.brand}
										options={brands}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onChange={(option) => {
											setFieldValue('brand', option)
											setFieldValue('category', null)

											if (!isNull(option)) {
												this.handleListResources(option.id)
												listProductCategories(option.id)

												this.setState({ selectedBrandID: option.id })
											} else {
												this.setState({ selectedBrandID: null })
											}
										}}
										onBlur={handleBlur('brand')}
										name='brand'
										errors={productsErrors}
										frontendErrors={errors}
										touched={touched.brand}
										isClearable
										fullWidth
									/>
									<Select
										label='Categorie'
										placeholder='Alege categoria'
										value={values.category}
										options={productCategories}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onChange={(option) => setFieldValue('category', option)}
										onBlur={handleBlur('category')}
										disabled={isNull(values.brand) || isLoadingCategories}
										name='category'
										errors={productsErrors}
										frontendErrors={errors}
										touched={touched.category}
										isClearable
										fullWidth
									/>
									<div className='price-container'>
										<Input
											label='Preț'
											value={values.price}
											onChange={handleChange('price')}
											onBlur={handleBlur('price')}
											type='price'
											name='price'
											disabled={values.orderOnly}
											errors={productsErrors}
											frontendErrors={errors}
											touched={touched.price}
											fullWidth
										/>
										<Toggle
											label='Pe comandă'
											checked={values.orderOnly}
											onChange={(value) => {
												setFieldValue('orderOnly', value)
												setFieldValue('price', '')
											}}
										/>

										<Toggle
											label='Afișare pe site'
											checked={values.site_published}
											onChange={(value) => {
												setFieldValue('site_published', value)
											}}
										/>

										<Toggle
											label='Produs popular'
											checked={values.popular}
											onChange={(value) => {
												setFieldValue('popular', value)
											}}
										/>
									</div>
									<NumberInput
										value={values.minOrder}
										label='Comandă minimă'
										onChange={(value) => setFieldValue('minOrder', value)}
										name='minOrder'
										errors={productsErrors}
										min={1}
										fullWidth
									/>
									<div className='resources-section-container'>
										<div className='resources-section-header'>
											<p className='resources-section-title'>Resurse conectate</p>
											<div className='button-container'>
												<Button
													icon={() => <PlusIcon />}
													disabled={isNull(values.brand)}
													onClick={openConnectResourcesModal}
													size='small'
												/>
												<ConnectResourcesModal
													brandID={selectedBrandID}
													onConnectResources={(resources) =>
														setFieldValue('connectedResources', resources)
													}
													initialSelectedResources={values.connectedResources}
													resourceQuery={resourceQuery}
													onSearchResources={this.handleChangeSearch}
												/>
											</div>
										</div>
										{!isEmpty(values.connectedResources) && !isNull(values.brand) ? (
											<div className='connected-resources-container'>
												{values.connectedResources.map((resource) => (
													<div className='resource-card-container' key={resource.id}>
														<div className='texts-container'>
															<div className='resource-name-container'>
																<div className='icon-container'>
																	<DocumentIcon className='document-icon' />
																</div>
																<p className='resouce-name'>{resource?.name}</p>
															</div>
															<div className='secondary-texts'>
																<p>{resource?.folder?.path}</p>
															</div>
														</div>
														<Button
															icon={() => <TrashIcon />}
															onClick={() =>
																setFieldValue(
																	'connectedResources',
																	without(values.connectedResources, resource)
																)
															}
															color='red'
															size='small'
														/>
													</div>
												))}
											</div>
										) : isNull(values.brand) ? (
											<p className='missing-text pb-2'>Vă rugăm să selectați un brand</p>
										) : isEmpty(values.connectedResources) ? (
											<p className='missing-text pb-2'>Fară resurse conectate</p>
										) : null}
									</div>
								</div>
								<Button
									title='Salveză Produsul'
									onClick={handleSubmit}
									loading={isLoading}
									type='submit'
									size='large'
									fullWidth
								/>
							</div>
						</div>
					</div>
				)}
			</Formik>
		)
	}
}

const mapStateToProps = (state) => ({
	token: state.users.token,
	product: state.products.currentProduct,
	productsErrors: state.products.errors,
	isLoading: state.products.isLoading,
	brands: _values(state.brands.data),
	isLoadingBrands: state.brands.isLoading,
	productCategories: _values(state.productCategories.data),
	isLoadingCategories: state.productCategories.isLoading,
	isLoadingResources: state.resources.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listProductCategories: (brandID) => dispatch(RESOURCES.productCategories.list({ brand_id: brandID })),
	listResources: (params) => dispatch(RESOURCES.resources.list(params)),
	createProduct: (productData) => dispatch(RESOURCES.products.create(productData)),
	openConnectResourcesModal: () => dispatch(openModal(modalTypes.CONNECT_RESOURCES))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct)
