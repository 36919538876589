import React from 'react'
import { DocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { closeModal, modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'

import './ConnectedResourcesModal.scss'

export const ConnectedResourcesModal = ({ open, resources }) => {
	return (
		<Modal open={open && !isEmpty(resources)} title={`Resurse conectate (${resources.length})`}>
			<div className='connected-resources-list-container'>
				{resources.map((resource) => (
					<div className='resource-row' key={resource.id}>
						<div className='resource-row-left'>
							<div className='resource-name-cotnainer'>
								<DocumentIcon className='resource-icon' />
								<p className='resource-name'>{resource.name}</p>
							</div>
							<p className='resource-location'>{resource?.folder?.path}</p>
						</div>
						<a href={resource.download_url} download target='_blank' rel='noreferrer'>
							<Button icon={() => <ArrowDownTrayIcon />} size='small' />
						</a>
					</div>
				))}
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.VIEW_CONNECTED_RESOURCES
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedResourcesModal)
