import React, { Component } from 'react'
import { FolderPlusIcon, FolderIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon, FolderIcon as SolidFolderIcon } from '@heroicons/react/24/solid'

import Tree, { TreeNode } from 'rc-tree'
import 'rc-tree/assets/index.css'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { modalTypes, openModal } from '../../redux/modals'

import { Button } from '../Button'
import { Loader } from '../Loader'

import './FoldersTree.scss'

export class FoldersTree extends Component {
	constructor() {
		super()

		this.state = {
			treeData: [],
			defaultSelectedKeys: []
		}
	}

	componentDidMount() {
		this.saveTreeDataToState()
	}

	componentDidUpdate = (prevProps) => {
		const { folders, isLoading } = this.props

		if (prevProps.folders !== folders && !isLoading) {
			this.saveTreeDataToState()
		}
	}

	saveTreeDataToState = () => {
		const { folders, selectedKey } = this.props
		const treeData = this.generateTreeData(folders)

		this.setState({ treeData: treeData, defaultSelectedKeys: [selectedKey] })
	}

	generateTreeData = (folders) =>
		folders.map((folder) => {
			const folderInfo = { title: folder.name, key: folder.id }
			const folderChildren = !isEmpty(folder.children) ? this.generateTreeData(folder.children) : []

			return { ...folderInfo, children: folderChildren }
		})

	onSelect = (info) => {
		const { key } = info.node
		const { onSelect } = this.props
		this.selKey = this.selKey === key ? null : key

		onSelect(key)
	}

	getFolderIcon = (selected) =>
		selected ? <SolidFolderIcon className='tree-folder-icon' /> : <FolderIcon className='tree-folder-icon' />

	renderFolderChildren = (folder) => {
		if (folder.children) {
			return folder.children.map((childFolder) => (
				<TreeNode
					title={childFolder.title}
					key={childFolder.key}
					icon={({ selected }) => this.getFolderIcon(selected)}
				>
					{this.renderFolderChildren(childFolder)}
				</TreeNode>
			))
		}
	}

	render() {
		const { selKey } = this
		const { treeData, defaultSelectedKeys } = this.state
		const { disableAdding, isLoading, onOpenAddFolderModal, openAddFolderModal } = this.props

		return (
			<>
				{!isLoading ? (
					<>
						<Tree
							switcherIcon={(obj) =>
								!obj.isLeaf ? (
									obj.expanded ? (
										<ChevronUpIcon className='tree-chevron-icon' />
									) : (
										<ChevronDownIcon className='tree-chevron-icon' />
									)
								) : null
							}
							className='edy-optic-folders-tree'
							onSelect={(_selectedKeys, info) => this.onSelect(info)}
							height={300}
							defaultSelectedKeys={defaultSelectedKeys}
							autoExpandParent
						>
							{treeData.map((folder) => (
								<TreeNode
									key={folder.key}
									title={folder.title}
									icon={({ selected }) => this.getFolderIcon(selected)}
									selectable={folder.selectable || true}
								>
									{this.renderFolderChildren(folder)}
								</TreeNode>
							))}
						</Tree>
						{!disableAdding && selKey && (
							<div className='edy-optic-folders-tree-footer'>
								<Button
									title='Folder Nou'
									onClick={() => {
										onOpenAddFolderModal && onOpenAddFolderModal()
										openAddFolderModal()
									}}
									icon={() => <FolderPlusIcon />}
									iconLeft
									type='button'
								/>
							</div>
						)}
					</>
				) : (
					<div className='loader-container'>
						<Loader />
					</div>
				)}
			</>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	openAddFolderModal: () => dispatch(openModal(modalTypes.ADD_FOLDER))
})

export default connect(null, mapDispatchToProps)(FoldersTree)
