/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from 'react'
import {
	CheckCircleIcon,
	CloudArrowUpIcon,
	DocumentIcon,
	PlusIcon,
	TrashIcon,
	XCircleIcon
} from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import { isEmpty, uniqBy, split, last, toUpper, filter, has, find, values } from 'lodash'

import { connect } from 'react-redux'
import { removeResponse, ResponseTypes } from '../../../../redux/resources'

import { formatBytes } from '../../../../utils'

import { Button } from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'
import { FileTypeBadge } from '../../../../components/FileTypeBadge'

import './Dropzone.scss'

function CustomDropzone({ onChangeFiles, resourcesResponses, isLoading, removeResponse }) {
	const [files, setFiles] = useState([])

	useEffect(() => onChangeFiles(files), [files])

	const handleDrop = (acceptedFiles) =>
		setFiles((files) =>
			files.length === 0 ? acceptedFiles : uniqBy([...files, ...acceptedFiles], (file) => file.path)
		)

	const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: onDrop,
		noClick: true,
		noKeyboard: true
	})

	const getFileExtension = (file) => toUpper(last(split(file.path, '.')))

	const hasFileResponses = (fileName) => has(resourcesResponses, fileName)

	const hasSuccessResopnse = (fileName) => {
		const successResponse = find(
			values(resourcesResponses),
			(response) => response.fileName === fileName && response.responseType === ResponseTypes.SUCCESS
		)

		return !isEmpty(successResponse)
	}

	const isLoadingFile = (fileName) => isLoading && !hasSuccessResopnse(fileName)

	return (
		<div {...getRootProps({ className: 'edy-optic-files-dropzone' })}>
			<div className='edy-optic-files-dropzone-input-container'>
				<input {...getInputProps()} />
				<CloudArrowUpIcon className='upload-icon' />
				<Button title='Adaugă' onClick={open} icon={() => <PlusIcon />} iconLeft type='button' />
				<p className='secondary-text'>Sau trage fișierele aici pentru a încărca.</p>
			</div>
			{!isEmpty(files) && (
				<div className='files-preview-container'>
					{files.map((file) => (
						<div className='file-preview-row' key={file.name}>
							<div className='left-container'>
								{isLoadingFile(file.name) ? (
									<Loader size='small' />
								) : hasFileResponses(file.name) ? (
									resourcesResponses[file.name].responseType === ResponseTypes.SUCCESS ? (
										<CheckCircleIcon className='file-icon success' />
									) : resourcesResponses[file.name].responseType === ResponseTypes.FAIL ? (
										<XCircleIcon className='file-icon fail' />
									) : null
								) : (
									<DocumentIcon className='file-icon' />
								)}
								<p className='file-name'>{file.name}</p>
							</div>
							<div className='right-container'>
								<FileTypeBadge fileType={getFileExtension(file)} />
								<div className='file-size-container'>
									<p className='file-size-text'>{formatBytes(file.size)}</p>
								</div>
								<Button
									icon={() => <TrashIcon />}
									onClick={() => {
										removeResponse(file.name)
										setFiles((files) => filter(files, (f) => f.path !== file.path))
									}}
									type='button'
									size='small'
									color='red'
								/>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({
	resourcesResponses: state.resources.responses,
	isLoading: state.resources.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	removeResponse: (fileName) => dispatch(removeResponse(fileName))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDropzone)
