import { Resource } from './index'

export const AUTH_ENDPOINTS = {
	ACTIVATE_ACCOUNT: '/auth/verify',
	FORGOT_PASSWORD: '/auth/forgot-password',
	LOGIN: '/auth/login',
	LOGOUT: '/auth/logout',
	REFRESH_TOKEN: '/auth/token/refresh',
	RESET_PASSWORD: '/auth/reset-password',
	SIGNUP: '/auth/signup'
}

export const FOLDERS_ENDPOINTS = {
	MAIN: '/resource-categories/{resourceCategoryID}/folders',
	BRAND: '/brands/{brandID}/folders',
	MOVE: '/resource-categories/{resourceCategoryID}/folders/{folderID}/move',
	DESTROY: '/resource-categories/{resourceCategoryID}/folders/{folderID}',
	PERSONAL: '/personal-folders'
}

export const CART_ENDPOINTS = {
	MAIN: '/cart'
}

export const ORDERS_ENDPOINTS = {
	UPDATE: '/orders/{orderID}/status'
}

export const RESOURCES = {
	brands: new Resource('brands', '/brands-new/{id}'),
	icons: new Resource('icons', '/resource-categories/icons'),
	news: new Resource('news'),
	orders: new Resource('orders'),
	orderStatuses: new Resource('orderStatuses', '/orders/statuses'),
	productCategories: new Resource('productCategories'),
	products: new Resource('products'),
	resourceCategories: new Resource('resourceCategories'),
	resources: new Resource('resources'),
	userCategories: new Resource('userCategories'),
	users: new Resource('users')
}
