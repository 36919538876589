import { lightFormat } from 'date-fns'

export const columns = [
	{
		Header: 'Nr. comanda',
		accessor: 'id',
		Cell: ({ value: id }) => '#' + id
	},
	{
		Header: 'Client',
		accessor: 'user',
		Cell: ({ value: user }) => `${user.first_name} ${user.last_name}`
	},

	{
		Header: 'Data',
		accessor: 'created_at',
		Cell: ({ value: created_at }) => lightFormat(new Date(created_at), 'dd/MM/yyyy HH:mm')
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value: status }) => status.name
	},
	{
		Header: 'Valoare',
		accessor: 'sale_total',
		Cell: ({ value: sale_total }) => `${sale_total.toLocaleString()} RON`
	}
]
