import React, { useEffect, useState } from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { isEmpty, split, last, toUpper, values } from 'lodash'

import { connect } from 'react-redux'
import { closeModal, modalTypes } from '../../redux/modals'

import { formatBytes } from '../../utils'

import { Modal } from '../Modal'
import { Loader } from '../Loader'
import { FileTypeBadge } from '../FileTypeBadge'
import { Button } from '../Button'

import './UploadingResourcesModal.scss'

export const UploadingResourcesModal = ({
	open,
	files,
	selectedBrandID,
	selectedCategoryID,
	selectedFolderID,
	closeModal,
	filesStatuses
}) => {
	const getFileExtension = (file) => toUpper(last(split(file.path, '.')))

	const [isLoading, setIsLoading] = useState(true)
	const [hasErrors, setHasErrors] = useState(false)

	useEffect(() => {
		setIsLoading(!!values(filesStatuses).find(({ isLoading }) => isLoading))
		setHasErrors(!!values(filesStatuses).find(({ error }) => !!error))
	}, [filesStatuses])

	return (
		<Modal open={open} removeHeader disableClosing>
			<div className='uploading-resources-modal-container'>
				<div className='upper-container'>
					{isLoading ? (
						<>
							<div className='loader-container'>
								<Loader size='large' />
							</div>
							<div className='status-text text-primary'>
								Resursele sunt în procesul de încărcare. <br />
								Daca vor apărea erori, acestea pot fi văzute mai jos
							</div>
						</>
					) : !hasErrors ? (
						<>
							<CheckCircleIcon className='status-icon success' />
							<p className='status-text success'>Toate resursele au fost încărcate cu succes</p>
						</>
					) : hasErrors ? (
						<>
							<XCircleIcon className='status-icon fail' />
							<p className='status-text fail'>
								Am întâmpinat câteva erori. <br />
								Pentru resursele menționate, te rugăm să încerci din nou.
							</p>
						</>
					) : null}
				</div>
				{!isEmpty(files) && (
					<div className='files-preview-container'>
						{files.map((file) => (
							<div className='file-container' key={file.name}>
								<div className='file-preview-row'>
									<div className='left-container'>
										{filesStatuses[file.name]?.isLoading ? (
											<Loader size='small' />
										) : filesStatuses[file.name]?.progress === 100 ? (
											<CheckCircleIcon className='file-icon success' />
										) : (
											<XCircleIcon className='file-icon fail' />
										)}
										<p className='file-name'>{file.name}</p>
									</div>
									<div className='right-container'>
										<FileTypeBadge fileType={getFileExtension(file)} />
										<div className='file-size-container'>
											<p className='file-size-text'>{formatBytes(file.size)}</p>
										</div>
										<div className='file-size-container'>
											<p className='file-size-text'>{filesStatuses[file.name]?.progress}%</p>
										</div>
									</div>
								</div>
								{filesStatuses[file.name]?.error && (
									<p className='file-error-message'>{filesStatuses[file.name]?.error}</p>
								)}
							</div>
						))}
					</div>
				)}
				{!isEmpty(filesStatuses) && !isLoading && (
					<div className='button-container'>
						<Link
							to={`/brands/${selectedBrandID}/resource-categories/${selectedCategoryID}/${selectedFolderID}`}
							className='button-link'
						>
							<Button title='Vezi resurse' onClick={closeModal} fullWidth />
						</Link>
					</div>
				)}

				{isEmpty(filesStatuses) && !isLoading && (
					<div className='button-container'>
						<Button title='Inchide' onClick={closeModal} fullWidth />
					</div>
				)}
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.UPLOADING_RESOURCES
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadingResourcesModal)
