export const shopOrderingOptions = {
	NEW_DESC: {
		label: 'Cele mai noi',
		id: 'new_desc',
		orderBy: 'id',
		orderDirection: 'desc'
	},
	PRICE_ASC: {
		label: 'Pret crescator',
		id: 'price_asc',
		orderBy: 'price',
		orderDirection: 'asc'
	},
	PRICE_DESC: {
		label: 'Pret descrescator',
		id: 'price_desc',
		orderBy: 'price',
		orderDirection: 'desc'
	},
	NAME_ASC: {
		label: 'Denumire A-Z',
		id: 'name_asc',
		orderBy: 'name',
		orderDirection: 'asc'
	},
	NAME_DESC: {
		label: 'Denumire Z-A',
		id: 'name_desc',
		orderBy: 'name',
		orderDirection: 'desc'
	}
}
