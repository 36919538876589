import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { forgotPassword } from '../../redux/auth'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'

import { validations } from '../../assets/validations'
import logo from '../../assets/images/logo-edyoptic-new.svg'

import './ForgotPassword.scss'

class ForgotPassword extends Component {
	render() {
		const { authErrors, isLoading, forgotPassword } = this.props

		return (
			<div className='forgot-password-container'>
				<div className='main-container'>
					<div className='header'>
						<img className='logo' src={logo} alt='EdyOptic' />
						<h1 className='title'>Am uitat parola</h1>
						<p>
							Daca ai uitat parola contului tău, introdu adresa de email și iți vom trimite un link de
							resetare.
						</p>
					</div>
					<div className='m-6 mt-3'>
						<Formik
							initialValues={{ email: '' }}
							validationSchema={validations.forgotPassword}
							onSubmit={(values) => forgotPassword(values)}
						>
							{({ handleChange, handleBlur, values, handleSubmit, errors, touched }) => (
								<Form className='space-y-6'>
									<Input
										value={values.email}
										onChange={handleChange('email')}
										onBlur={handleBlur('email')}
										label='Adresa de email'
										type='email'
										autoComplete='email'
										name='email'
										errors={authErrors}
										frontendErrors={errors}
										touched={touched.email}
										fullWidth
									/>
									<Button
										title='Trimite link de resetare'
										disabled={isEmpty(values.email)}
										onClick={handleSubmit}
										loading={isLoading}
										type='submit'
										fullWidth
									/>
								</Form>
							)}
						</Formik>
						<div className='back-to-login-container'>
							<Link to='/login' className='back-to-login-link'>
								Înapoi la login
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authErrors: state.auth.errors,
	isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (values) => dispatch(forgotPassword(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
