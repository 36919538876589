import React from 'react'

import { fileTypes } from '../../types'

import './FileTypeBadge.scss'

export default function FileTypeBadge({ fileType }) {
	const getBadgeProps = () => {
		switch (fileType) {
			case fileTypes.PDF:
				return {
					label: 'PDF',
					color: 'red'
				}
			case fileTypes.JPG:
			case fileTypes.PNG:
				return {
					label: 'IMG',
					color: 'yellow'
				}
			case fileTypes.MP4:
				return {
					label: 'VID',
					color: 'purple'
				}
			default:
				return {
					label: fileType,
					color: 'primary'
				}
		}
	}

	const badgeProps = getBadgeProps()

	return <div className={`edy-optic-file-type-badge ${badgeProps.color}`}>{badgeProps.label}</div>
}
