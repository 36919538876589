import React from 'react'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { closeModal, modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'

import '../../../../assets/styles/DeleteModal.scss'

export const DeleteResourceCategoryModal = ({ open, resourceCategory, deleteBrand, closeModal }) => {
	return (
		<Modal open={open && !isEmpty(resourceCategory)} title='Șterge categoria de resurse'>
			<p className='delete-modal-text'>
				Ești sigur că vrei să ștergi categoria de resurse <span>"{resourceCategory.name}"</span>?
				<br />
				<span>Toate resursele acesteia vor fi șterse împreună cu ea!</span>
			</p>
			<div className='delete-modal-buttons-container'>
				<Button title='Nu, renunță' onClick={closeModal} color='secondary' />
				<Button title='Da, șterge' onClick={() => deleteBrand(resourceCategory)} color='red' />
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.DELETE_RESOURCE_CATEGORY
})

const mapDispatchToProps = (dispatch) => ({
	deleteBrand: (resourceCategory) => dispatch(RESOURCES.resourceCategories.destroy(resourceCategory)),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteResourceCategoryModal)
