import React, { useEffect, useState } from 'react'
import { DocumentIcon, ArrowTopRightOnSquareIcon, ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { isEmpty, isNull } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes } from '../../redux/modals'
import { addToCart } from '../../redux/cart'

import { Modal } from '../Modal'
import { Input } from '../Input'
import { Button } from '../Button'
import { ImagesSlider } from '../ImagesSlider'
import { Loader } from '../Loader'

import './ProductDetailsModal.scss'

export const ProductDetailsModal = ({ open, product, productID, onClose, isLoading, retrieveProduct, addToCart }) => {
	useEffect(() => productID && retrieveProduct(productID), [productID, retrieveProduct])
	useEffect(() => !isEmpty(product) && setQuantity(product.min_order), [product])

	const [quantity, setQuantity] = useState(product?.min_order ? parseInt(product.min_order) : 1)

	return (
		<Modal
			open={open && productID && !isEmpty(product)}
			onClose={onClose}
			className='product-details-modal'
			removeHeader
		>
			<div className='product-details-modal-container'>
				{!isEmpty(product) && !isLoading ? (
					<>
						<Button icon={() => <XMarkIcon />} onClick={onClose} className='mobile-close-modal-button' />
						<div className='product-details-modal-image-container'>
							{!isEmpty(product.images) ? (
								<div className='products-images-slider-container'>
									<ImagesSlider
										images={product.images}
										getImageSrc={(image) => image.url}
										hasPreviews={product.images.length > 1}
									/>
								</div>
							) : (
								<img src={product.image_url} alt='' />
							)}
						</div>
						<div className='product-details-modal-content-container'>
							<div className='product-details-modal-header'>
								<div className='header-texts'>
									<p className='product-name'>{product.name}</p>
									<div className='product-brand-info'>
										<p className='product-brand'>{product?.category?.brand?.name}</p>
										<p className='product-category'>{product?.category?.name}</p>
									</div>
								</div>
								<Button icon={() => <XMarkIcon />} onClick={onClose} className='close-modal-button' />
							</div>
							<p
								className='product-description'
								dangerouslySetInnerHTML={{ __html: product.description }}
							/>
							<div className='product-details-modal-footer'>
								<div className='product-price-container'>
									<p className='product-price'>
										{isNull(product.price) ? 'Pe comandă' : `${product.price.toLocaleString()} RON`}
									</p>
									<p className='product-minimum-ammount'>Minim {product.min_order} buc.</p>
								</div>
								<div className='buttons-container'>
									<Input
										value={quantity}
										onChange={(e) => setQuantity(e.target.value ? parseInt(e.target.value) : '')}
										className='avo-quantity-input'
										type='number'
										size='large'
									/>
									<Button
										icon={() => <ShoppingCartIcon />}
										onClick={() => addToCart(product, quantity)}
										disabled={quantity < product.min_order}
										size='large'
										className='my-2'
									/>
								</div>
							</div>
						</div>
						<div className='product-details-modal-connected-resources-container'>
							<p className='connected-resources-section-title'>Resurse conectate</p>
							{!isEmpty(product.resources) ? (
								<div className='connected-resources-list-container'>
									{product.resources.map((resource) => (
										<div className='resource-row' key={resource.id}>
											<a
												href={resource.preview_url}
												target='_blank'
												rel='noreferrer'
												className='resource-row-left'
											>
												<div className='resource-name-cotnainer'>
													<DocumentIcon className='resource-icon' />
													<p className='resource-name'>{resource?.name}</p>
												</div>
												<p className='resource-location'>{resource?.folder?.path}</p>
											</a>
											<a href={resource.preview_url} download target='_blank' rel='noreferrer'>
												<Button icon={() => <ArrowTopRightOnSquareIcon />} size='small' />
											</a>
										</div>
									))}
								</div>
							) : (
								<p className='no-resources'>Fară resurse conectate</p>
							)}
						</div>
					</>
				) : isLoading ? (
					<div className='loader-container'>
						<Loader />
					</div>
				) : null}
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.PRODUCT_DETAILS,
	product: state.products.currentProduct,
	isLoading: state.products.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	retrieveProduct: (productID) => dispatch(RESOURCES.products.retrieve(productID)),
	addToCart: (product, quantity) => dispatch(addToCart(product, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsModal)
