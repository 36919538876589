import React, { Component } from 'react'
import { DocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'

import { values, isEmpty, upperCase, orderBy } from 'lodash'
import { lightFormat } from 'date-fns'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'

import { Button } from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'

import './RecentResources.scss'

export class RecentResources extends Component {
	componentDidMount = () => {
		const { brandID, listResources } = this.props

		listResources(brandID)
	}

	render() {
		const { resources, isLoading } = this.props

		return (
			<div className='recent-resources-container'>
				<p className='section-title'>Resurse Recente</p>
				<div className='resources-container'>
					{!isEmpty(resources) ? (
						orderBy(resources, 'id', 'desc').map((resource, resourceIdx) => <ResourceCard resource={resource} key={resourceIdx} />)
					) : isLoading ? (
						<Loader />
					) : (
						<p className='no-resources'>Nu există resurse în acest brand</p>
					)}
				</div>
			</div>
		)
	}
}

function ResourceCard({ resource: { name, publish_date, extension, preview_url, download_url } }) {
	return (
		<div className='resource-card-container'>
			<div className='texts-container'>
				<a href={preview_url} className='resource-name-container' target='_blank' rel='noreferrer'>
					<div className='icon-container'>
						<DocumentIcon className='document-icon' />
					</div>
					<p className='resouce-name'>{name}</p>
				</a>
				<div className='secondary-texts'>
					<p>{lightFormat(new Date(publish_date), 'dd/MM/yyyy')}</p>
					<p>{upperCase(extension)}</p>
				</div>
			</div>
			<a href={download_url} download target='_blank' rel='noreferrer'>
				<Button icon={() => <ArrowDownTrayIcon />} color='white' />
			</a>
		</div>
	)
}

const mapStateToProps = (state) => ({
	resources: values(state.resources.data),
	isLoading: state.resources.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listResources: (brandID) => dispatch(RESOURCES.resources.list({ brand_id: brandID }))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecentResources)
