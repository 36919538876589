import { LOCATION_CHANGE } from 'connected-react-router'

export default function routerLocations(state = [], action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return [...state, action.payload]
		default:
			return state
	}
}
