import React from 'react'
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from '@heroicons/react/24/solid'
import { TrashIcon } from '@heroicons/react/24/outline'

import { isNull, isEmpty, without } from 'lodash'

import { connect } from 'react-redux'
import { modalTypes, openModal } from '../../redux/modals'

import { Button } from '../Button'

import { ConnectProductsModal } from './partials'

import './ProductsPanel.scss'

function ProductsPanel({
	isOpen,
	openConent,
	brandID,
	connectedProducts,
	openConnectProductsModal,
	onConnectProducts,
	isSinglePanel,
	productQuery,
	onSearchProducts
}) {
	return (
		<div className='panel'>
			<div className='panel-header'>
				{!isSinglePanel && (
					<div className='panel-toggler' onClick={openConent}>
						{isOpen ? (
							<ChevronUpIcon className='panel-toggler-icon' />
						) : (
							<ChevronDownIcon className='panel-toggler-icon' />
						)}
					</div>
				)}
				<div className='panel-header-right'>
					<div className='panel-title-container'>
						<p className='panel-title'>Produse conectate</p>
						<p className='connected-bubble'>{connectedProducts.length}</p>
					</div>
					<div className='button-container'>
						<Button
							icon={() => <PlusIcon />}
							disabled={isNull(brandID)}
							onClick={openConnectProductsModal}
							fullWidth
							size='small'
						/>
						<ConnectProductsModal
							brandID={brandID}
							onConnectProducts={onConnectProducts}
							initialSelectedProducts={connectedProducts}
							productQuery={productQuery}
							onSearchProducts={onSearchProducts}
						/>
					</div>
				</div>
			</div>
			{(isOpen || isSinglePanel) && (
				<>
					<div className='panel-content'>
						{!isEmpty(connectedProducts) && brandID ? (
							<div className='connected-products-container'>
								{connectedProducts.map((product) => (
									<div className='product-card-container' key={product.id}>
										<div className='product-info-container'>
											<img src={product.image_url} alt={product.name} className='product-image' />
											<div className='product-texts-container'>
												<p className='product-name'>{product.name}</p>
												<p className='product-category'>{product.category.name}</p>
											</div>
										</div>
										<Button
											icon={() => <TrashIcon />}
											onClick={() => onConnectProducts(without(connectedProducts, product))}
											color='red'
											size='small'
										/>
									</div>
								))}
							</div>
						) : isNull(brandID) ? (
							<p className='missing-text'>Vă rugăm să selectați un brand</p>
						) : isEmpty(connectedProducts) ? (
							<p className='missing-text'>Fară produse conectate</p>
						) : null}
					</div>
					<div className='panel-footer'>
						<Button
							title='Șterge toate'
							onClick={() => onConnectProducts([])}
							icon={() => <TrashIcon />}
							color='red'
							iconLeft
							fullWidth
						/>
					</div>
				</>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	openConnectProductsModal: () => dispatch(openModal(modalTypes.CONNECT_PRODUCTS))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPanel)
