export const columns = [
	{
		Header: 'Nume',
		accessor: 'name'
	},
	{
		Header: 'Imagine',
		accessor: ({ image_url, icon_url }) => image_url ? image_url : icon_url,
		Cell: ({ value: image_url}) => <img src={image_url} alt='' className='category-icon' />
	},
	{
		Header: 'Folder',
		accessor: 'folder',
		Cell: ({ value: folder }) => folder?.name || '-'
	},
	{
		Header: 'Nr. ordine',
		accessor: 'order'
	}
]
