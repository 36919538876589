import React, { Component } from 'react'
import { Form, Formik } from 'formik'

import { isEmpty, isNull, values as _values, orderBy, omitBy, find, toString } from 'lodash'
import { lightFormat, sub } from 'date-fns'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { listFolders } from '../../redux/folders'

import { apiDateFormat } from '../../constants'
import { objectKeysToSnakeCase } from '../../utils'

import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { Toggle } from '../../components/Toggle'
import { Input } from '../../components/Input'
import { DatePicker } from '../../components/DatePicker'
import { FoldersTree } from '../../components/FoldersTree'
import { AddFolderModal } from '../../components/AddFolderModal'
import { GroupAccessDate } from '../../components/GroupAccessDate'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'
import { Loader } from '../../components/Loader'

import { validations } from '../../assets/validations'

import './EditResource.scss'

export class EditResource extends Component {
	constructor() {
		super()

		this.state = {
			editFolder: false,
			showInterestDates: false,
			typeOptions: [
				{value: 'general', label: 'General'},
				{value: 'catalog', label: 'Catalog produse'},
				{value: 'fisa-tehnica', label: 'Fisa tehnica produs'},
			]
		}
	}

	componentDidMount() {
		const {
			listBrands,
			listUserCategories,
			retrieveResource,
			match: {
				params: { resourceID }
			}
		} = this.props

		listBrands()
		listUserCategories()
		retrieveResource(resourceID)
	}

	componentDidUpdate(prevProps) {
		const { resource, isLoadingResources, listResourceCategories, listFolders } = this.props

		if (!isEmpty(resource) && !isLoadingResources && resource !== prevProps.resource) {
			this.setState({ showInterestDates: !!(resource.interest_start || resource.interest_stop) })
			listResourceCategories(resource.brand_id)
			listFolders(resource.category_id)
		}
	}

	setFoldersCallback = (setFieldValue) => {
		this.foldersCallback = setFieldValue
	}

	render() {
		const {
			resource,
			resourcesErrors,
			brands,
			isLoading,
			isLoadingBrands,
			resourceCategories,
			isLoadingResourceCategories,
			updateResource,
			listResourceCategories,
			folders,
			isLoadingFolders,
			listFolders,
			userCategories,
			isLoadingUserCategories
		} = this.props

		const { editFolder, showInterestDates, typeOptions } = this.state

		return (
			<div className='edit-resource-page-container'>
				<div className='edit-resource-page-content-container'>
					{!isEmpty(resource) && (
						<>
							<PageHeader
								pageTitle={`Editează Resursă "${resource.name || ''}"`}
								parentRoute={`/brands/${resource.brand_id}/resource-categories/${resource.category_id}/${resource.folder.id}`}
							/>
							{!isEmpty(brands) &&
							!isLoadingBrands &&
							!isEmpty(resourceCategories) &&
							!isLoadingUserCategories ? (
								<Formik
									initialValues={{
										name: resource.name,
										brand: find(brands, ['id', resource.brand_id]),
										category: find(resourceCategories, ['id', resource.category_id]),
										folderID: resource.folder.id,
										publishDate: new Date(resource.publish_date),
										publishDateDiff: resource.publish_date_diff,
										interestStart: resource.interest_start
											? new Date(resource.interest_start)
											: null,
										interestStop: resource.interest_stop ? new Date(resource.interest_stop) : null,
										site_published: resource.site_published || false,
										type: resource.type || '',
										type_object: resource.type_object || null
									}}
									enableReinitialize
									validationSchema={validations.resources}
									onSubmit={(values) => {
										let resourceData = {
											...objectKeysToSnakeCase(values),
											folder_id: Number.parseInt(values.folderID),
											brand_id: values.brand.id,
											category_id: values.category.id,
											publish_date: lightFormat(new Date(values.publishDate), apiDateFormat),
											interest_start:
												showInterestDates && !isNull(values.interestStart)
													? lightFormat(new Date(values.interestStart), apiDateFormat)
													: null,
											interest_stop:
												showInterestDates && !isNull(values.interestStop)
													? lightFormat(new Date(values.interestStop), apiDateFormat)
													: null
										}

										updateResource(resourceData, resource.id)
									}}
								>
									{({
										setFieldValue,
										handleChange,
										handleBlur,
										values,
										handleSubmit,
										errors,
										touched
									}) => (
										<Form className='edit-resource-form'>
											<div className='edit-resource-card'>
												<div className='edit-resource-card-header-container'>
													<div className='header-left'>
														<div className='edit-resource-card-number-container'>
															<p className='edit-resource-card-number'>1</p>
														</div>
														<p className='edit-resource-card-title'>Brand și Categorie</p>
													</div>
													{isLoadingBrands || isLoadingResourceCategories ? <Loader /> : null}
												</div>
												<div className='edit-resource-card-content-container'>
													<div className='edit-resource-card-content-row'>
														<Select
															label='Brand'
															placeholder='Selectează brand-ul'
															value={values.brand}
															options={brands}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
															onChange={(option) => {
																setFieldValue('brand', option)
																setFieldValue('category', null)
																setFieldValue('folderID', null)

																if (!isNull(option)) {
																	listResourceCategories(option.id)
																}
															}}
															onBlur={handleBlur('brand')}
															name='brand'
															errors={resourcesErrors}
															frontendErrors={errors}
															touched={touched.brand}
															fullWidth
														/>
														<Select
															label='Categorie'
															placeholder='Selectează categoria'
															value={values.category}
															options={resourceCategories}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
															onChange={(option) => {
																setFieldValue('category', option)
																listFolders(option.id)

																this.setState({ editFolder: true })
															}}
															onBlur={handleBlur('category')}
															disabled={
																isNull(values.brand) || isLoadingResourceCategories
															}
															name='category'
															errors={resourcesErrors}
															frontendErrors={errors}
															touched={touched.category}
															fullWidth
														/>
													</div>
												</div>
											</div>
											<div className='edit-resource-card'>
												<div className='edit-resource-card-header-container'>
													<div className='header-left'>
														<div className='edit-resource-card-number-container'>
															<p className='edit-resource-card-number'>2</p>
														</div>
														<p className='edit-resource-card-title'>Folder și tip</p>
													</div>
													{editFolder &&
														toString(resource.category_id) ===
															toString(values.category.id) && (
															<Button
																title='Revino la folderul inițial'
																onClick={() => this.setState({ editFolder: false })}
																size='small'
															/>
														)}
												</div>
												<div className='edit-resource-card-content-container'>
													<div className='edit-resource-card-content-row'>
													
														{editFolder && !isEmpty(folders) && !isNull(values.category) ? (
															<div className='flex flex-col'>
																<FoldersTree
																	folders={folders}
																	selectedKey={String(resource.folder_id)}
																	isLoading={isLoadingFolders}
																	onSelect={(selectedFolderID) =>
																		setFieldValue('folderID', selectedFolderID)
																	}
																	onOpenAddFolderModal={() =>
																		this.setFoldersCallback(setFieldValue)
																	}
																/>
																<AddFolderModal selectedCategory={values.category} />
															</div>
														) : isEmpty(folders) || isNull(values.category) ? (
															<p className='select-category-text'>Selectați o categorie</p>
														) : !editFolder ? (
															<div className='current-folder-container'>
																<p className='current-folder-text'>
																	Folderul curent: <span>"{resource.folder.name}"</span>
																</p>
																<Button
																	title='Schimbă folderul'
																	onClick={() => this.setState({ editFolder: true })}
																	size='small'
																/>
															</div>
														) : null}
													
														<Select	
															label='Tip resursa'
															placeholder='Selectează tip-ul'
															value={values.type_object}
															options={typeOptions}
															getOptionLabel={(option) => option.label}
															getOptionValue={(option) => option.value}
															onChange={(option) => {
																setFieldValue('type_object', option)
																setFieldValue('type', option.value)
																console.log(option)
															}}
															onBlur={handleBlur('type')}
															name='type'
															errors={resourcesErrors}
															frontendErrors={errors}
															touched={touched.type}
															fullWidth
														/>
													</div>
												</div>
											</div>
											<div className='edit-resource-card'>
												<div className='edit-resource-card-header-container'>
													<div className='header-left'>
														<div className='edit-resource-card-number-container'>
															<p className='edit-resource-card-number'>3</p>
														</div>
														<p className='edit-resource-card-title'>Date de Acces</p>
													</div>
												</div>
												<div className='edit-resource-date-card-content-container'>
													<div className='left'>
														<DatePicker
															label='Data postare'
															value={values.publishDate}
															onChange={(value) => {
																setFieldValue('publishDate', new Date(value))
															}}
															onBlur={handleBlur('publishDate')}
															name='publishDate'
															errors={resourcesErrors}
															frontendErrors={errors}
															touched={touched.publishDate}
														/>
														<Toggle
															checked={values.publishDateDiff}
															label='Dată access diferențiată'
															onChange={(value) =>
																setFieldValue('publishDateDiff', value)
															}
														/>
													</div>
													<div className='right'>
														{!isEmpty(userCategories) &&
															userCategories.map((userCategory) => {
																const accessDate = sub(new Date(values.publishDate), {
																	days: values.publishDateDiff
																		? userCategory.days_before_access
																		: 0
																})

																return (
																	<GroupAccessDate
																		label={`Dată acces ${userCategory.name}`}
																		date={accessDate}
																		key={userCategory.id}
																	/>
																)
															})}
														<GroupAccessDate
															label='Dată acces toți clienții'
															date={values.publishDate}
														/>
													</div>
												</div>
											</div>
											<div className='edit-resource-card'>
												<div className='edit-resource-card-header-container'>
													<div className='header-left'>
														<div className='edit-resource-card-number-container'>
															<p className='edit-resource-card-number'>4</p>
														</div>
														<p className='edit-resource-card-title'>Afșare</p>
													</div>
												</div>
												<div className='edit-resource-card-content-container'>
													<div className='add-resource-card-content-row mb-4'>
														<Toggle
															label='Afișare pe site'
															checked={values.site_published}
															onChange={(value) => {
																setFieldValue('site_published', value)
															}}
														/>
													</div>
													<div className='add-resource-card-content-row'>
														<Toggle
															checked={showInterestDates}
															label='Interval de Afișare'
															onChange={(value) => {
																this.setState({ showInterestDates: value })
																setFieldValue('interestStart', null)
																setFieldValue('interestStop', null)
															}}
														/>
													</div>
													{showInterestDates ? (
														<div className='edit-resource-card-content-row'>
															<DatePicker
																label='Start Afișare'
																value={values.interestStart}
																onChange={(value) =>
																	setFieldValue('interestStart', new Date(value))
																}
																onBlur={handleBlur('interestStart')}
																name='interestStart'
																errors={resourcesErrors}
																frontendErrors={errors}
																touched={touched.interestStart}
															/>
															<DatePicker
																label='Final Afișare'
																value={values.interestStop}
																onChange={(value) =>
																	setFieldValue('interestStop', new Date(value))
																}
																onBlur={handleBlur('interestStop')}
																name='interestStop'
																errors={resourcesErrors}
																frontendErrors={errors}
																touched={touched.interestStop}
															/>
														</div>
													) : (
														''
													)}
												</div>
											</div>
											<div className='edit-resource-card'>
												<div className='edit-resource-card-header-container'>
													<div className='header-left'>
														<div className='edit-resource-card-number-container'>
															<p className='edit-resource-card-number'>5</p>
														</div>
														<p className='edit-resource-card-title'>Denumire</p>
													</div>
												</div>
												<div className='edit-resource-card-content-container'>
													<div className='edit-resource-card-content-row'>
														<Input
															label='Denumire resursa'
															value={values.name}
															placeholder='Introdu denumirea resursei'
															onChange={handleChange('name')}
															onBlur={handleBlur('name')}
															name='name'
															errors={resourcesErrors}
															frontendErrors={errors}
															touched={touched.name}
															fullWidth
														/>
													</div>
												</div>
											</div>
											<Button
												title='Salvează Resursa'
												onClick={handleSubmit}
												type='submit'
												size='extraLarge'
												fullWidth
											/>
										</Form>
									)}
								</Formik>
							) : isLoadingBrands || isLoadingUserCategories || isLoading ? (
								<PageLoader />
							) : null}
						</>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const userCategories = orderBy(omitBy(state.userCategories.data, 'is_admin'), 'days_before_access', 'desc')

	return {
		resource: state.resources.currentResource,
		resourcesErrors: state.resources.errors,
		brands: _values(state.brands.data),
		isLoadingBrands: state.brands.isLoading,
		userCategories: userCategories,
		isLoadingUserCategories: state.userCategories.isLoading,
		resourceCategories: _values(state.resourceCategories.data),
		isLoadingResourceCategories: state.resourceCategories.isLoading,
		folders: _values(state.folders.data),
		isLoadingFolders: state.folders.isLoading
	}
}

const mapDispatchToProps = (dispatch) => ({
	retrieveResource: (resourceID) => dispatch(RESOURCES.resources.retrieve(resourceID)),
	updateResource: (resourceData, resourceID) => dispatch(RESOURCES.resources.update(resourceData, resourceID)),
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	listResourceCategories: (brandID) => dispatch(RESOURCES.resourceCategories.list({ brand_id: brandID })),
	listFolders: (resourceCategoryID) => dispatch(listFolders(resourceCategoryID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditResource)
