import React from 'react'
import Select, { components } from 'react-select'

import { isEmpty, kebabCase, camelCase, snakeCase, head } from 'lodash'

import { InputError } from '../ErrorComponents'

import './Select.scss'

const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
		</ValueContainer>
	)
}

export default function CustomSelect({
	label,
	value,
	options,
	placeholder,
	onChange,
	onBlur,
	isMulti,
	isClearable,
	disableSearch,
	size,
	fullWidth,
	labelInline,
	labelInside,
	noOptionsMessage,
	getOptionLabel,
	getOptionValue,
	disabled,
	loading,
	name,
	errors,
	frontendErrors,
	touched
}) {
	const kebabSize = kebabCase(size)
	const snakeCaseName = snakeCase(name)
	const camelCaseName = camelCase(name)

	const hasErrors =
		(errors && !isEmpty(errors[snakeCaseName])) ||
		(frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

	const getErrorMessage = () =>
		errors && !isEmpty(errors[snakeCaseName]) ? head(errors[snakeCaseName]) : frontendErrors[camelCaseName]

	return (
		<div
			className={`edy-optic-select-container ${
				labelInside ? 'label-inside' : labelInline ? 'label-inline' : ''
			} ${fullWidth ? 'full-width' : ''}`}
		>
			{label && !labelInside && <label className='edy-optic-select-label'>{label}</label>}
			<Select
				value={value}
				options={options}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={labelInside ? label : placeholder ? placeholder : ''}
				isSearchable={disableSearch || true}
				isDisabled={disabled}
				isMulti={isMulti}
				isClearable={isClearable}
				isLoading={loading}
				menuPlacement='auto'
				classNamePrefix='edy-optic-select'
				className={`edy-optic-select ${kebabSize || 'medium'}`}
				noOptionsMessage={() => (noOptionsMessage ? noOptionsMessage : 'Fără rezultate')}
				getOptionLabel={(option) => (getOptionLabel ? getOptionLabel(option) : option.label)}
				getOptionValue={(option) => (getOptionValue ? getOptionValue(option) : option.value)}
				components={{
					ValueContainer: labelInside ? CustomValueContainer : ValueContainer
				}}
				styles={{
					valueContainer: (provided, state) => ({
						...provided,
						overflow: 'visible'
					}),
					singleValue: (provided) => ({
						...provided,
						position: labelInside ? 'absolute' : provided.position,
						top: labelInside ? 10 : 0
					}),
					placeholder: (provided, state) => ({
						...provided,
						position: 'absolute',
						top: state.hasValue || state.selectProps.inputValue ? -8 : 3,
						transition: 'top 0.1s, font-size 0.1s',
						fontSize: (state.hasValue || state.selectProps.inputValue) && 12
					}),
					input: (base) => ({ ...base, 'input:focus': { boxShadow: 'none' } })
				}}
			/>
			{hasErrors && <InputError message={getErrorMessage()} />}
		</div>
	)
}
