import React, { useState } from 'react'
import { EyeIcon, MagnifyingGlassCircleIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'

import { parseInt, isNull } from 'lodash'

import { connect } from 'react-redux'
import { addToCart } from '../../../../redux/cart'

import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

import './ProductCard.scss'

export const ProductCard = ({ product, addToCart, onViewDetails, onViewImages }) => {
	const { name, image_url, description, price, min_order } = product
	const [quantity, setQuantity] = useState(min_order ? parseInt(min_order) : 1)

	return (
		<div className='product-card-container'>
			<div className='product-image-container'>
				<Button 
					className='view-images-button'
					onClick={onViewImages}
					icon={() => <MagnifyingGlassCircleIcon />}
					color='default'
					size='large'
				/>
				<img src={image_url} alt={name} className='product-image' />
			</div>
			<div className='product-info'>
				<p className='product-name' onClick={onViewDetails}>
					{name}
				</p>
				<p className='product-description' dangerouslySetInnerHTML={{ __html: description }} />
				<div className='product-card-footer'>
					<div className='product-price-container'>
						<p className='product-price'>
							{isNull(price) ? 'Solicită ofertă' : `${price.toLocaleString()} RON`}
						</p>
						<p className='product-minimum-ammount'>Minim {min_order} buc.</p>
					</div>
					<div className='buttons-container'>
						<Button
							title='Vezi detalii'
							className='view-details-button'
							onClick={onViewDetails}
							icon={() => <EyeIcon />}
							color='secondary'
							size='large'
						/>
						<Input
							value={quantity}
							onChange={(e) => setQuantity(e.target.value ? parseInt(e.target.value) : '')}
							className='avo-quantity-input'
							type='number'
							size='large'
						/>
						<Button
							icon={() => <ShoppingCartIcon />}
							onClick={() => addToCart(product, quantity)}
							disabled={quantity < min_order}
							size='large'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	addToCart: (product, quantity) => dispatch(addToCart(product, quantity))
})

export default connect(null, mapDispatchToProps)(ProductCard)
