import React from 'react'

import { kebabCase } from 'lodash'

import { Loader } from '../Loader'

import './Button.scss'

export default function Button({
	title,
	type,
	icon,
	iconLeft,
	disabled,
	loading,
	onClick,
	size,
	variant,
	color,
	fullWidth,
	disableTabSelect,
	className,
	id
}) {
	const kebabColor = kebabCase(color)
	const kebabSize = kebabCase(size)

	return (
		<button
			onClick={onClick}
			id={id}
			className={
				'edy-optic-button ' +
				`${variant || 'contained'} ` +
				`${kebabSize || 'medium'} ` +
				`${kebabColor || 'primary'} ` +
				`${fullWidth ? 'full-width' : ''} ` +
				`${iconLeft ? 'icon-left' : ''} ` +
				`${className || ''}`
			}
			disabled={disabled || loading}
			tabIndex={disableTabSelect ? '-1' : null}
			type={type}
		>
			<p className='edy-optic-button-title'>{title ? title : null}</p>
			{loading || icon ? (
				<div className={`edy-optic-button-icon-container ${title ? 'has-title' : 'icon-only'}`}>
					{loading ? <Loader color={color === 'red' && 'red'} /> : icon()}
				</div>
			) : null}
		</button>
	)
}
