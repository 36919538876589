import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { keyBy, omit, includes } from 'lodash'

import { closeModal } from './modals'
import { getPages } from './utils'

import { RESOURCES } from './spec'

const { news: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	currentArticle: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	isLoading: false,
	isGettingFirstPage: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.main:
			currentPendingRequests = state.pendingRequests + 1

			const isGettingFirstPage = action.payload.request.params.page === 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0,
				isGettingFirstPage: isGettingFirstPage
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0,
				isGettingFirstPage: false
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				currentArticle: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateNewsSuccess({ payload: { data } }) {
	toast.success('Noutatea a fost adăugată cu succes!')
	yield put(push(`/brands/${data.brand.id}/news/${data.id}`))
}

function handleCreateNewsFail(response) {
	toast.error('Noutatea NU a fost adăugată!')
}

function* handleUpdateNewsSuccess({ payload: { data } }) {
	toast.success('Modificările au fost salvate!')
	yield put(push(`/brands/${data.brand.id}/news/${data.id}`))
}

function* handleDestroyNewsSuccess({ meta: { object } }) {
	toast.success('Articolul a fost șters cu succes!')

	yield put(closeModal())

	if (includes(window.location.pathname, '/brands/') && includes(window.location.pathname, '/news/')) {
		yield put(push(`/brands/${object.brand.id}/news`))
	}
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateNewsSuccess)
	yield takeEvery(resource.actions.create.fail, handleCreateNewsFail)
	yield takeEvery(resource.actions.update.success, handleUpdateNewsSuccess)
	yield takeEvery(resource.actions.destroy.success, handleDestroyNewsSuccess)
}
