import React, { Component } from 'react'
import { PencilIcon, PlusIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline'

import { isEmpty, isNull, values, debounce } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { usersSidebarItems } from '../../utils'

import { columns } from './constants'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Sidebar } from '../../components/Sidebar'
import { Loader } from '../../components/Loader'

import { AddUserModal, EditUserModal, DeleteUserModal } from './partials'

import './Users.scss'

export class Users extends Component {
	constructor() {
		super()

		this.state = {
			selectedUser: {},
			usersQuery: '',
			usersCategory: null
		}
	}

	componentDidMount() {
		const { listUserCategories } = this.props

		this.handleListUsers()
		listUserCategories()
	}

	debounceSearch = debounce((query) => this.handleListUsers(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ usersQuery: query })
		this.debounceSearch(query)
	}

	handleChangeUsersCategory = (category) => {
		const { usersQuery } = this.state

		this.setState({ userCategory: category })
		this.handleListUsers(usersQuery, category)
	}

	handleListUsers = (search = this.state.usersQuery, category = this.state.usersCategory) => {
		const { listUsers, isLoading } = this.props

		let params = {}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}
		if (!isNull(category)) {
			params = { ...params, category_id: category.id }
		}

		if (!isLoading) {
			listUsers(params)
		}
	}

	render() {
		const { selectedUser, usersQuery, userCategory } = this.state
		const { users, isLoading, userCategories, isLoadingUserCategories, openModal } = this.props

		return (
			<div className='users-page-container'>
				<Sidebar items={usersSidebarItems} />
				<AddUserModal />
				<EditUserModal user={selectedUser} />
				<DeleteUserModal user={selectedUser} />
				<div className='users-page-content-container'>
					<div className='users-page-header-container'>
						<PageHeader pageTitle='Utilizatori' parentRoute='/home' disableShadow />
						{!isLoading ? (
							<Button
								title='Adaugă Utilizator'
								icon={() => <PlusIcon />}
								onClick={() => openModal(modalTypes.ADD_USER)}
								className='add-button'
								size='large'
								iconLeft
							/>
						) : (
							<Loader />
						)}
					</div>
					<div className='filters-container'>
						<div className='filters-left'>
							<Input
								value={usersQuery}
								placeholder='Caută utilizatori'
								onChange={(e) => this.handleChangeSearch(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
								size='large'
							/>
						</div>
						<div className='filters-right'>
							<Select
								label='Categorie'
								value={userCategory}
								options={userCategories}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => this.handleChangeUsersCategory(option)}
								disabled={isLoadingUserCategories}
								labelInside
								isClearable
								fullWidth
							/>
						</div>
					</div>
					{!isLoading && (
						<div className='users-page-content'>
							<Datatable
								data={users}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'id',
										Cell: ({ row }) => (
											<div className='buttons-row'>
												<Button
													icon={() => <TrashIcon />}
													onClick={() => {
														this.setState({ selectedUser: row.original })
														openModal(modalTypes.DELETE_USER)
													}}
													color='red'
													size='small'
													iconLeft
												/>
												<Button
													title='Modifică'
													icon={() => <PencilIcon />}
													onClick={() => {
														this.setState({ selectedUser: row.original })
														openModal(modalTypes.EDIT_USER)
													}}
													size='small'
													iconLeft
												/>
											</div>
										)
									}
								]}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	users: values(state.users.data),
	isLoading: state.users.isLoading,
	userCategories: values(state.userCategories.data),
	isLoadingUserCategories: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listUsers: (params) => dispatch(RESOURCES.users.list(params)),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	openModal: (type) => dispatch(openModal(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
