import React from 'react'
import DatePicker from 'react-datepicker'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

import { format } from 'date-fns'

import './DatePicker.scss'

export default function CustomDatePicker({ value, label, onChange, onBlur, size, fullWidth }) {
	return (
		<div className={`avo-datepicker-container ${fullWidth ? 'full-width' : ''} ${size || ''}`}>
			{label && <label className='avo-datepicker-label'>{label}</label>}
			<DatePicker
				selected={value}
				onChange={(date) => onChange(date)}
				onBlur={onBlur}
				dateFormat='dd/MM/yyyy'
				isClearable={true}
				renderCustomHeader={({
					date,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => (
					<div className='avo-datepicker-custom-header-container'>
						<span className='avo-datepicker-month'>{format(date, 'MMMM yyyy')}</span>

						<div className='avo-datepicker-header-buttons-container'>
							<button
								type='button'
								onClick={decreaseMonth}
								disabled={prevMonthButtonDisabled}
								className={`avo-datepicker-header-month-button ${
									prevMonthButtonDisabled ? 'disabled' : ''
								}
                        `}
							>
								<ChevronLeftIcon className='avo-datepicker-header-month-button-icon' />
							</button>

							<button
								type='button'
								onClick={increaseMonth}
								disabled={nextMonthButtonDisabled}
								className={`avo-datepicker-header-month-button ${
									nextMonthButtonDisabled ? 'disabled' : ''
								}
                        `}
							>
								<ChevronRightIcon className='avo-datepicker-header-month-button-icon' />
							</button>
						</div>
					</div>
				)}
			/>
		</div>
	)
}
