import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { connect } from 'react-redux'

import { Button } from '../Button'

import { navigate } from '../../utils'

import './ViewCartToast.scss'

export const ViewCartToast = ({ product: { name, image_url }, cart, closeToast }) => {
	return (
		<div className='view-cart-toast-container'>
			<div className='view-cart-toast-left-container'>
				<div className='product-image-container'>
					<img src={image_url} alt={name} className='product-image' />
				</div>
				<div className='product-name-container'>
					<p className='product-name'>{name}</p>
					<div className='secondary-text'>a fost adăugat în coș</div>
				</div>
			</div>
			<div className='view-cart-toast-right-container'>
				<div className='total-price-container'>
					<p className='total-cart'>Total Coș</p>
					<p className='total-price'>{cart?.sale_total?.toLocaleString()} RON</p>
				</div>
				<div className='buttons-container'>
					<Button
						title='Vezi coș'
						onClick={() => {
							navigate('/cart')
							closeToast()
						}}
						className='view-cart-button'
					/>
					<Button icon={() => <XMarkIcon />} onClick={closeToast} />
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	cart: state.cart.data
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCartToast)
