import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

import './BrandResourcesCard.scss'

export default function BrandResourcesCard({ brand }) {
	return (
		<Link to={`/brands/${brand.id}`} className='brand-resource-card-container'>
			<div className='top' style={{ backgroundImage: `url(${brand.image_url})` }}>
				{/* <img src={brand.logo_url} alt={`${brand.name} Logo`} className='brand-image' /> */}
			</div>
			<div className='bottom'>
				<div className='texts-container'>
					<p className='brand-name-text'>{brand.name}</p>
				</div>
				<div className='resource-link'>
					<ArrowRightIcon className='link-icon' />
				</div>
			</div>
		</Link>
	)
}
