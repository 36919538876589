import { APIAction } from '.'
import { AUTH_ENDPOINTS } from './spec'
import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

const initialState = {
	pendingRequests: 0,
	isLoading: false,
	accountActivated: null,
	data: {},
	errors: {}
}

export const ACTIONS = {
	ACTIVATE_ACCOUNT: new APIAction('ACTIVATE_ACCOUNT'),
	LOGIN: new APIAction('LOGIN'),
	REFRESH_TOKEN: new APIAction('REFRESH_TOKEN'),
	SIGNUP: new APIAction('SIGNUP'),
	FORGOT_PASSWORD: new APIAction('FORGOT_PASSWORD'),
	RESET_PASSWORD: new APIAction('RESET_PASSWORD'),
	LOGOUT: new APIAction('LOGOUT')
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests

	switch (action.type) {
		case ACTIONS.ACTIVATE_ACCOUNT.main:
		case ACTIONS.LOGIN.main:
		case ACTIONS.LOGOUT.main:
		case ACTIONS.SIGNUP.main:
		case ACTIONS.FORGOT_PASSWORD.main:
		case ACTIONS.RESET_PASSWORD.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				isLoading: currentPendingRequests > 0,
				errors: {}
			}
		case ACTIONS.LOGIN.success:
		case ACTIONS.SIGNUP.success:
		case ACTIONS.FORGOT_PASSWORD.success:
		case ACTIONS.RESET_PASSWORD.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				isLoading: currentPendingRequests > 0,
				errors: {}
			}
		case ACTIONS.ACTIVATE_ACCOUNT.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				accountActivated: true,
				isLoading: currentPendingRequests > 0,
				errors: {}
			}
		case ACTIONS.ACTIVATE_ACCOUNT.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				accountActivated: false,
				isLoading: currentPendingRequests > 0,
				errors: {}
			}
		case ACTIONS.LOGIN.fail:
		case ACTIONS.LOGOUT.fail:
		case ACTIONS.SIGNUP.fail:
		case ACTIONS.FORGOT_PASSWORD.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				isLoading: currentPendingRequests > 0,
				errors: action.payload.errors
			}
		case ACTIONS.RESET_PASSWORD.fail:
			currentPendingRequests = state.pendingRequests - 1
			
			if(action.payload.message) {
				toast.error(action.payload.message)
			}
			return {
				...state,
				isLoading: currentPendingRequests > 0,
				errors: action.payload.errors
			}
		case ACTIONS.LOGOUT.success:
			currentPendingRequests = state.pendingRequests - 1

			return initialState
		default:
			return state
	}
}

function* handleLoginSuccess() {
	const query = new URLSearchParams(window.location.search)
	if(query.has('redirectTo')) {
		yield put(push(query.get('redirectTo')))
	}
	else {
		yield put(push('/home'))
	}
}

function handleLoginFail() {
	toast.error('Date de login incorecte!')
}

function* handleForgotPasswordSuccess() {
	toast.success('Am trimis un link de resetare a parolei pe adresa de email introdusă.')
	yield put(push('/login'))
}

function* handleResetPasswordSuccess() {
	toast.success('Parola a fost schimbată. Puteți folosi această parolă la login!')
	yield put(push('/login'))
}

function* handleLogout() {
	yield put(push('/login'))
}

export function* saga() {
	yield takeEvery(ACTIONS.LOGIN.success, handleLoginSuccess)
	yield takeEvery(ACTIONS.LOGIN.fail, handleLoginFail)
	yield takeEvery(ACTIONS.FORGOT_PASSWORD.success, handleForgotPasswordSuccess)
	yield takeEvery(ACTIONS.RESET_PASSWORD.success, handleResetPasswordSuccess)
	yield takeEvery(ACTIONS.LOGOUT.success, handleLogout)
}

export const activateAccount = (data) => {
	return {
		type: ACTIONS.ACTIVATE_ACCOUNT.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.ACTIVATE_ACCOUNT,
				method: 'POST',
				data: data
			}
		}
	}
}

export const login = (data) => {
	return {
		type: ACTIONS.LOGIN.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.LOGIN,
				method: 'POST',
				data: data
			}
		}
	}
}

export const refreshToken = (data) => {
	return {
		type: ACTIONS.REFRESH_TOKEN.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.REFRESH_TOKEN,
				method: 'POST',
				data: data
			}
		}
	}
}

export const signup = (data) => {
	return {
		type: ACTIONS.SIGNUP.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.SIGNUP,
				method: 'POST',
				data: data
			}
		}
	}
}

export const forgotPassword = (data) => {
	return {
		type: ACTIONS.FORGOT_PASSWORD.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.FORGOT_PASSWORD,
				method: 'POST',
				data: data
			}
		}
	}
}

export const resetPassword = (data) => {
	return {
		type: ACTIONS.RESET_PASSWORD.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.RESET_PASSWORD,
				method: 'POST',
				data: data
			}
		}
	}
}

export const logout = () => {
	return {
		type: ACTIONS.LOGOUT.main,
		payload: {
			request: {
				url: AUTH_ENDPOINTS.LOGOUT,
				method: 'POST'
			}
		}
	}
}

export function* handleUserUnauthenticated() {
	const { pathname, search } = window.location
	if(pathname !== '/login' && !pathname.includes('password-reset')) {
		yield put(push('/login?redirectTo=' + encodeURIComponent(pathname + search)))
	}
}
