import React from 'react'

import { isPast as _isPast, isToday, lightFormat } from 'date-fns'

import './GroupAccessDate.scss'

export default function GroupAccessDate({ label, date }) {
	const isPast = _isPast(new Date(date)) && !isToday(new Date(date))

	return (
		<div className='group-access-date-container'>
			<p className='group-access-date-label'>{label}</p>
			<p className='group-access-date'>{lightFormat(date, 'dd/MM/yyyy')}</p>
			{isPast && (
				<div className='disclaimer-container'>
					<p className='disclaimer-text'>Această dată este din trecut!</p>
				</div>
			)}
		</div>
	)
}
