import { toast } from 'react-toastify'

import { replace, trimEnd } from 'lodash'

export const isFirstPage = (response) => {
	return response.page === 1
}

export const getPages = ({ current_page: currentPage, last_page: lastPage }) => {
	let pages = { first: 1, previous: null, current: currentPage, next: null, last: lastPage }

	if (currentPage > 1) {
		pages = { ...pages, previous: currentPage - 1 }
	}

	if (currentPage === lastPage) {
		pages = { ...pages, next: null }
	} else if (currentPage < lastPage) {
		pages = { ...pages, next: currentPage + 1 }
	}

	return pages
}

export const cleanURL = (url) => {
	return trimEnd(replace(url, '//', '/'), '/')
}

export function toastError(error) {
	if (error) {
		toast.error(error)
	}
}
