import React, { Component } from 'react'
import { Form, Formik } from 'formik'

import { values as _values, isEmpty, head } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Select } from '../../../../components/Select'
import { Button } from '../../../../components/Button'
import { PhoneInput } from '../../../../components/PhoneInput'
import { Loader } from '../../../../components/Loader'

import { objectKeysToSnakeCase } from '../../../../utils'
import { validations } from '../../../../assets/validations'

import './AddUserModal.scss'

class AddUserModal extends Component {
	componentDidMount() {
		const { listUserCategories } = this.props

		listUserCategories()
	}

	render() {
		const { open, usersErrors, isLoading, userCategories, isLoadingUserCategories, createUser } = this.props

		return (
			<Modal open={open} title='Adaugă utilizator'>
				{!isEmpty(userCategories) && !isLoadingUserCategories ? (
					<Formik
						initialValues={{
							lastName: '',
							firstName: '',
							email: '',
							phone: '+40',
							category: head(userCategories),
							optica: '',
							address: '',
							city: ''
						}}
						validationSchema={validations.users}
						onSubmit={(values) =>
							createUser({ ...objectKeysToSnakeCase(values), category_id: values.category.id })
						}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<Form className='add-user-modal-form-container'>
								<div className='add-user-modal-form-row'>
									<Input
										label='Nume'
										value={values.lastName}
										placeholder='Numele utilizatorului'
										onChange={handleChange('lastName')}
										onBlur={handleBlur('lastName')}
										size='large'
										name='lastName'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.lastName}
										fullWidth
									/>
									<Input
										label='Prenume'
										value={values.firstName}
										placeholder='Prenumele utilizatorului'
										onChange={handleChange('firstName')}
										onBlur={handleBlur('firstName')}
										size='large'
										name='firstName'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.firstName}
										fullWidth
									/>
								</div>
								<div className='add-user-modal-form-row'>
									<Input
										label='Email'
										value={values.email}
										placeholder='Adresa de email a utilizatorului'
										onChange={handleChange('email')}
										onBlur={handleBlur('email')}
										size='large'
										name='email'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.email}
										fullWidth
									/>
									<PhoneInput
										label='Telefon'
										value={values.phone}
										onChange={(phoneNumber) => setFieldValue('phone', phoneNumber)}
										onBlur={handleBlur('phone')}
										name='phone'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.phone}
										fullWidth
									/>
								</div>
								<Select
									label='Categorie'
									value={values.category}
									options={userCategories}
									placeholder='Alege categoria utilizatorului'
									size='large'
									getOptionValue={(option) => option.id}
									getOptionLabel={(option) => option.name}
									onChange={(option) => setFieldValue('category', option)}
									onBlur={handleBlur('category')}
									name='category'
									errors={usersErrors}
									frontendErrors={errors}
									touched={touched.category}
									isClearable
									fullWidth
								/>
								<div className='add-user-modal-form-row'>
									<Input
										label='Optica'
										value={values.optica}
										placeholder='Optica'
										onChange={handleChange('optica')}
										onBlur={handleBlur('optica')}
										size='large'
										name='optica'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.optica}
										fullWidth
									/>
									<Input
										label='Localitate'
										value={values.city}
										placeholder='Localitatea'
										onChange={handleChange('city')}
										onBlur={handleBlur('city')}
										size='large'
										name='city'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.city}
										fullWidth
									/>
								</div>
								<Input
									label='Adresa'
									value={values.address}
									placeholder='Adresa'
									onChange={handleChange('address')}
									onBlur={handleBlur('address')}
									size='large'
									name='address'
									errors={usersErrors}
									frontendErrors={errors}
									touched={touched.address}
									fullWidth
								/>
								<Button
									title='Salvează utilizatorul'
									onClick={handleSubmit}
									loading={isLoading}
									type='submit'
									size='large'
									fullWidth
								/>
							</Form>
						)}
					</Formik>
				) : isLoadingUserCategories ? (
					<Loader />
				) : null}
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.ADD_USER,
	usersErrors: state.users.errors,
	isLoading: state.users.isLoading,
	userCategories: _values(state.userCategories.data),
	isLoadingUserCategories: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createUser: (values) => dispatch(RESOURCES.users.create(values)),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)
