import React, { Component } from 'react'
import Slider from 'react-slick'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { Button } from '../Button'

import './ImagesSlider.scss'

export default class ImagesSlider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			nav1: null,
			nav2: null
		}
	}

	componentDidMount() {
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2
		})
	}

	render() {
		const { images, getImageSrc, onRemoveImage, hasPreviews } = this.props

		const imagesToShow = images.length >= 3 ? 3 : images.length

		const mainSettings = {
			infinite: true,
			speed: 500
		}

		const previewsSettings = {
			infinite: true,
			speed: 500,
			slidesToShow: imagesToShow,
			focusOnSelect: true
		}

		return (
			<div className='edy-optic-images-slider-container'>
				<div className='edy-optic-sliders'>
					<Slider
						asNavFor={this.state.nav2}
						ref={(slider) => (this.slider1 = slider)}
						className={`edy-optic-main-images-slider ${hasPreviews ? 'has-previews' : ''}`}
						{...mainSettings}
					>
						{images.map((image, index) => {
							const imagePreview = getImageSrc(image)

							return (
								<div className='image-container-outer' key={'image-' + index}>
									<div className={`image-container-inner ${onRemoveImage ? 'has-buttons' : ''}`}>
										{onRemoveImage && (
											<div className='button-container'>
												<Button
													onClick={() => onRemoveImage(image)}
													icon={() => <XMarkIcon />}
													color='red'
												/>
											</div>
										)}
										<img src={imagePreview} alt='' />
										{onRemoveImage && <div className='placeholder-div' />}
									</div>
								</div>
							)
						})}
					</Slider>
					{hasPreviews && (
						<Slider
							asNavFor={this.state.nav1}
							ref={(slider) => (this.slider2 = slider)}
							className='edy-optic-previews-slider'
							{...previewsSettings}
						>
							{images.map((image, index) => {
								const imagePreview = getImageSrc(image)

								return (
									<div className='image-container-outer' key={'preview-' + index}>
										<div className='image-container-inner'>
											<img src={imagePreview} alt='' />
										</div>
									</div>
								)
							})}
						</Slider>
					)}
				</div>
			</div>
		)
	}
}
