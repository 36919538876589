import React, { Component } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'

import { isNull, debounce } from 'lodash'

import { connect } from 'react-redux'
import { addToCart } from '../../../../redux/cart'

import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

import './CartProductCard.scss'

export class CartProductCard extends Component {
	constructor(props) {
		super(props)
		const { product } = props
		this.state = {
			selectedQuantity: parseInt(product?.quantity),
			oldQuantity: parseInt(product?.quantity)
		}
	}

	debounceAddToCart = debounce((product, quantity) => this.props.addToCart(product, quantity, false), 500)

	handleChangeQuantity = (quantity) => {
		const {
			product: { product }
		} = this.props

		const intQuantity = quantity ? parseInt(quantity) : ''
		this.setState({ selectedQuantity: intQuantity })

		if(intQuantity >= product.min_order) {
			this.debounceAddToCart(product, intQuantity)
			this.setState({ oldQuantity: intQuantity })
		}
	}

	handleBlur = () => {
		const { selectedQuantity, oldQuantity } = this.state
		const {
			product: { product }
		} = this.props

		if(!selectedQuantity || selectedQuantity < product.min_order) {
			this.setState({selectedQuantity: oldQuantity})
		}
	}

	render() {
		const { selectedQuantity } = this.state
		const { user, product, handleRemoveFromCart } = this.props

		const {
			product: { name, image_url },
			price,
			sale_price: salePrice,
			subtotal,
			sale_subtotal: saleSubtotal
		} = product

		const hasDiscount = user.category.discount ? true : false

		return (
			<div className='cart-product-card-container'>
				<div className='cart-product-card-left'>
					<div className='product-image-container'>
						<img src={image_url} alt={name} className='product-image' />
					</div>
					<div className='product-info'>
						<p className='product-name'>{name}</p>
					</div>
				</div>
				<div className='cart-product-card-right'>
					<Input
						value={selectedQuantity}
						onChange={(e) => this.handleChangeQuantity(e.target.value)}
						onBlur={() => this.handleBlur()}
						className='avo-quantity-input'
						type='number'
						size='large'
					/>
					<div className='price-container'>
						{!isNull(price) ? (
							<>
								<p className='price-type'>Preț/buc.</p>
								{hasDiscount && <p className='old-price'>{price.toLocaleString()} RON</p>}
								<p className='price'>{salePrice.toLocaleString()} RON</p>
							</>
						) : (
							<p className='price'>Solicită ofertă</p>
						)}
					</div>
					<div className='price-container'>
						<p className='price-type'>Subtotal</p>
						{hasDiscount && <p className='old-price'>{subtotal.toLocaleString()} RON</p>}
						<p className='price'>{saleSubtotal.toLocaleString()} RON</p>
					</div>
					<Button icon={() => <TrashIcon />} onClick={() => handleRemoveFromCart(product)} color='red' />
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
	addToCart: (product, quantity, showToast) => dispatch(addToCart(product, quantity, showToast))
})

export default connect(mapStateToProps, mapDispatchToProps)(CartProductCard)
