import React from 'react'

import './Tabs.scss'

export default function Tabs({ label, tabs, initialSelectedTab, onChangeTab }) {
	return (
		<div className='edy-optic-tabs-component-container'>
			{label && <p className='edy-optic-tabs-label'>{label}</p>}

			<div className='edy-optic-tabs-container'>
				{tabs.map((tab) => {
					return (
						<div
							className={`tab-container ${tab.color || ''} ${tab === initialSelectedTab ? 'selected' : ''}`}
							onClick={() => onChangeTab(tab)}
							key={tab.type}
						>
							<div className='tab-title'>{tab.title}</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
