import React from 'react'
import FormData from 'form-data'
import { Form, Formik } from 'formik'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { SingleImageDropzone } from '../../../../components/SingleImageDropzone'

import { validations } from '../../../../assets/validations'

import './AddBrandModal.scss'
import { ImageDropzone } from '../../../../components/ImageDropzone'

export const AddBrandModal = ({ open, brandsErrors, isLoading, createBrand }) => {
	return (
		<Modal open={open} title='Adaugă brand'>
			<Formik
				initialValues={{ 
					name: '', 
					logo: {}, 
					image: {}, 
					logo_crop: '', 
					imge_crop: '' ,
					sales_image: {},
					resources_image: {},
					news_image: {},
					products_image: {}
				}}
				validationSchema={validations.brands}
				onSubmit={async (values) => {
					let brandData = new FormData()
					brandData.append('name', values.name)

					brandData.append('logo', values.logo, values.logo.name)
					brandData.append('logo_crop', values.logo_crop)

					brandData.append('image', values.image, values.image.name)
					brandData.append('image_crop', values.image_crop)

					brandData.append('sales_image', values.sales_image, values.sales_image.name)
					brandData.append('resources_image', values.resources_image, values.resources_image.name)
					brandData.append('news_image', values.news_image, values.news_image.name)
					brandData.append('products_image', values.products_image, values.products_image.name)

					createBrand(brandData)
				}}
			>
				{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
					<Form className='add-brand-modal-form-container'>
						<Input
							label='Nume Brand'
							value={values.name}
							placeholder='Introdu numele brandului'
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
							size='large'
							name='name'
							errors={brandsErrors}
							frontendErrors={errors}
							touched={touched.name}
							fullWidth
						/>
						{/* <div className='add-brand-modal-form-row'>
							<SingleImageDropzone
								label='Logo Brand'
								onChange={(file) => setFieldValue('logo', file)}
								onCrop={(crop) => setFieldValue('logo_crop', JSON.stringify(crop))}
								aspectRatio={1}
								name="logo"
							/>
						</div> */}
						<div className='add-brand-modal-form-row'>
							<SingleImageDropzone
								label='Imagine Meniu'
								onChange={(file) => setFieldValue('image', file)}
								onCrop={(crop) => setFieldValue('image_crop', JSON.stringify(crop))}
								aspectRatio={0.85}
								name="image"
							/>
						</div>

						<div className='add-brand-modal-form-row'>
							<label className="edy-optic-label">Imagine Noutati</label>
							<ImageDropzone
								label='Incarca imagine'
								onChangeFiles={(files) => setFieldValue('news_image', files[0])}
							/>
						</div>

						<div className='add-brand-modal-form-row'>
							<label className="edy-optic-label">Imagine Oferte</label>
							<ImageDropzone
								label='Incarca imagine'
								onChangeFiles={(files) => setFieldValue('sales_image', files[0])}
							/>
						</div>

						<div className='add-brand-modal-form-row'>
							<label className="edy-optic-label">Imagine Produse</label>
							<ImageDropzone
								label='Incarca imagine'
								onChangeFiles={(files) => setFieldValue('products_image', files[0])}
							/>
						</div>

						<div className='add-brand-modal-form-row'>
							<label className="edy-optic-label">Imagine Resurse</label>
							<ImageDropzone
								label='Incarca imagine'
								onChangeFiles={(files) => setFieldValue('resources_image', files[0])}
							/>
						</div>
						<Button
							title='Salvează brandul'
							onClick={handleSubmit}
							loading={isLoading}
							disabled={isEmpty(values.name) || isEmpty(values.logo) || isEmpty(values.image)}
							type='submit'
							size='large'
							fullWidth
						/>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.ADD_BRAND,
	brandsErrors: state.brands.errors,
	isLoading: state.brands.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	createBrand: (values) => dispatch(RESOURCES.brands.create(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBrandModal)
