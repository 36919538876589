import { toast } from 'react-toastify'
import { put, select, takeEvery } from 'redux-saga/effects'
import URITemplate from 'urijs/src/URITemplate'

import { keyBy, omit, values } from 'lodash'

import { Action, APIAction } from '.'
import { cleanURL } from './utils'
import { closeModal } from './modals'
import { CART_ENDPOINTS } from './spec'

import { ViewCartToast } from '../components/ViewCartToast'

const ACTIONS = {
	ADD_TO_LOCAL_CART: new Action('ADD_TO_LOCAL_CART'),
	REMOVE_FROM_CART: new Action('REMOVE_FROM_CART'),
	RESET_CART: new Action('RESET_CART'),
	GET_CART: new APIAction('GET_CART')
}

const initialState = {
	pendingRequests: 0,
	data: {},
	errors: {},
	localProducts: {},
	isLoading: false
}

export function reducer(state = initialState, action = {}) {
	let localProducts
	let currentPendingRequests

	switch (action.type) {
		case ACTIONS.ADD_TO_LOCAL_CART.main:
			const { product, quantity } = action.payload

			const productByKey = keyBy({ product: { ...product, quantity } }, 'id')
			localProducts = { ...state.localProducts, ...productByKey }

			return {
				...state,
				localProducts: localProducts
			}
		case ACTIONS.REMOVE_FROM_CART.main:
			const { productID } = action.payload

			return {
				...state,
				localProducts: omit(state.localProducts, productID)
			}
		case ACTIONS.GET_CART.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.GET_CART.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: action.payload.data,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.GET_CART.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.RESET_CART.main:
			return initialState
		default:
			return state
	}
}

function* handleAddToCart(res) {
	const { product, showToast } = res.payload

	if(showToast) {
		toast(<ViewCartToast product={product} />, {
			className: 'view-cart-toast',
			position: 'top-center',
			closeButton: false,
			hideProgressBar: true,
			closeOnClick: false,
			autoClose: false
		})
	}

	yield put(closeModal())

	const localProducts = yield select((state) => state.cart.localProducts)
	const productsArray = generateProductsArray(localProducts)

	yield put(getCart({ products: productsArray }))
}

function* handleRemoveFromCart() {
	const localProducts = yield select((state) => state.cart.localProducts)
	const productsArray = generateProductsArray(localProducts)

	yield put(getCart({ products: productsArray }))
}

export function* saga() {
	yield takeEvery(ACTIONS.ADD_TO_LOCAL_CART.main, handleAddToCart)
	yield takeEvery(ACTIONS.REMOVE_FROM_CART.main, handleRemoveFromCart)
}

export const addToCart = (product, quantity, showToast = true) => ({
	type: ACTIONS.ADD_TO_LOCAL_CART.main,
	payload: { product, quantity, showToast }
})

export const removeFromCart = (product) => ({
	type: ACTIONS.REMOVE_FROM_CART.main,
	payload: { productID: product.id }
})

export const resetCart = () => ({
	type: ACTIONS.RESET_CART.main
})

export const getCart = (data) => {
	const endpoint = URITemplate(CART_ENDPOINTS.MAIN)
	const url = endpoint.expand()

	return {
		type: ACTIONS.GET_CART.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'POST',
				data: data
			}
		},
		meta: {
			object: data
		}
	}
}

const generateProductsArray = (products) => {
	const productsArray = values(products).map((product) => ({
		product_id: product.id,
		quantity: product.quantity
	}))

	return productsArray
}
