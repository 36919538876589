import { put, takeEvery } from 'redux-saga/effects'
import URITemplate from 'urijs/src/URITemplate'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { keyBy, omit } from 'lodash'

import { APIAction } from '.'
import { resetCart } from './cart'
import { cleanURL, getPages } from './utils'
import { ORDERS_ENDPOINTS, RESOURCES } from './spec'

const { orders: resource } = RESOURCES

const ACTIONS = {
	UPDATE_ORDER_STATUS: new APIAction('UPDATE_ORDER_STATUS')
}

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	currentOrder: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	isLoading: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
		case ACTIONS.UPDATE_ORDER_STATUS.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
		case ACTIONS.UPDATE_ORDER_STATUS.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				currentOrder: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.fail:
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case ACTIONS.UPDATE_ORDER_STATUS.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateOrderSuccess() {
	toast.success('Comanda a fost plasată cu succes!')

	yield put(resetCart())
	yield put(push('/orders'))
}

function handleCreateOrderFail() {
	toast.error('Comanda NU a putut fi plasată!')
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateOrderSuccess)
	yield takeEvery(resource.actions.create.fail, handleCreateOrderFail)
}

export const updateOrderStatus = (orderID, data) => {
	const endpoint = URITemplate(ORDERS_ENDPOINTS.UPDATE)
	const url = endpoint.expand({ orderID: orderID })

	return {
		type: ACTIONS.UPDATE_ORDER_STATUS.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'POST',
				data: data
			}
		},
		meta: {
			object: data
		}
	}
}
