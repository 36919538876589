import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'

import { orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import './BrandResourceCategories.scss'
import { goBack } from 'connected-react-router'

export class BrandResourceCategories extends Component {
	componentDidMount() {
		const {
			retrieveBrands,
			match: { params }
		} = this.props

		const { brandID } = params

		retrieveBrands(brandID)
	}

	render() {
		const { brand, isLoading, goBack } = this.props

		return (
			<div className='brand-resources-container'>
				<div className='brand-resources-header'>
					<div className='left'>
						<Button
							icon={() => <ArrowLeftIcon className='back-button-icon' />}
							onClick={goBack}
							size='large'
							color='primaryLight'
							className='back-button'
						/>
						<p className='brand-name'>Resurse {brand.name}</p>
					</div>
					{isLoading && (
						<Loader />
					)}
				</div>
				<div className='resources-container-outter'>
					<div className='resources-container'>
						{brand.categories &&
							orderBy(brand.categories, ['read_only', 'order', 'id'], ['desc', 'asc', 'asc']).map(
								(category) => <BrandResourceCategory category={category} key={category.id} />
							)}
					</div>
				</div>
			</div>
		)
	}
}

function BrandResourceCategory({ category }) {
	const getResorceCategoryLink = () =>
		category.link
			? category.link
			: `/brands/${category.brand_id}/resource-categories/${category.id}/${category.folder.id}`

	return (
		<Link to={getResorceCategoryLink()} className={`brand-resource-container ${!!category.image_url ? 'has-image' : ''}`}>
			{category.image_url ? (
				<img className="category-image" src={category.image_url} alt={category.name} />
			) : (
				<>
					<div className='brand-resource-icon-container'>
						{category.getIcon ? category.getIcon() : <img src={category.icon_url} alt='' />}
					</div>
					<div className='brand-resource-title-container'>
						<p className='brand-resource-title'>{category.name}</p>
					</div>
					<ArrowRightIcon className='brand-resource-arrow' />
				</>
			)}
		</Link>
	)
}

const mapStateToProps = (state) => ({
	brand: state.brands.currentBrand,
	isLoading: state.brands.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	retrieveBrands: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID)),
	goBack: () => dispatch(goBack())
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandResourceCategories)
