import { isEmpty, slice } from 'lodash'

import { CategoryBadge } from './partials'

const categoriesToShow = 4

export const columns = [
	{
		Header: 'Ordine',
		accessor: 'order'
	},
	{
		Header: 'Nume brand',
		accessor: 'name',
		Cell: (props) => <p className='datatable-brand-name'>{props.value}</p>
	},
	{
		Header: 'Logo',
		accessor: 'logo_url',
		Cell: ({ value, row }) => (
			<div className='brand-logo-container'>
				<img src={value} alt={`${row.original.name} logo`} />
			</div>
		)
	},
	{
		Header: 'Imagine meniu',
		accessor: 'image_url',
		Cell: ({ value: image_url }) => (
			<div className='brand-menu-image-container'>
				<img src={image_url} alt='Imagine meniu' />
			</div>
		)
	},
	{
		Header: 'Category resurse',
		accessor: 'categories',
		Cell: ({ value: categories }) =>
			!isEmpty(categories) && (
				<div className='category-badges-container'>
					{slice(categories, 0, categoriesToShow).map((category) => (
						<CategoryBadge category={category.name} key={category.id} />
					))}
					{categories.length > categoriesToShow && (
						<p className='ml-2'>+ {categories.length - categoriesToShow}</p>
					)}
				</div>
			)
	}
]
