import React from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

import { MyCart, UserComponent } from './partials'

import logo from '../../assets/images/logo-edyoptic-new.svg'
import AppIndicator from '../../assets/images/app-indicator-dark.svg'

import './Navbar.scss'

export function Navbar() {
	return (
		<div className='navbar-container'>
			<Link to='/home' className='left'>
				<div className='logo-container'>
					<img src={logo} alt='Edi Optic Logo' />
				</div>
				<img src={AppIndicator} alt='Portal Clienți App Indicator' className='app-indicator-image' />
			</Link>

			<div className='right'>
				<MyCart />
				<UserComponent />
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
