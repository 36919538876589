import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { isEmpty, replace } from 'lodash'

import { connect } from 'react-redux'
import { resetPassword } from '../../redux/auth'
import { validations } from '../../assets/validations'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { Checkbox } from '../../components/Checkbox'

import logo from '../../assets/images/logo-edyoptic-new.svg'

import './ResetPassword.scss'

export const ResetPassword = ({ authErrors, isLoading, resetPassword, location, match: { params } }) => {
	const [showPassword, setShowPassword] = useState(false)

	const email = replace(location.search, '?email=', '')

	return (
		<div className='reset-password-container'>
			<div className='main-container'>
				<div className='header'>
					<img className='logo' src={logo} alt='EdyOptic' />
					<h1 className='title'>Resetare parolă</h1>
				</div>
				<div className='m-6 mt-0'>
					<Formik
						initialValues={{
							email: email,
							password: '',
							passwordConfirmation: '',
							token: params.token
						}}
						validationSchema={validations.resetPassword}
						onSubmit={(values) =>
							resetPassword({
								email: values.email,
								password: values.password,
								password_confirmation: values.passwordConfirmation,
								token: values.token
							})
						}
					>
						{({ handleChange, handleBlur, values, handleSubmit, errors, touched }) => (
							<Form className='space-y-6'>
								<Input
									value={values.email}
									onChange={handleChange('email')}
									onBlur={handleBlur('email')}
									label='Adresa de email'
									type='email'
									autoComplete='email'
									name='email'
									errors={authErrors}
									frontendErrors={errors}
									touched={touched.email}
									fullWidth
								/>
								<Input
									label='Parola'
									value={values.password}
									onChange={handleChange('password')}
									onBlur={handleBlur('password')}
									autoComplete='password'
									type={showPassword ? 'text' : 'password'}
									name='password'
									errors={authErrors}
									frontendErrors={errors}
									touched={touched.password}
									fullWidth
								/>
								<div className='password-input-container'>
									<Input
										label='Confirmare parola'
										value={values.passwordConfirmation}
										onChange={handleChange('passwordConfirmation')}
										onBlur={handleBlur('passwordConfirmation')}
										autoComplete='password'
										type={showPassword ? 'text' : 'password'}
										name='passwordConfirmation'
										errors={authErrors}
										frontendErrors={errors}
										touched={touched.passwordConfirmation}
										fullWidth
									/>
									<Checkbox
										label='Afișează parola'
										onChange={(e) => setShowPassword(e.target.checked)}
										checked={showPassword}
									/>
								</div>
								<Button
									title='Schimbă parola'
									onClick={handleSubmit}
									disabled={isEmpty(values.password) || isEmpty(values.email)}
									loading={isLoading}
									fullWidth
								/>
							</Form>
						)}
					</Formik>
					<div className='back-to-login-container'>
						<Link to='/login' className='back-to-login-link'>
							Înapoi la login
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	authErrors: state.auth.errors,
	isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (values) => dispatch(resetPassword(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
