import { keyBy } from 'lodash'

import { RESOURCES } from './spec'

const { orderStatuses: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	isLoading: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const resultsByKey = keyBy(action.payload, 'id')

			return {
				...state,
				data: resultsByKey,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}
