import React, { useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes } from '../../redux/modals'

import { Modal } from '../Modal'
import { Button } from '../Button'
import { ImagesSlider } from '../ImagesSlider'
import { Loader } from '../Loader'

import './ProductImagesModal.scss'

export const ProductImagesModal = ({ open, product, productID, onClose, isLoading, retrieveProduct, addToCart }) => {
	useEffect(() => productID && retrieveProduct(productID), [productID, retrieveProduct])

	return (
		<Modal
			open={open && productID && !isEmpty(product)}
			onClose={onClose}
			className='product-images-modal'
			removeHeader
		>
			<div className='product-images-modal-container'>
				{!isEmpty(product) && !isLoading ? (
					<>
						<Button icon={() => <XMarkIcon />} onClick={onClose} className='mobile-close-modal-button' />
						<div className='product-images-modal-image-container'>
							{!isEmpty(product.images) ? (
								<div className='products-images-slider-container'>
									<ImagesSlider
										images={product.images}
										getImageSrc={(image) => image.url}
										hasPreviews={product.images.length > 1}
									/>
								</div>
							) : (
								<img src={product.image_url} alt='' />
							)}
						</div>						
					</>
				) : isLoading ? (
					<div className='loader-container'>
						<Loader />
					</div>
				) : null}
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.PRODUCT_IMAGES,
	product: state.products.currentProduct,
	isLoading: state.products.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	retrieveProduct: (productID) => dispatch(RESOURCES.products.retrieve(productID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductImagesModal)
