import { lightFormat } from 'date-fns/esm'

export const columns = [
	{
		Header: 'Data',
		accessor: 'created_at',
		Cell: ({ value: created_at }) => lightFormat(new Date(created_at), 'dd/MM/yyyy')
	},
	{
		Header: 'Titlu',
		accessor: 'title'
	},
	{
		Header: 'Brand',
		accessor: 'brand',
		Cell: ({ value: brand }) => brand.name
	},
	{
		Header: 'Autor',
		accessor: 'author'
	},
	{
		Header: 'Resurse conectate',
		accessor: 'resources',
		Cell: ({ value: resources }) => `${resources.length} ${resources.length === 1 ? 'Resursă' : 'Resurse'}`
	}
]
