import React from 'react'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { snakeCase, camelCase, isEmpty, head } from 'lodash'

import { InputError } from '../ErrorComponents'

import './PhoneInput.scss'

export default function CustomPhoneInput({
	label,
	placeholder,
	value,
	onChange,
	onBlur,
	name,
	errors,
	frontendErrors,
	touched,
	fullWidth,
	className
}) {
	const snakeCaseName = snakeCase(name)
	const camelCaseName = camelCase(name)

	const hasErrors =
		(errors && !isEmpty(errors[snakeCaseName])) ||
		(frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

	const getErrorMessage = () =>
		errors && !isEmpty(errors[snakeCaseName]) ? head(errors[snakeCaseName]) : frontendErrors[camelCaseName]

	return (
		<div className={`edy-optic-phone-input-label-container ${fullWidth ? 'full-width' : ''} ${className || ''}`}>
			{label && <label className='edy-optic-label'>{label}</label>}
			<div className='edy-optic-phone-input-wrapper'>
				<PhoneInput
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					onBlur={onBlur}
					international
					defaultCountry='RO'
					countryCallingCodeEditable
				/>
			</div>
			{hasErrors && <InputError message={getErrorMessage()} />}
		</div>
	)
}
