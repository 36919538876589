import React, { Component } from 'react'
import {
	ArrowDownTrayIcon,
	FolderIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { isEmpty, values, orderBy, toUpper, isNull } from 'lodash'

import { connect } from 'react-redux'
import { toastError } from '../../redux/utils'
import { resetResponses } from '../../redux/resources'
import { listPersonalFolders } from '../../redux/folders'

import { resourceTypes } from './constants'
import { sortingDirections } from '../../types'
import { formatBytes, navigate } from '../../utils'

import { Button } from '../../components/Button'
import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'
import { FileTypeBadge } from '../../components/FileTypeBadge'
import { Loader } from '../../components/Loader'


import './PersonalFolder.scss'

export class PersonalFolder extends Component {
	constructor() {
		super()

		const query = new URLSearchParams(window.location.search)
		const accessor = query.get('orderBy') ? query.get('orderBy') : null
		const direction = query.get('order') ? query.get('order') : null

		this.state = {
			datatableData: [],
			sort: { direction, accessor }
		}
	}

	componentDidMount = () => {
		const {
			resetResponses,
			listPersonalFolders,
		} = this.props

		listPersonalFolders()
		resetResponses()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			folders,
			isLoadingFolders,
			foldersStatus,
			foldersMessage,
		} = this.props

		if (foldersStatus === 404) {
			toastError(foldersMessage)

			navigate('/home')
		}

		if (folders !== prevProps.folders && !isLoadingFolders) {
			this.getDatatableData()
		}
	}

	getDatatableData = () => {
		const { sort } = this.state
		const { folders } = this.props

		let datatableFolders = orderBy(
			folders.map((folder) => ({
				id: folder.id,
				brand_id: folder.brand_id,
				category_id: folder.category_id,
				name: folder.name,
				type: resourceTypes.FOLDER,
				extension: 'folder',
				size: folder.resources_count
					? folder.resources_count + (folder.resources_count === 1 ? ' resursă' : ' resurse')
					: '0 resurse'
			})),
			sort.accessor,
			sort.direction
		)

		this.setState({ datatableData: datatableFolders })
	}

	columnHeaderClick = (column) => {
		const { sort } = this.state

		if (sort.accessor === column.id) {
			switch (sort.direction) {
				case sortingDirections.ASC:
					this.handleChangeSorting(column.id, sortingDirections.DESC)
					break
				case sortingDirections.DESC:
					this.handleChangeSorting(null, null)
					break
				default:
					this.handleChangeSorting(column.id, sortingDirections.ASC)
					break
			}
		} else {
			this.handleChangeSorting(column.id, sortingDirections.ASC)
		}
	}

	handleChangeSorting = (accessor, direction) => {
		const { location, history } = this.props

		this.setState({ sort: { accessor: accessor, direction: direction } })

		let searchParams = new URLSearchParams(location.search)

		if (!isNull(accessor) && !isNull(direction)) {
			searchParams.set('orderBy', accessor)
			searchParams.set('order', direction)
		} else {
			searchParams.delete('orderBy')
			searchParams.delete('order')
		}

		history.push({
			pathname: location.pathname,
			search: searchParams.toString()
		})
	}

	render() {
		const { datatableData, sort } = this.state
		const {
			isLoadingFolders,
		} = this.props

		return (
			<>
				{!isLoadingFolders ? (
					<div className='brand-resource-details-container'>
						<div className='brand-resource-page-header-container'>
							<PageHeader
								pageTitle={'Folder personal'}
								disableShadow
							/>
							{isLoadingFolders && <Loader />}
						</div>
						{!isEmpty(datatableData) ? (
							<>
								<div className='brand-resource-details-content'>
									<Datatable
										data={!isLoadingFolders ? datatableData : []}
										sortable
										onHeaderClick={(col) => this.columnHeaderClick(col)}
										columns={[
											{
												Header: 'Nume',
												accessor: 'name',
												sortDirection: sort.accessor === 'name' ? sort.direction : null,
												Cell: ({ value: name, row: { original: folder } }) =>
													(
														<Link
															to={`/brands/${folder.brand_id}/resource-categories/${folder.category_id}/${folder.id}`}
															className='name-icon-cell-container'
														>
															<div className='icon-container'>
																<FolderIcon className='icon' />
															</div>
															<p className='name'>{name}</p>
														</Link>
													)
											},
											{
												Header: 'Tip',
												accessor: 'extension',
												disableSortBy: true,
												Cell: ({ value: extension }) =>
													extension ? (
														<div className='file-type-badge-cell-container'>
															<FileTypeBadge fileType={toUpper(extension)} />
														</div>
													) : (
														''
													)
											},
											{
												Header: 'Dimensiune',
												accessor: 'size',
												sortDirection: sort.accessor === 'size' ? sort.direction : null,
												Cell: ({ value: size }) =>
													typeof size == 'number' ? formatBytes(size) : size
											},
											{
												Header: '',
												accessor: 'id',
												disableSortBy: true,
												Cell: ({ row: { original: resource }, value: id }) => (
													<div className='brand-resources-actions-column-container'>
														{resource.type !== resourceTypes.FOLDER && (
															<>
																<a
																	href={resource.preview_url}
																	target='_blank'
																	rel='noreferrer'
																>
																	<Button title='Vizualizează' color='white' />
																</a>
																<a
																	href={resource.download_url}
																	target='_blank'
																	rel='noreferrer'
																>
																	<Button
																		title='Descarcă'
																		icon={() => <ArrowDownTrayIcon />}
																		color='secondary'
																	/>
																</a>
															</>
														)}
													</div>
												)
											}
										]}
									/>
								</div>
							</>
						) : null}
					</div>
				) : (
					<PageLoader />
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	folders: values(state.folders.data),
	foldersStatus: state.folders.status,
	foldersMessage: state.folders.message,
	isLoadingFolders: state.folders.isLoading
})

const mapDispatchToProps = (dispatch) => ({	
	listPersonalFolders: (params) => dispatch(listPersonalFolders(params)),
	resetResponses: () => dispatch(resetResponses())
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalFolder)
