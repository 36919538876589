import React, { Component } from 'react'
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { isEmpty, isNull, values, debounce, toUpper, orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { closeModal, modalTypes, openModal } from '../../redux/modals'

import { shopOrderingOptions } from './constants'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Loader } from '../../components/Loader'
import { PageHeader } from '../../components/PageHeader'
import { ProductDetailsModal } from '../../components/ProductDetailsModal'
import { ProductImagesModal } from '../../components/ProductImagesModal'
import { LoadMoreComponent } from '../../components/LoadMoreComponent'

import { ProductCard } from './partials'

import { Button } from '../../components/Button'

import './Shop.scss'

export class Shop extends Component {
	constructor() {
		super()

		const query = new URLSearchParams(window.location.search)

		const brand = query.has('brand') ? parseInt(query.get('brand')) : null
		const productCategory = query.has('category') ? parseInt(query.get('category')) : null
		const order = query.has('order') ? shopOrderingOptions[toUpper(query.get('order'))] : shopOrderingOptions.NAME_ASC
		const search = query.has('search') ? query.get('search') : ''

		this.state = {
			order,
			brand,
			productCategory,
			productQuery: search,
			selectedProduct: null,
			showFilters: false
		}
	}

	componentDidMount = () => {
		const { listBrands, location } = this.props
		let searchParams = new URLSearchParams(location.search)
		this.handleListProducts(searchParams.get("search"), 1, searchParams.get("brand"), searchParams.get("category"), searchParams.get("order"))
		listBrands()
	}

	componentDidUpdate = (_prevProps, prevState) => {
		const { brand, productCategory, order } = this.state
		const { brands, productCategories, isLoadingBrands, isLoadingProductCategories, listProductCategories } =
			this.props
		if (!isNull(brand) && brand !== prevState.brand && !isLoadingProductCategories) {
			listProductCategories(brand.id)
		}
		
		if (
			(brand !== prevState.brand && !isLoadingBrands && typeof prevState.brand != 'number') ||
			(productCategory !== prevState.productCategory && !isLoadingProductCategories && typeof prevState.productCategory != 'number') ||
			order !== prevState.order
		) {
			this.handleListProducts()
		}

		if (!isEmpty(brands) && typeof brand == 'number') {
			this.setState({
				brand: brands.find((b) => parseInt(b.id) === parseInt(brand))
			})
		}

		if (!isEmpty(productCategories) && typeof productCategory == 'number') {
			this.setState({
				productCategory: productCategories.find((c) => parseInt(c.id) === parseInt(productCategory))
			})
		}
	}

	debounceSearch = debounce((query) => this.handleListProducts(query), 300)

	handleChangeSearch = (query) => {
		const { location, history } = this.props
		let searchParams = new URLSearchParams(location.search)
		this.setState({ productQuery: query })
		this.debounceSearch(query)

		if (query) {
			searchParams.set('search', query)
		} else {
			searchParams.delete('search')
		}

		history.push({
			pathname: location.pathname,
			search: searchParams.toString()
		})
	}

	handleChangeSelect = (brand, productCategory, order) => {
		const { location, history } = this.props
		this.setState({ brand, productCategory, order })

		let searchParams = new URLSearchParams(location.search)

		if (brand) {
			searchParams.set('brand', brand.id)
		} else {
			searchParams.delete('brand')
		}

		if (productCategory) {
			searchParams.set('category', productCategory.id)
		} else {
			searchParams.delete('category')
		}

		if (order) {
			searchParams.set('order', order.id)
		} else {
			searchParams.delete('order')
		}

		history.push({
			pathname: location.pathname,
			search: searchParams.toString()
		})
	}

	handleListProducts = (
		search = this.state.productQuery,
		page = 1,
		brand = this.state.brand?.id,
		productCategory = this.state.productCategory?.id,
		order = this.state.order?.id
	) => {
		const { listProducts, isLoading } = this.props

		let params = {}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (page >= 1) {
			params = { ...params, page }
		}

		if (!isNull(brand)) {
			params = { ...params, brand_id: brand }
		}

		if (!isNull(productCategory)) {
			params = { ...params, category_id: productCategory }
		}

		if (!isNull(order)) {
			params = { ...params, order: order }
		}

		if (!isLoading) {
			listProducts(params)
		}
	}

	getPageTitle = () => {
		const { brand, productCategory } = this.state
		return (
			'Webshop' +
			(brand?.name ? ` ${brand.name}` + (productCategory?.name ? ` / ${productCategory.name}` : '') : '')
		)
	}

	render() {
		const { productQuery, selectedProduct, showFilters, brand, productCategory, order } = this.state
		const {
			products,
			isLoading,
			pages,
			brands,
			isLoadingBrands,
			productCategories,
			isLoadingProductCategories,
			openProductDetailsModal,
			openProductImagesModal,
			closeModal
		} = this.props

		return (
			<div className='shop-page-container'>
				<div className='shop-page-header-container'>
					<PageHeader
						pageTitle={this.getPageTitle()}
						parentRoute={brand ? `/brands/${brand.id}/product-categories` : '/home'}
						disableShadow
					/>
					{isLoading && <Loader />}
				</div>
				<div className='filters-section'>
					<div className='filters-mobile-toggler'>
						<Button
							title={`${showFilters ? 'Închide' : 'Deschide'} filtre`}
							onClick={() => this.setState((prevState) => ({ showFilters: !prevState.showFilters }))}
							icon={() => <FunnelIcon />}
							variant='outlined'
							size='large'
							fullWidth
						/>
					</div>
					<div className={`filters-container ${showFilters ? 'open' : ''}`}>
						<Input
							value={productQuery}
							placeholder='Caută după nume'
							onChange={(e) => this.handleChangeSearch(e.target.value)}
							icon={() => <MagnifyingGlassIcon />}
							fullWidth
							size='large'
						/>
						<Select
							label='Ordonare'
							value={order}
							options={values(shopOrderingOptions)}
							getOptionValue={(option) => option.id}
							onChange={(option) => this.handleChangeSelect(brand, productCategory, option)}
							labelInside
							fullWidth
						/>
						<Select
							label='Brand'
							value={brand}
							options={brands}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							onChange={(option) => this.handleChangeSelect(option, null, order)}
							disabled={isLoadingBrands}
							labelInside
							isClearable
							fullWidth
						/>
						<Select
							label='Categorie'
							value={productCategory}
							options={productCategories}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							onChange={(option) => this.handleChangeSelect(brand, option, order)}
							disabled={isNull(brand) || isLoadingProductCategories}
							labelInside
							isClearable
							fullWidth
						/>
					</div>
				</div>
				<div className='products-container'>
					{orderBy(products, 'order').map((product) => (
						<ProductCard
							key={product.id}
							product={product}
							onViewDetails={() => {
								this.setState({ selectedProduct: product })
								openProductDetailsModal()
							}}
							onViewImages={() => {
								this.setState({ selectedProduct: product })
								console.log(product.images)
								openProductImagesModal()
							}}
						/>
					))}
					{isLoading && (
						<div className='loading-container'>
							<Loader />
						</div>
					)}
					{(!isLoading && !isNull(pages.next)) && (
						<LoadMoreComponent
							loadingMoreText='Se încarcă mai multe produse...'
							isLoading={isLoading}
							onLoadMore={() => this.handleListProducts(productQuery, pages.next)}
						/>
					)}
				</div>
				<ProductDetailsModal
					productID={selectedProduct?.id || null}
					onClose={() => {
						closeModal()
						this.setState({ selectedProduct: null })
					}}
				/>
				<ProductImagesModal
					productID={selectedProduct?.id || null}
					onClose={() => {
						closeModal()
						this.setState({ selectedProduct: null })
					}}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		products: orderBy(state.products.data, 'order'),
		isLoading: state.products.isLoading,
		pages: state.products.pages,
		brands: values(state.brands.data),
		isLoadingBrands: state.brands.isLoading,
		productCategories: values(state.productCategories.data),
		isLoadingProductCategories: state.productCategories.isLoading
	}
}

const mapDispatchToProps = (dispatch) => ({
	listProducts: (params) => dispatch(RESOURCES.products.list(params)),
	listProductCategories: (brandID) => dispatch(RESOURCES.productCategories.list({ brand_id: brandID })),
	listBrands: () => dispatch(RESOURCES.brands.list()),
	openProductDetailsModal: () => dispatch(openModal(modalTypes.PRODUCT_DETAILS)),
	openProductImagesModal: () => dispatch(openModal(modalTypes.PRODUCT_IMAGES)),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Shop)
