import React, { useCallback, useState, useRef } from 'react'
import { CloudArrowUpIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { isEmpty, head } from 'lodash'

import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import { Button } from '../Button'

import './SingleImageDropzone.scss'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspectRatio) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100
			},
			aspectRatio,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	)
}

export default function Dropzone({ label, name, onChange, onCrop, initialImageUrl, aspectRatio }) {
	const [files, setFiles] = useState([])
	const [noDrag, setNoDrag] = useState(false)

	const [imgSrc, setImgSrc] = useState('')
	const previewCanvasRef = useRef()
	const imgRef = useRef()
	const [crop, setCrop] = useState()
	const [completedCrop, setCompletedCrop] = useState()

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1)
			}
		},
		100,
		[completedCrop]
	)

	const handleDrop = (acceptedFiles) => {
		setNoDrag(!isEmpty(acceptedFiles))
		onChange(!isEmpty(acceptedFiles) ? acceptedFiles[0] : null)

		setFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			)
		)

		if (!isEmpty(acceptedFiles)) {
			setCrop(undefined) // Makes crop preview update between images.
			const reader = new FileReader()
			reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''))
			reader.readAsDataURL(acceptedFiles[0])
		}
	}

	function handleCropComplete(crop) {
		setCompletedCrop(crop)

		onCrop({
			cropUnit: crop.unit,
			cropX: crop.x,
			cropY: crop.y,
			cropWidth: crop.width,
			cropHeight: crop.height,
			naturalWidth: imgRef.current.naturalWidth,
			naturalHeight: imgRef.current.naturalHeight,
			imgWidth: imgRef.current.width,
			imgHeight: imgRef.current.height
		})
	}

	function onImageLoad(e) {
		const { width, height } = e.currentTarget
		if (!aspectRatio) {
			aspectRatio = width / height;
		}
		setCrop(centerAspectCrop(width, height, aspectRatio))
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: onDrop,
		noClick: true,
		noKeyboard: true,
		noDrag
	})

	return (
		<div {...getRootProps({ className: 'edy-optic-add-brand-dropzone' })}>
			<input {...getInputProps()} />
			{!isEmpty(files) ? (
				<>
					<p className='add-brand-dropzone-label-top'>{label}</p>
					<div className='edy-dropzone-image-preview-container'>
						<div className='left-preview'>
							<div className='edy-optic-image-preview-top'>
								{Boolean(completedCrop) && (
									<canvas
										ref={previewCanvasRef}
										id={name + '-canvas'}
										className='preview-image'
										style={{
											objectFit: 'contain',
											width: completedCrop.width,
											height: completedCrop.height
										}}
									/>
								)}
							</div>
							<div className='edy-dropzone-image-preview-footer'>
								<div className='left'>
									<p className='preview-label'>{label}</p>
									<p className='image-file-name'>{head(files).name}</p>
								</div>
								<Button
									icon={() => <TrashIcon />}
									color='red'
									size='small'
									onClick={() => onDrop([])}
								/>
							</div>
						</div>
						<div className='right-crop'>
							<div className='edy-dropzone-image-crop'>
								{Boolean(imgSrc) && (
									<ReactCrop
										crop={crop}
										onChange={(_, percentCrop) => setCrop(percentCrop)}
										onComplete={(c) => handleCropComplete(c)}
										aspect={aspectRatio}
									>
										<img
											ref={imgRef}
											id={name + '-img'}
											alt='Crop me'
											src={imgSrc}
											onLoad={onImageLoad}
										/>
									</ReactCrop>
								)}
							</div>
						</div>
					</div>
				</>
			) : initialImageUrl ? (
				<div className='edy-dropzone-image-preview-initial'>
					<div className='edy-optic-image-preview-top'>
						<img src={initialImageUrl} alt='' className='preview-image' />
					</div>
					<Button
						title='Schimbă imaginea'
						onClick={open}
						icon={() => <PencilSquareIcon />}
						iconLeft
						type='button'
					/>
				</div>
			) : (
				<>
					<CloudArrowUpIcon className='upload-icon' />
					<p className='add-brand-dropzone-label'>{label}</p>
					<Button title='Adaugă fișier' onClick={open} icon={() => <PlusIcon />} iconLeft type='button' />
					<p className='secondary-text'>sau mută fișierele aici pentru a încărca.</p>
				</>
			)}
		</div>
	)
}
