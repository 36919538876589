import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

import { values, head, uniq, without, includes } from 'lodash'

import { connect } from 'react-redux'

import { Tabs } from '../Tabs'
import { Toggle } from '../Toggle'
import { Checkbox } from '../Checkbox'

import './SendEmailPanel.scss'

const tabTypes = {
	ALL_CATEGORIES: 'ALL_CATEGORIES',
	SPECIFIC_CATEGORY: 'SPECIFIC_CATEGORY'
}

const tabs = [
	{ title: 'Toate categoriile', type: tabTypes.ALL_CATEGORIES },
	{ title: 'Categorii specifice', type: tabTypes.SPECIFIC_CATEGORY }
]

export const SendEmailPanel = ({
	isOpen,
	openConent,
	notifications,
	changeNotifications,
	userCategories,
	onChangeUserCategories,
	initialSelectedCategories
}) => {
	const [selectedTab, setSelectedTab] = useState(head(tabs))
	const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories)

	useEffect(() => {
		setSelectedCategories(initialSelectedCategories)
	}, [initialSelectedCategories])

	useEffect(() => {
		onChangeUserCategories(selectedCategories)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategories])

	const onChangeCheckbox = (checkboxValue, userCategory) => {
		if (checkboxValue) {
			handleSelectCategory(userCategory)
		} else {
			handleRemoveCategory(userCategory)
		}
	}

	const handleSelectCategory = (userCategory) => setSelectedCategories(uniq([...selectedCategories, userCategory.id]))

	const handleRemoveCategory = (userCategory) => setSelectedCategories(without(selectedCategories, userCategory.id))

	const handleChangeTab = (tab) => {
		setSelectedTab(tab)

		let newSelectedCategories

		if (tab.type === tabTypes.ALL_CATEGORIES) {
			newSelectedCategories = userCategories.map((category) => category.id)
		} else {
			newSelectedCategories = []
		}

		setSelectedCategories(newSelectedCategories)
	}

	return (
		<div className='panel'>
			<div className='panel-header'>
				<div className='panel-toggler' onClick={openConent}>
					{isOpen ? (
						<ChevronUpIcon className='panel-toggler-icon' />
					) : (
						<ChevronDownIcon className='panel-toggler-icon' />
					)}
				</div>
				<div className='panel-header-right'>
					<p className='panel-title'>Trimite Email</p>
					<Toggle checked={notifications} onChange={(e) => changeNotifications(e)} />
				</div>
			</div>
			{isOpen && (
				<div className='panel-content send-email-panel-content'>
					{notifications ? (
						<>
							<Tabs
								label='Trimite la'
								tabs={tabs}
								onChangeTab={(tab) => handleChangeTab(tab)}
								initialSelectedTab={selectedTab}
							/>
							<div className='selected-user-categories-container'>
								<p className='selected-user-categories-label'>Categorii Utilizatori</p>
								<div className='user-categories-list-container'>
									{userCategories.map((userCategory) => {
										const isChecked = includes(selectedCategories, userCategory.id)

										return (
											<Checkbox
												key={userCategory.id}
												checked={isChecked}
												label={userCategory.name}
												onChange={(e) => onChangeCheckbox(e.target.checked, userCategory)}
												disabled={selectedTab.type === tabTypes.ALL_CATEGORIES}
											/>
										)
									})}
								</div>
							</div>
						</>
					) : (
						<p className='no-email'>Noutatea nu va fi trimisă pe email.</p>
					)}
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({
	userCategories: values(state.userCategories.data)
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailPanel)
