import React from 'react'
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from '@heroicons/react/24/solid'
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline'

import { isNull, isEmpty, without } from 'lodash'

import { connect } from 'react-redux'
import { modalTypes, openModal } from '../../redux/modals'

import { Button } from '../Button'
import { ConnectResourcesModal } from '../ConnectResourcesModal'

import './ResourcesPanel.scss'

function ResourcesPanel({
	isOpen,
	openConent,
	brandID,
	connectedResources,
	openConnectResourcesModal,
	onConnectResources,
	isSinglePanel,
	resourceQuery,
	onSearchResources
}) {
	return (
		<div className='panel'>
			<div className='panel-header'>
				{!isSinglePanel && (
					<div className='panel-toggler' onClick={openConent}>
						{isOpen ? (
							<ChevronUpIcon className='panel-toggler-icon' />
						) : (
							<ChevronDownIcon className='panel-toggler-icon' />
						)}
					</div>
				)}
				<div className='panel-header-right'>
					<div className='panel-title-container'>
						<p className='panel-title'>Resurse conectate</p>
						<p className='connected-bubble'>{connectedResources.length}</p>
					</div>
					<div className='button-container'>
						<Button
							icon={() => <PlusIcon />}
							disabled={isNull(brandID)}
							onClick={openConnectResourcesModal}
							fullWidth
							size='small'
						/>
						<ConnectResourcesModal
							brandID={brandID}
							onConnectResources={onConnectResources}
							initialSelectedResources={connectedResources}
							resourceQuery={resourceQuery}
							onSearchResources={onSearchResources}
						/>
					</div>
				</div>
			</div>
			{(isOpen || isSinglePanel) && (
				<>
					<div className='panel-content'>
						{!isEmpty(connectedResources) && brandID ? (
							<div className='connected-resources-container'>
								{connectedResources.map((resource) => (
									<div className='resource-card-container' key={resource.id}>
										<div className='texts-container'>
											<div className='resource-name-container'>
												<div className='icon-container'>
													<DocumentIcon className='document-icon' />
												</div>
												<p className='resouce-name'>{resource?.name}</p>
											</div>
											<div className='secondary-texts'>
												<p>{resource?.folder?.path}</p>
											</div>
										</div>
										<Button
											icon={() => <TrashIcon />}
											onClick={() => onConnectResources(without(connectedResources, resource))}
											color='red'
											size='small'
										/>
									</div>
								))}
							</div>
						) : isNull(brandID) ? (
							<p className='missing-text'>Vă rugăm să selectați un brand</p>
						) : isEmpty(connectedResources) ? (
							<p className='missing-text'>Fară resurse conectate</p>
						) : null}
					</div>
					<div className='panel-footer'>
						<Button
							title='Șterge toate'
							onClick={() => onConnectResources([])}
							icon={() => <TrashIcon />}
							color='red'
							iconLeft
							fullWidth
						/>
					</div>
				</>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	openConnectResourcesModal: () => dispatch(openModal(modalTypes.CONNECT_RESOURCES))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesPanel)
