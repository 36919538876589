/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { CloudArrowUpIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import { Button } from '../Button'

import './ImageDropzone.scss'

export default function ImageDropzone({ label, onChangeFiles, initialImageUrl }) {
	const [files, setFiles] = useState([])
	const [previewUrl, setPreviewUrl] = useState(initialImageUrl)

	useEffect(() => onChangeFiles(files), [files])

	const handleDrop = (acceptedFiles) => {
		setFiles(acceptedFiles)
		setPreviewUrl(URL.createObjectURL(acceptedFiles[0]))
	}

	const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

	const { getRootProps, getInputProps, open } = useDropzone({
		maxFiles: 1,
		multiple: false,
		onDrop: onDrop,
		noClick: true,
		noKeyboard: true
	})

	return (
		<div {...getRootProps({ className: 'edy-optic-multi-image-dropzone' })}>
			<input {...getInputProps()} />
			{previewUrl ? (
				<div className='edy-dropzone-image-preview'>
					<div className='edy-optic-image-preview-top'>
						<img src={previewUrl} alt='' className='preview-image max-h-80' />
					</div>
					<Button
						title='Schimbă imaginea'
						onClick={open}
						icon={() => <PencilSquareIcon />}
						iconLeft
						type='button'
					/>
				</div>
			) : (
				<div className='edy-optic-multi-image-dropzone-upload-container'>
					<CloudArrowUpIcon className='upload-icon' />
					<p className='multi-image-dropzone-label'>{label}</p>
					<Button title='Adaugă imagine' onClick={open} icon={() => <PlusIcon />} iconLeft type='button' />
					<p className='secondary-text'>sau mută imaginile aici pentru a încărca.</p>
				</div>
			)}
		</div>
	)
}
