import React, { Component } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { isEmpty, isUndefined, find, without, orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { closeModal, modalTypes } from '../../../../redux/modals'

import { Input } from '../../../Input'
import { Modal } from '../../../Modal'
import { Loader } from '../../../Loader'
import { Button } from '../../../Button'

import './ConnectProductsModal.scss'

export class ConnectProductsModal extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedProducts: props.initialSelectedProducts
		}
	}

	componentDidUpdate = (prevProps) => {
		const { selectedProducts } = this.state
		const { initialSelectedProducts } = this.props

		if (
			initialSelectedProducts !== prevProps.initialSelectedProducts &&
			initialSelectedProducts !== selectedProducts
		) {
			this.setState({ selectedProducts: initialSelectedProducts })
		}
	}

	handleSelectProduct = (product) =>
		this.setState((prevState) =>
			this.isSelected(product, prevState.selectedProducts)
				? {
						selectedProducts: without(prevState.selectedProducts, product)
				  }
				: {
						selectedProducts: [...prevState.selectedProducts, product]
				  }
		)

	handleConnectProducts = () => {
		const { selectedProducts } = this.state
		const { closeModal, onConnectProducts } = this.props

		onConnectProducts(selectedProducts)
		closeModal()
	}

	isSelected = (product, selectedProducts = this.state.selectedProducts) =>
		!isUndefined(find(selectedProducts, ['id', product.id]))

	render() {
		const { selectedProducts } = this.state
		const { open, products, isLoading, productQuery, onSearchProducts } = this.props

		return (
			<Modal open={open} title='Selectează produse'>
				<div className='choose-products-modal-container'>
					{!isUndefined(productQuery) && !isUndefined(onSearchProducts) ? (
						<div className='search-container'>
							<Input
								value={productQuery}
								placeholder='Caută după nume'
								onChange={(e) => onSearchProducts(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
							/>
						</div>
					) : null}
					{!isEmpty(products) && !isLoading ? (
						<>
							<div className='products-list'>
								{products.map((product) => (
									<ProductCard
										product={product}
										isSelected={this.isSelected(product)}
										handleSelectProduct={this.handleSelectProduct}
										key={product.id}
									/>
								))}
							</div>
							<Button
								title='Conectează resursele selectate'
								onClick={this.handleConnectProducts}
								disabled={isEmpty(selectedProducts)}
								size='large'
								fullWidth
							/>
						</>
					) : isLoading ? (
						<div className='loader-container'>
							<Loader />
						</div>
					) : (<div className='products-list'></div>)}
				</div>
			</Modal>
		)
	}
}

function ProductCard({ product, isSelected, handleSelectProduct }) {
	const onSelectProduct = () => handleSelectProduct(product)

	return (
		<div className={`product-card-container ${isSelected ? 'selected' : ''}`} onClick={onSelectProduct}>
			<div className='product-info-container'>
				<img src={product.image_url} alt={product.name} className='product-image' />
				<div className='product-texts-container'>
					<p className='product-name'>{product.name}</p>
					<p className='product-category'>{product.category.name}</p>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.CONNECT_PRODUCTS,
	products: orderBy(state.products.data, 'order'),
	isLoading: state.products.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listProducts: (brandID) => dispatch(RESOURCES.products.list({ brand_id: brandID })),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectProductsModal)
