import React from 'react'
import { Form, Formik } from 'formik'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { objectKeysToSnakeCase } from '../../../../utils'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

import { validations } from '../../../../assets/validations'

import './EditUserCategoryModal.scss'

export const EditUserCategoryModal = ({ open, userCategory, userCategoriesErrors, isLoading, updateUserCategory }) => {
	return (
		<Modal open={open && !isEmpty(userCategory)} title='Editează categoria de utilizator'>
			<Formik
				initialValues={{
					name: userCategory.name,
					discount: userCategory.discount,
					daysBeforeAccess: userCategory.days_before_access
				}}
				enableReinitialize
				validationSchema={validations.userCategories}
				onSubmit={(values) => updateUserCategory({ ...objectKeysToSnakeCase(values) }, userCategory.id)}
			>
				{({ handleChange, handleBlur, values, handleSubmit, errors, touched }) => (
					<Form className='edit-user-category-modal-form-container'>
						<Input
							label='Nume'
							value={values.name}
							placeholder='Numele categoriei'
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
							size='large'
							name='name'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.name}
							fullWidth
						/>
						<Input
							label='Zile prioritare acces'
							value={values.daysBeforeAccess}
							onChange={handleChange('daysBeforeAccess')}
							onBlur={handleBlur('daysBeforeAccess')}
							size='large'
							name='daysBeforeAccess'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.daysBeforeAccess}
							fullWidth
						/>
						<Input
							label='Discount'
							value={values.discount}
							onChange={handleChange('discount')}
							placeholder='Discountul categoriei'
							onBlur={handleBlur('discount')}
							size='large'
							name='discount'
							errors={userCategoriesErrors}
							frontendErrors={errors}
							touched={touched.discount}
							fullWidth
						/>
						<Button
							title='Salvează categoria de utilizator'
							onClick={handleSubmit}
							loading={isLoading}
							type='submit'
							size='large'
							fullWidth
						/>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.EDIT_USER_CATEGORY,
	userCategoriesErrors: state.userCategories.errors,
	isLoading: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	updateUserCategory: (values, userID) => dispatch(RESOURCES.userCategories.update(values, userID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserCategoryModal)
