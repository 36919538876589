import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { UserIcon, ArrowLeftOnRectangleIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { connect } from 'react-redux'
import { logout } from '../../../../redux/auth'
import { resetCart } from '../../../../redux/cart'

import './UserComponent.scss'
import { Link } from 'react-router-dom'

export function UserComponent({ user, logout, resetCart }) {
	return (
		<Menu as='div' className='user-actions-container'>
			<div>
				<Menu.Button className='user-actions-button'>
					<div className='user-component-container'>
						<div className='user-icon-container'>
							<UserIcon className='user-icon' />
						</div>
						<div className='user-texts-container'>
							<div className='user-name-container'>
								<p className='user-name'>
									{user.first_name} {user.last_name}
								</p>
								<ChevronDownIcon className='chevron-down-icon' />
							</div>
							{user?.category?.name && <p className='user-role'>{user.category.name}</p>}
						</div>
					</div>
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter='transition ease-out duration-100'
				enterFrom='transform opacity-0 scale-95'
				enterTo='transform opacity-100 scale-100'
				leave='transition ease-in duration-75'
				leaveFrom='transform opacity-100 scale-100'
				leaveTo='transform opacity-0 scale-95'
			>
				<Menu.Items className='user-action-items-container'>
					<div className='user-texts-container'>
						<div className='user-name-container'>
							<p className='user-name'>
								{user.first_name} {user.last_name}
							</p>
						</div>
						{user?.category?.name && <p className='user-role'>{user.category.name}</p>}
					</div>
					<div className='p-1'>
						<Menu.Item>
							{({ active }) => (
								<Link className={`user-action-item group ${active ? 'active' : ''}`} to='/orders'>
									<ShoppingCartIcon className={`user-action-item-icon ${active ? 'active' : ''}`} />
									Comenzile mele
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									className={`user-action-item group ${active ? 'active' : ''}`}
									onClick={() => {
										resetCart()
										logout()
									}}
								>
									<ArrowLeftOnRectangleIcon
										className={`user-action-item-icon ${active ? 'active' : ''}`}
									/>
									Logout
								</button>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

const mapStateToProps = (state) => ({
	user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	resetCart: () => dispatch(resetCart())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent)
