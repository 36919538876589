import { toUpper, kebabCase, replace, trimEnd } from 'lodash'
import URITemplate from 'urijs/src/URITemplate'

export const SUCCESS_SUFFIX = '_SUCCESS'
export const ERROR_SUFFIX = '_FAIL'

export class Action {
	constructor(name) {
		this.main = name
	}
}

export class APIAction extends Action {
	constructor(name) {
		super(name)
		this.success = name + SUCCESS_SUFFIX
		this.fail = name + ERROR_SUFFIX
	}
}

export class Resource {
	constructor(name, endpoint = '/{resource}/{id}') {
		this.name = name
		this.endpoint = URITemplate(endpoint)

		this.actions = {
			list: new APIAction(`LIST_${toUpper(this.name)}`),
			create: new APIAction(`CREATE_${toUpper(this.name)}`),
			retrieve: new APIAction(`RETRIEVE_${toUpper(this.name)}`),
			update: new APIAction(`UPDATE_${toUpper(this.name)}`),
			destroy: new APIAction(`DESTROY_${toUpper(this.name)}`)
		}
	}

	list(params = {}) {
		const url = this.endpoint.expand({ resource: kebabCase(this.name) })

		return {
			type: this.actions.list.main,
			payload: {
				request: {
					url: this._cleanURL(url),
					method: 'GET',
					params: params
				}
			}
		}
	}

	retrieve(id) {
		const url = this.endpoint.expand({ resource: kebabCase(this.name), id: id })

		return {
			type: this.actions.retrieve.main,
			payload: {
				request: {
					url: this._cleanURL(url),
					method: 'GET'
				}
			}
		}
	}

	create(data, headers = {}) {
		const url = this.endpoint.expand({ resource: kebabCase(this.name) })

		return {
			type: this.actions.create.main,
			payload: {
				request: {
					url: this._cleanURL(url),
					method: 'POST',
					data,
					headers
				},
			},
			meta: {
				object: data
			}
		}
	}

	update(data, id = null, headers = {}, callback = null) {
		const url = this.endpoint.expand({ resource: kebabCase(this.name), id: id ? id : data.id })

		return {
			type: this.actions.update.main,
			payload: {
				request: {
					url: this._cleanURL(url),
					method: 'POST',
					data,
					headers
				}
			},
			meta: {
				object: data,
				callback
			},
		}
	}

	destroy(data = {}, id = null) {
		const url = this.endpoint.expand({ resource: kebabCase(this.name), id: id ? id : data.id })

		return {
			type: this.actions.destroy.main,
			payload: {
				request: {
					url: this._cleanURL(url),
					method: 'DELETE'
				}
			},
			meta: {
				object: data
			}
		}
	}

	_cleanURL(url) {
		return trimEnd(replace(url, '//', '/'), '/')
	}
}
