import React, { Component } from 'react'

import { values, isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { closeModal, modalTypes } from '../../redux/modals'
import { listFolders } from '../../redux/folders'

import { Modal } from '../Modal'
import { Loader } from '../Loader'
import { Button } from '../Button'

import './ConnectFolderModal.scss'
import { FoldersTree } from '../FoldersTree'

export class ConnectFolderModal extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedFolderID: props.initialSelectedFolder,
			selectedFolder: null
		}

		const { resourceCategoryID, listFolders } = props
		listFolders(resourceCategoryID, { only_children: true })
	}

	componentDidUpdate = (prevProps) => {
		const { selectedFolderID } = this.state
		const { initialSelectedFolder } = this.props

		if (
			initialSelectedFolder !== prevProps.initialSelectedFolder &&
			initialSelectedFolder !== selectedFolderID
		) {
			this.setState({ selectedFolderID: initialSelectedFolder })
		}		
	}


	handleConnectFolder = () => {
		const { selectedFolderID, selectedFolder } = this.state
		const { closeModal, onConnectFolder } = this.props

		onConnectFolder(selectedFolderID, selectedFolder)
		closeModal()
	}

	render() {
		const { selectedFolderID } = this.state
		const { open, folders, isLoading } = this.props

		return (
			<Modal open={open} title='Selectează folder'>
				<div className='choose-folder-modal-container'>
					{!isEmpty(folders) && !isLoading ? (
						<>
							<div className='folders-tree'>
								<FoldersTree
									folders={folders}
									isLoading={isLoading}
									onSelect={(folderID) => {
										const sameId = selectedFolderID === folderID
										
										this.setState({
											selectedFolderID: sameId ? null : folderID,
											selectedFolder: sameId ? null : folders.find(folder => folder.id === Number(folderID))
										})
									}}
									selectedKey={String(selectedFolderID)}
									disableAdding
								/>
							</div>
							<Button
								title='Conectează folderul selectat'
								onClick={this.handleConnectFolder}
								disabled={!selectedFolderID}
								size='large'
								fullWidth
							/>
						</>
					) : isLoading ? (
						<div className='loader-container'>
							<Loader />
						</div>
					) : (<div className='folders-tree'></div>)}
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.CONNECT_FOLDER,
	folders: values(state.folders.data),
	isLoading: state.folders.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listFolders: (resourceCategoryID, params) => dispatch(listFolders(resourceCategoryID, params)),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectFolderModal)
