import React from 'react'

import { isEmpty, camelCase, snakeCase, head } from 'lodash'

import { InputError } from '../ErrorComponents'

import './Textarea.scss'

export default function Textarea({
	label,
	placeholder,
	autoComplete,
	required,
	value,
	onChange,
	onBlur,
	onClick,
	size,
	fullWidth,
	icon,
	name,
	errors,
	frontendErrors,
	touched,
	disabled,
	className
}) {
	const snakeCaseName = snakeCase(name)
	const camelCaseName = camelCase(name)

	const hasErrors =
		(errors && !isEmpty(errors[snakeCaseName])) ||
		(frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

	const getErrorMessage = () =>
		errors && !isEmpty(errors[snakeCaseName]) ? head(errors[snakeCaseName]) : frontendErrors[camelCaseName]

	return (
		<div
			className={
				'edy-optic-textarea-label-container ' +
				`${disabled ? 'disabled' : ''} ` +
				`${fullWidth ? 'full-width' : ''} ` +
				`${className || ''}`
			}
		>
			{label && <label className='edy-optic-label'>{label}</label>}
			<div className={`edy-optic-textarea-wrapper ${size || 'medium'}`}>
				{icon && <div className='input-icon-wrapper'>{icon()}</div>}
				<textarea
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					onClick={onClick}
					className={
						'edy-optic-textarea ' +
						`${icon ? 'has-icon' : ''} ` +
						`${className || ''}`
					}
					placeholder={placeholder}
					autoComplete={autoComplete || 'off'}
					required={required || false}
					disabled={disabled}
				></textarea>
			</div>
			{hasErrors && <InputError message={getErrorMessage()} />}
		</div>
	)
}