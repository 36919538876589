import React, { Component } from 'react'
import { Form, Formik } from 'formik'

import { isEmpty, values as _values, find } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Input } from '../../../../components/Input'
import { Select } from '../../../../components/Select'
import { Button } from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'

import { objectKeysToSnakeCase } from '../../../../utils'
import { validations } from '../../../../assets/validations'

import './EditUserModal.scss'
import { PhoneInput } from '../../../../components/PhoneInput'

class EditUserModal extends Component {
	componentDidMount() {
		const { listUserCategories } = this.props

		listUserCategories()
	}

	render() {
		const { open, user, usersErrors, isLoading, userCategories, isLoadingUserCategories, updateUser } = this.props

		return (
			<Modal open={open && !isEmpty(user)} title='Editează utilizator'>
				{!isEmpty(user) && !isEmpty(userCategories) && !isLoadingUserCategories ? (
					<Formik
						initialValues={{
							lastName: user.last_name,
							firstName: user.first_name,
							email: user.email,
							phone: user.phone || '',
							category: user.category ? find(userCategories, ['name', user.category.name]) : null,
							optica: user.optica  || '',
							address: user.address || '',
							city: user.city || ''
						}}
						enableReinitialize
						validationSchema={validations.users}
						onSubmit={(values) => {
							updateUser(
								{
									...objectKeysToSnakeCase(values),
									category_id: values.category.id
								},
								user.id
							)
						}}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<Form className='edit-user-modal-form-container'>
								<div className='edit-user-modal-form-row'>
									<Input
										label='Nume'
										value={values.lastName}
										placeholder='Numele utilizatorului'
										onChange={handleChange('lastName')}
										onBlur={handleBlur('lastName')}
										size='large'
										name='lastName'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.lastName}
										fullWidth
									/>
									<Input
										label='Prenume'
										value={values.firstName}
										placeholder='Prenumele utilizatorului'
										onChange={handleChange('firstName')}
										onBlur={handleBlur('firstName')}
										size='large'
										name='firstName'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.firstName}
										fullWidth
									/>
								</div>
								<div className='edit-user-modal-form-row'>
									<Input
										label='Email'
										value={values.email}
										placeholder='Adresa de email a utilizatorului'
										onChange={handleChange('email')}
										onBlur={handleBlur('email')}
										size='large'
										name='email'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.email}
										fullWidth
									/>
									<PhoneInput
										label='Telefon'
										value={values.phone}
										onChange={(phoneNumber) => setFieldValue('phone', phoneNumber)}
										onBlur={handleBlur('phone')}
										name='phone'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.phone}
										fullWidth
									/>
								</div>
								<Select
									label='Categorie'
									value={values.category}
									options={userCategories}
									getOptionValue={(option) => option.id}
									getOptionLabel={(option) => option.name}
									placeholder='Alege categoria utilizatorului'
									onChange={(option) => setFieldValue('category', option)}
									onBlur={handleBlur('category')}
									name='category'
									errors={usersErrors}
									frontendErrors={errors}
									touched={touched.category}
									size='large'
									fullWidth
								/>
								<div className='edit-user-modal-form-row'>
									<Input
										label='Optica'
										value={values.optica}
										placeholder='Optica'
										onChange={handleChange('optica')}
										onBlur={handleBlur('optica')}
										size='large'
										name='optica'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.optica}
										fullWidth
									/>
									<Input
										label='Localitate'
										value={values.city}
										placeholder='Localitatea'
										onChange={handleChange('city')}
										onBlur={handleBlur('city')}
										size='large'
										name='city'
										errors={usersErrors}
										frontendErrors={errors}
										touched={touched.city}
										fullWidth
									/>
								</div>
								<Input
									label='Adresa'
									value={values.address}
									placeholder='Adresa'
									onChange={handleChange('address')}
									onBlur={handleBlur('address')}
									size='large'
									name='address'
									errors={usersErrors}
									frontendErrors={errors}
									touched={touched.address}
									fullWidth
								/>
								<Button
									title='Salvează utilizatorul'
									onClick={handleSubmit}
									loading={isLoading}
									type='submit'
									size='large'
									fullWidth
								/>
							</Form>
						)}
					</Formik>
				) : isLoadingUserCategories ? (
					<Loader />
				) : null}
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.EDIT_USER,
	usersErrors: state.users.errors,
	isLoading: state.users.isLoading,
	userCategories: _values(state.userCategories.data),
	isLoadingUserCategories: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	updateUser: (values, userID) => dispatch(RESOURCES.users.update(values, userID)),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)
