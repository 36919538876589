import React, { Component } from 'react'
import { Formik } from 'formik'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { values as _values, isNull, head, isEmpty, omit, debounce } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'
import { ArticleImageDropzone } from '../../components/ArticleImageDropzone'
import { ResourcesPanel } from '../../components/ResourcesPanel'
import { ProductsPanel } from '../../components/ProductsPanel'

import { panelTypes } from '../AddNews/constants'

import { objectKeysToSnakeCase } from '../../utils'
import { validations } from '../../assets/validations'

import './EditNews.scss'
import { Toggle } from '../../components/Toggle'

export class EditNews extends Component {
	constructor() {
		super()

		this.state = {
			openPanel: panelTypes.RESOURCES,
			resourceQuery: '',
			productQuery: '',
			selectedBrandID: null
		}
	}

	componentDidMount() {
		const {
			listBrands,
			listUserCategories,
			retrieveArticle,
			match: { params }
		} = this.props

		const { articleID, brandID } = params

		retrieveArticle(articleID)
		listUserCategories()
		listBrands()

		this.handleListResources(brandID)
		this.handleListProducts(brandID)
	}

	getSelectedUserCategoriesArray = (userCategories = this.props.userCategories) =>
		userCategories.map((category) => category.id)

	handleOpenPanel = (panel) =>
		this.setState((prevState) => ({
			openPanel: prevState.openPanel !== panel ? panel : null
		}))

	handleListResources = (brandID = this.state.selectedBrandID, search = this.state.resourceQuery) => {
		const { listResources, isLoading } = this.props

		let params = { brand_id: brandID }

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading) {
			listResources(params)
		}
	}

	debounceSearchResources = debounce((query) => this.handleListResources(this.state.selectedBrandID, query), 300)

	handleChangeResourcesSearch = (query) => {
		this.setState({ resourceQuery: query })
		this.debounceSearchResources(query)
	}

	handleListProducts = (brandID = this.state.selectedBrandID, search = this.state.resourceQuery) => {
		const { listProducts, isLoading } = this.props

		let params = { brand_id: brandID }

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading) {
			listProducts(params)
		}
	}

	debounceSearchProducts = debounce((query) => this.handleListProducts(this.state.selectedBrandID, query), 300)

	handleChangeProductsSearch = (query) => {
		this.setState({ productsQuery: query })
		this.debounceSearchProducts(query)
	}

	render() {
		const { openPanel, resourceQuery, productQuery } = this.state

		const {
			article,
			newsErrors,
			isLoading,
			brands,
			isLoadingBrands,
			userCategories,
			isLoadingUserCategories,
			isLoadingResources,
			listResources,
			updateArticle,
			match: { params }
		} = this.props

		const { articleID } = params

		return (
			<>
				{!isEmpty(article) &&
				!isEmpty(brands) &&
				!isLoadingBrands &&
				!isEmpty(userCategories) &&
				!isLoadingUserCategories ? (
					<Formik
						initialValues={{
							title: article.title,
							brand: article.brand,
							image: {},
							content: article.content,
							connectedResources: article.resources,
							connectedProducts: article.products,
							site_published: article.site_published || false
						}}
						enableReinitialize
						validationSchema={validations.news}
						onSubmit={(values) => {
							let newArticleData

							if (!isEmpty(values.image)) {
								newArticleData = new FormData()

								newArticleData.append('title', values.title)
								newArticleData.append('brand_id', values.brand.id)
								newArticleData.append('content', values.content)
								newArticleData.append('image', values.image, values.image.name)
								newArticleData.append('site_published', values.site_published)

								if (!isEmpty(values.connectedResources)) {
									values.connectedResources.forEach((resource) => {
										newArticleData.append('connected_resources[]', resource.id)
									})
								}

								if (!isEmpty(values.connectedProducts)) {
									values.connectedProducts.forEach((product) => {
										newArticleData.append('connected_products[]', product.id)
									})
								}
							} else {
								newArticleData = {
									...objectKeysToSnakeCase(omit(values, ['image', 'brand'])),
									brand_id: values.brand.id
								}

								if (!isEmpty(values.connectedResources)) {
									newArticleData['connected_resources'] = values.connectedResources.map(
										(resource) => resource.id
									)
								}

								if (!isEmpty(values.connectedProducts)) {
									newArticleData['connected_products'] = values.connectedProducts.map(
										(product) => product.id
									)
								}
							}

							updateArticle(newArticleData, articleID)
						}}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<div className='add-news-container'>
								<div className='add-news-left'>
									<PageHeader pageTitle={`Editează Noutate "${article.title}"`} />
									<div className='add-news-form-container'>
										<Input
											value={values.title}
											placeholder='Titlu noutate'
											onChange={handleChange('title')}
											onBlur={handleBlur('title')}
											size='large'
											name='title'
											errors={newsErrors}
											frontendErrors={errors}
											touched={touched.title}
											fullWidth
										/>
										<div className='add-news-form-row'>
											<Select
												placeholder='Alege brand-ul'
												size='extraLarge'
												value={values.brand}
												options={brands}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												onChange={(option) => {
													setFieldValue('brand', option)

													if (!isNull(option)) {
														listResources(option.id)
													}

													if (!isNull(option)) {
														this.handleListResources(option.id)
														this.handleListProducts(option.id)
														this.setState({ selectedBrandID: option.id })
													}
												}}
												onBlur={handleBlur('brand')}
												name='brand'
												errors={newsErrors}
												frontendErrors={errors}
												touched={touched.brand}
												isClearable
												fullWidth
											/>
											<ArticleImageDropzone
												value={values.image}
												onChangeFiles={(images) => setFieldValue('image', head(images))}
												initialImageUrl={article.image_url}
											/>
										</div>
										<div className='news-content-editor-container'>
											<CKEditor
												editor={Editor}
												className='ckeditor'
												data={values.content}
												onChange={(_event, editor) => {
													const data = editor.getData()

													setFieldValue('content', data)
												}}
											/>
										</div>
									</div>
								</div>
								<div className='add-news-right'>
									<div className='panels-container'>
										<ResourcesPanel
											brandID={values.brand?.id || null}
											isOpen={openPanel === panelTypes.RESOURCES}
											openConent={() => this.handleOpenPanel(panelTypes.RESOURCES)}
											onConnectResources={(resources) =>
												setFieldValue('connectedResources', resources)
											}
											connectedResources={values.connectedResources}
											resourceQuery={resourceQuery}
											onSearchResources={this.handleChangeResourcesSearch}
										/>
										<ProductsPanel
											brandID={values.brand?.id || null}
											isOpen={openPanel === panelTypes.PRODUCTS}
											openConent={() => this.handleOpenPanel(panelTypes.PRODUCTS)}
											onConnectProducts={(products) =>
												setFieldValue('connectedProducts', products)
											}
											connectedProducts={values.connectedProducts}
											productQuery={productQuery}
											onSearchProducts={this.handleChangeProductsSearch}
										/>
									</div>

									<div className='w-full flex flex-col gap-2'>
										<Toggle
											label='Afișare pe site'
											checked={values.site_published}
											onChange={(value) => {
												setFieldValue('site_published', value)
											}}
										/>
										
										<Button
											title='Salvează Modificări'
											onClick={handleSubmit}
											disabled={
												isNull(values.brand) || isEmpty(values.title) || isEmpty(values.content)
											}
											loading={isLoading}
											type='submit'
											size='large'
											fullWidth
										/>
									</div>
								</div>
							</div>
						)}
					</Formik>
				) : isLoadingUserCategories || isLoadingResources ? (
					<PageLoader />
				) : null}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	article: state.news.currentArticle,
	newsErrors: state.news.errors,
	isLoading: state.news.isLoading,
	brands: _values(state.brands.data),
	isLoadingBrands: state.brands.isLoading,
	userCategories: _values(state.userCategories.data),
	isLoadingUserCategories: state.userCategories.isLoading,
	resources: _values(state.resources.data),
	isLoadingResources: state.resources.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	retrieveArticle: (articleID) => dispatch(RESOURCES.news.retrieve(articleID)),
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	listResources: (brandID) => dispatch(RESOURCES.resources.list({ brand_id: brandID })),
	listProducts: (params) => dispatch(RESOURCES.products.list(params)),
	updateArticle: (articleData, articleID) => dispatch(RESOURCES.news.update(articleData, articleID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditNews)
