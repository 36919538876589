import React, { Component } from 'react'
import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { values, filter, toString, orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { columns } from './constants'

import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Button } from '../../components/Button'

import {
	AddResourceCategoryModal,
	DeleteResourceCategoryModal,
	EditResourceCategoryModal,
	ManageFoldersModal
} from './partials'

import './BrandResourceCategoriesList.scss'
import { PageLoader } from '../../components/PageLoader'

export class BrandResourceCategoriesList extends Component {
	constructor() {
		super()

		this.state = {
			selectedResourceCategory: {}
		}
	}

	componentDidMount() {
		const {
			retrieveBrand,
			listResourceCategories,
			match: {
				params: { brandID }
			}
		} = this.props

		retrieveBrand(brandID)
		listResourceCategories(brandID)
	}

	render() {
		const { selectedResourceCategory } = this.state
		const {
			brand,
			resourceCategories,
			isLoading,
			openModal,
			match: {
				params: { brandID }
			}
		} = this.props

		return (
			<div className='brand-resource-categories-page-container'>
				<div className='brand-resource-categories-page-header-container'>
					<PageHeader pageTitle={`Categorii Resurse ${brand?.name || ''}`} parentRoute='/brands' disableShadow />
					{!isLoading ? (
						<Button
							title='Adaugă Categorie'
							icon={() => <PlusIcon />}
							onClick={() => openModal(modalTypes.ADD_RESOURCE_CATEGORY)}
							className='add-button'
							size='large'
							iconLeft
						/>
					) : (
						<PageLoader />
					)}
				</div>
				<Datatable
					data={resourceCategories}
					columns={[
						...columns,
						{
							Header: '',
							accessor: 'id',
							Cell: ({ row }) => (
								<div className='brand-actions-column-container'>
									{!row.original.read_only && (
										<>
											<Button
												icon={() => <TrashIcon />}
												onClick={() => {
													openModal(modalTypes.DELETE_RESOURCE_CATEGORY)
													this.setState({ selectedResourceCategory: row.original })
												}}
												color='red'
											/>
											<Button
												title='Modifică categorie'
												icon={() => <PencilSquareIcon />}
												onClick={() => {
													openModal(modalTypes.EDIT_RESOURCE_CATEGORY)
													this.setState({ selectedResourceCategory: row.original })
												}}
												iconLeft
											/>
										</>
									)}
									<Button
										title='Administrează foldere'
										icon={() => <PencilSquareIcon />}
										onClick={() => {
											openModal(modalTypes.MANAGE_FOLDERS)
											this.setState({ selectedResourceCategory: row.original })
										}}
										iconLeft
										color='secondary'
									/>
								</div>
							)
						}
					]}
				/>
				<AddResourceCategoryModal brandID={brandID} />
				<DeleteResourceCategoryModal resourceCategory={selectedResourceCategory} />
				<EditResourceCategoryModal resourceCategory={selectedResourceCategory} />
				<ManageFoldersModal resourceCategory={selectedResourceCategory} />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const brand = state.brands.currentBrand
	const resourceCategories = orderBy(
		filter(values(state.resourceCategories.data), (res) => toString(res.brand_id) === toString(brand.id)),
		['read_only', 'order', 'id'],
		['desc', 'asc', 'asc']
	)

	return {
		brand: brand,
		resourceCategories: resourceCategories,
		isLoading: state.resourceCategories.isLoading
	}
}

const mapDispatchToProps = (dispatch) => ({
	retrieveBrand: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID)),
	listResourceCategories: (brandID) => dispatch(RESOURCES.resourceCategories.list({ brand_id: brandID })),
	openModal: (type) => dispatch(openModal(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandResourceCategoriesList)
