import { Action } from '.'

const ACTIONS = {
	OPEN_MODAL: new Action('OPEN_MODAL'),
	CLOSE_MODAL: new Action('CLOSE_MODAL')
}

export const modalTypes = {
	ADD_USER: 'ADD_USER',
	EDIT_USER: 'EDIT_USER',
	DELETE_USER: 'DELETE_USER',
	ADD_USER_CATEGORY: 'ADD_USER_CATEGORY',
	EDIT_USER_CATEGORY: 'EDIT_USER_CATEGORY',
	DELETE_USER_CATEGORY: 'DELETE_USER_CATEGORY',
	ADD_BRAND: 'ADD_BRAND',
	EDIT_BRAND: 'EDIT_BRAND',
	DELETE_BRAND: 'DELETE_BRAND',
	ADD_RESOURCE_CATEGORY: 'ADD_RESOURCE_CATEGORY',
	EDIT_RESOURCE_CATEGORY: 'EDIT_RESOURCE_CATEGORY',
	DELETE_RESOURCE_CATEGORY: 'DELETE_RESOURCE_CATEGORY',
	MANAGE_FOLDERS: 'MANAGE_FOLDERS',
	ADD_FOLDER: 'ADD_FOLDER',
	CONNECT_RESOURCES: 'CONNECT_RESOURCES',
	CONNECT_FOLDER: 'CONNECT_FOLDER',
	DELETE_ARTICLE: 'DELETE_ARTICLE',
	DELETE_PRODUCT: 'DELETE_PRODUCT',
	ADD_PRODUCT_CATEGORY: 'ADD_PRODUCT_CATEGORY',
	EDIT_PRODUCT_CATEGORY: 'EDIT_PRODUCT_CATEGORY',
	DELETE_PRODUCT_CATEGORY: 'DELETE_PRODUCT_CATEGORY',
	PRODUCT_DETAILS: 'PRODUCT_DETAILS',
	PRODUCT_IMAGES: 'PRODUCT_IMAGES',
	DELETE_RESOURCE: 'DELETE_RESOURCE',
	VIEW_CONNECTED_RESOURCES: 'VIEW_CONNECTED_RESOURCES',
	CONNECT_PRODUCTS: 'CONNECT_PRODUCTS',
	UPLOADING_RESOURCES: 'UPLOADING_RESOURCES'
}

const initialState = {
	type: null
}

export function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case ACTIONS.OPEN_MODAL.main:
			return {
				type: action.payload.modalType
			}
		case ACTIONS.CLOSE_MODAL.main:
			return {
				type: null
			}
		default:
			return state
	}
}

export const openModal = (modalType) => ({
	type: ACTIONS.OPEN_MODAL.main,
	payload: {
		modalType: modalType
	}
})

export const closeModal = () => ({
	type: ACTIONS.CLOSE_MODAL.main
})
