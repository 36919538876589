import React from 'react'
import { Button } from '../Button'
import { Loader } from '../Loader'

import './LoadMoreComponent.scss'

export default function LoadMoreComponent({ onLoadMore, isLoading, loadingMoreText }) {
	return !isLoading ? (
		<div className='load-more-component-container'>
			<Button title='Încarcă mai multe' onClick={onLoadMore} />
		</div>
	) : (
		<div className='loading-container'>
			<Loader size='small' />
			<p className='loading-text'>{loadingMoreText}</p>
		</div>
	)
}
