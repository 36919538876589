/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { CloudArrowUpIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import { isEmpty, values, uniqBy, omitBy } from 'lodash'

import { Button } from '../Button'
import { ImagesSlider } from '../ImagesSlider'

import './MultiImageDropzone.scss'

export default function Dropzone({ label, onChangeFiles, initialImages }) {
	const [files, setFiles] = useState([])
	const [allImages, setAllImages] = useState(initialImages || [])
	const [removeImages, setRemoveImages] = useState([])

	useEffect(() => onChangeFiles(files, removeImages), [allImages])
	useEffect(() => {
		if(initialImages?.length) {
			setAllImages(initialImages)
			setFiles([])
		}
	}, [initialImages])
	// useEffect(() => () => {
	// 	console.log('here', removeImages)
	// 	onChangeFiles(files, removeImages)
	// }, [removeImages])

	const handleDrop = (acceptedFiles) => {
		const filesWithPreviews = acceptedFiles.map((file) =>
			Object.assign(file, {
				url: URL.createObjectURL(file)
			})
		)

		setFiles((files) =>
			files.length === 0 ? filesWithPreviews : uniqBy([...files, ...filesWithPreviews], (file) => file.path)
		)

		setAllImages((prevImages) => uniqBy([...prevImages, ...filesWithPreviews], (file) => file.path ? file.path : file.id))
	}

	const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: onDrop,
		noClick: true,
		noKeyboard: true
	})
	return (
		<div {...getRootProps({ className: 'edy-optic-multi-image-dropzone' })}>
			<input {...getInputProps()} />
			{!isEmpty(allImages) ? (
				<div className='edy-optic-multi-image-dropzone-images-preview-container'>
					<div className='images-preview-slider'>
						<ImagesSlider
							images={allImages}
							onRemoveImage={(image) => {
								console.log(image)
								if(image.path) {
									const newFiles = values(omitBy(files, ['path', image.path]))
									setFiles(newFiles)
								}
								else {
									setRemoveImages(prevIds => [...prevIds, image.id])
								}
								setAllImages(values(omitBy(allImages, ['url', image.url])))
							}}
							getImageSrc={(image) => image.url}
							hasPreviews
						/>
					</div>
					<div className='upload-more-container'>
						<Button
							title='Adaugă imagine'
							onClick={open}
							icon={() => <PlusIcon />}
							iconLeft
							type='button'
						/>
						<p className='secondary-text'>sau mută imaginile aici pentru a încărca.</p>
					</div>
				</div>
			) : (
				<div className='edy-optic-multi-image-dropzone-upload-container'>
					<CloudArrowUpIcon className='upload-icon' />
					<p className='multi-image-dropzone-label'>{label}</p>
					<Button title='Adaugă imagine' onClick={open} icon={() => <PlusIcon />} iconLeft type='button' />
					<p className='secondary-text'>sau mută imaginile aici pentru a încărca.</p>
				</div>
			)}
		</div>
	)
}
