import React, { Component } from 'react'
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { values } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { usersSidebarItems } from '../../utils'

import { columns } from './constants'

import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Sidebar } from '../../components/Sidebar'
import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import { AddUserCategoryModal, EditUserCategoryModal, DeleteUserCategoryModal } from './partials'

import './UserCategories.scss'

export class UserCategories extends Component {
	constructor() {
		super()

		this.state = {
			selectedUserCategory: {}
		}
	}

	componentDidMount() {
		const { listUserCategories } = this.props

		listUserCategories()
	}

	render() {
		const { selectedUserCategory } = this.state
		const { userCategories, isLoading, openModal } = this.props

		return (
			<div className='user-categories-page-container'>
				<Sidebar items={usersSidebarItems} />
				<AddUserCategoryModal />
				<EditUserCategoryModal userCategory={selectedUserCategory} />
				<DeleteUserCategoryModal userCategory={selectedUserCategory} />
				<div className='user-categories-page-content-container'>
					<div className='user-categories-page-header-container'>
						<PageHeader pageTitle='Categorii Utilizatori' parentRoute='/home' disableShadow />
						{!isLoading ? (
							<Button
								title='Adaugă Categorie'
								icon={() => <PlusIcon />}
								onClick={() => openModal(modalTypes.ADD_USER_CATEGORY)}
								className='add-button'
								size='large'
								iconLeft
							/>
						) : (
							<Loader />
						)}
					</div>
					{!isLoading && (
						<div className='user-categories-page-content'>
							<Datatable
								data={userCategories}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'id',
										Cell: ({ row }) =>
											row.original.id !== 1 && (
												<div className='buttons-row'>
													<Button
														icon={() => <TrashIcon />}
														onClick={() => {
															this.setState({ selectedUserCategory: row.original })
															openModal(modalTypes.DELETE_USER_CATEGORY)
														}}
														color='red'
														size='small'
														iconLeft
													/>
													<Button
														title='Modifică'
														icon={() => <PencilIcon />}
														onClick={() => {
															this.setState({ selectedUserCategory: row.original })
															openModal(modalTypes.EDIT_USER_CATEGORY)
														}}
														size='small'
														iconLeft
													/>
												</div>
											)
									}
								]}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	userCategories: values(state.userCategories.data),
	isLoading: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	openModal: (type) => dispatch(openModal(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCategories)
