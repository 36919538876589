import React, { Component } from 'react'
import { Formik } from 'formik'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import { values as _values, isNull, head, isEmpty, debounce } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'
import { ResourcesPanel } from '../../components/ResourcesPanel'
import { ProductsPanel } from '../../components/ProductsPanel'
import { SendEmailPanel } from '../../components/SendEmailPanel'
import { ArticleImageDropzone } from '../../components/ArticleImageDropzone'

import { panelTypes } from './constants'
import { validations } from '../../assets/validations'

import './AddNews.scss'
import { Toggle } from '../../components/Toggle'

export class AddNews extends Component {
	constructor() {
		super()

		this.state = {
			openPanel: panelTypes.RESOURCES,
			resourceQuery: '',
			productQuery: '',
			selectedBrandID: null
		}
	}

	componentDidMount = () => {
		const { listBrands, listUserCategories } = this.props

		listBrands()
		listUserCategories()
	}

	handleOpenPanel = (panel) =>
		this.setState((prevState) => ({
			openPanel: prevState.openPanel !== panel ? panel : null
		}))

	getSelectedUserCategoriesArray = (userCategories = this.props.userCategories) =>
		userCategories.map((category) => category.id)

	handleListResources = (brandID = this.state.selectedBrandID, search = this.state.resourceQuery) => {
		const { listResources, isLoading } = this.props

		let params = { brand_id: brandID }

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading) {
			listResources(params)
		}
	}

	debounceSearchResources = debounce((query) => this.handleListResources(this.state.selectedBrandID, query), 300)

	handleChangeResourcesSearch = (query) => {
		this.setState({ resourceQuery: query })
		this.debounceSearchResources(query)
	}

	handleListProducts = (brandID = this.state.selectedBrandID, search = this.state.resourceQuery) => {
		const { listProducts, isLoading } = this.props

		let params = { brand_id: brandID }

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading) {
			listProducts(params)
		}
	}

	debounceSearchProducts = debounce((query) => this.handleListProducts(this.state.selectedBrandID, query), 300)

	handleChangeProductsSearch = (query) => {
		this.setState({ productQuery: query })
		this.debounceSearchProducts(query)
	}

	render() {
		const { openPanel, resourceQuery, productQuery } = this.state
		const {
			newsErrors,
			isLoading,
			brands,
			isLoadingBrands,
			userCategories,
			isLoadingUserCategories,
			createArticle
		} = this.props

		return (
			<>
				{!isEmpty(brands) && !isLoadingBrands && !isEmpty(userCategories) && !isLoadingUserCategories ? (
					<Formik
						initialValues={{
							title: '',
							brand: null,
							image: {},
							content: '<p>Conținutul acestei noutăți...</p>',
							connectedResources: [],
							connectedProducts: [],
							notifications: false,
							userCategories: this.getSelectedUserCategoriesArray(),
							site_published: false
						}}
						validationSchema={validations.news}
						onSubmit={(values) => {
							let newArticleData = new FormData()

							newArticleData.append('title', values.title)
							newArticleData.append('brand_id', values.brand.id)
							newArticleData.append('content', values.content)
							newArticleData.append('image', values.image, values.image.name)
							newArticleData.append('content', values.site_published)

							if (!isEmpty(values.connectedResources)) {
								values.connectedResources.forEach((resource) => {
									newArticleData.append('connected_resources[]', resource.id)
								})
							}

							if (!isEmpty(values.connectedProducts)) {
								values.connectedProducts.forEach((product) => {
									newArticleData.append('connected_products[]', product.id)
								})
							}

							if (values.notifications && !isEmpty(values.userCategories)) {
								values.userCategories.forEach((category) => {
									newArticleData.append('user_categories[]', category)
								})
							}

							createArticle(newArticleData)
						}}
					>
						{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
							<div className='add-news-container'>
								<div className='add-news-left'>
									<PageHeader pageTitle='Adaugă Noutate' parentRoute='/news' />
									<div className='add-news-form-container'>
										<Input
											value={values.title}
											placeholder='Titlu noutate'
											onChange={handleChange('title')}
											onBlur={handleBlur('title')}
											size='large'
											name='title'
											errors={newsErrors}
											frontendErrors={errors}
											touched={touched.title}
											fullWidth
										/>
										<div className='add-news-form-row'>
											<Select
												placeholder='Alege brand-ul'
												size='extraLarge'
												value={values.brand}
												options={brands}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												onChange={(option) => {
													setFieldValue('brand', option)

													if (!isNull(option)) {
														this.handleListResources(option.id)
														this.handleListProducts(option.id)
														this.setState({ selectedBrandID: option.id })
													}
												}}
												onBlur={handleBlur('brand')}
												name='brand'
												errors={newsErrors}
												frontendErrors={errors}
												touched={touched.brand}
												isClearable
												fullWidth
											/>
											<ArticleImageDropzone
												value={values.image}
												onChangeFiles={(images) => setFieldValue('image', head(images))}
											/>
										</div>
										<div className='news-content-editor-container'>
											<CKEditor
												editor={Editor}
												className='ckeditor'
												data={values.content}
												onChange={(_event, editor) => {
													const data = editor.getData()

													setFieldValue('content', data)
												}}
											/>
										</div>
									</div>
								</div>
								<div className='add-news-right'>
									<div className='panels-container'>
										<ResourcesPanel
											brandID={values.brand?.id || null}
											isOpen={openPanel === panelTypes.RESOURCES}
											openConent={() => this.handleOpenPanel(panelTypes.RESOURCES)}
											onConnectResources={(resources) =>
												setFieldValue('connectedResources', resources)
											}
											connectedResources={values.connectedResources}
											resourceQuery={resourceQuery}
											onSearchResources={this.handleChangeResourcesSearch}
										/>
										<ProductsPanel
											brandID={values.brand?.id || null}
											isOpen={openPanel === panelTypes.PRODUCTS}
											openConent={() => this.handleOpenPanel(panelTypes.PRODUCTS)}
											onConnectProducts={(products) =>
												setFieldValue('connectedProducts', products)
											}
											connectedProducts={values.connectedProducts}
											productQuery={productQuery}
											onSearchProducts={this.handleChangeProductsSearch}
										/>
										<SendEmailPanel
											isOpen={openPanel === panelTypes.EMAIL}
											openConent={() => this.handleOpenPanel(panelTypes.EMAIL)}
											notifications={values.notifications}
											changeNotifications={(checked) => {
												setFieldValue('notifications', checked)

												if (checked) {
													this.setState({ openPanel: panelTypes.EMAIL })
												}
											}}
											initialSelectedCategories={values.userCategories}
											onChangeUserCategories={(userCategories) => {
												setFieldValue('userCategories', userCategories)
											}}
										/>
									</div>

									<div className='w-full flex flex-col gap-2'>
										<Toggle
											label='Afișare pe site'
											checked={values.site_published}
											onChange={(value) => {
												setFieldValue('site_published', value)
											}}
										/>

										<Button
											title='Adaugă Noutatea'
											onClick={handleSubmit}
											disabled={
												isNull(values.brand) ||
												isEmpty(values.title) ||
												isEmpty(values.content) ||
												isEmpty(values.image)
											}
											loading={isLoading}
											type='submit'
											size='large'
											fullWidth
										/>
									</div>
								</div>
							</div>
						)}
					</Formik>
				) : isLoadingBrands || isLoadingUserCategories ? (
					<PageLoader />
				) : null}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	newsErrors: state.news.errors,
	isLoading: state.news.isLoading,
	brands: _values(state.brands.data),
	isLoadingBrands: state.brands.isLoading,
	userCategories: _values(state.userCategories.data),
	isLoadingUserCategories: state.userCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	listResources: (params) => dispatch(RESOURCES.resources.list(params)),
	listProducts: (params) => dispatch(RESOURCES.products.list(params)),
	createArticle: (articleData) => dispatch(RESOURCES.news.create(articleData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNews)
