import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'

import { orderBy, isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import './BrandProductCategories.scss'

export class BrandProductCategories extends Component {
	componentDidMount() {
		const {
			retrieveBrands,
			listProductCategories,
			match: { params }
		} = this.props

		const { brandID } = params

		retrieveBrands(brandID)
		listProductCategories(brandID)
	}

	render() {
		const {
			brand,
			isLoadingBrands,
			productCategories,
			isLoadingCategories
		} = this.props

		return (
			<div className='brand-product-categories-container'>
				<div className='brand-product-categories-header'>
					<div className='left'>
						<Link to={`/brands/${brand.id}`}>
							<Button
								icon={() => <ArrowLeftIcon className='back-button-icon' />}
								size='large'
								color='primaryLight'
								className='back-button'
							/>
						</Link>
						<p className='brand-name'>Categorii produse {brand.name}</p>
					</div>
					{isLoadingBrands && <Loader />}
				</div>
				<div className='resources-container-outter'>
					<div className='resources-container'>
						{!isEmpty(productCategories) ? (
							productCategories.map((category) => (
								<BrandResourceCategory productCategory={category} key={category.id} />
							))
						) : isLoadingCategories ? (
							<div className='loader-container'>
								<Loader />
							</div>
						) : isEmpty(productCategories) && !isLoadingCategories ? (
							<p>Nu există categorii de produse</p>
						) : null}
					</div>
				</div>
			</div>
		)
	}
}

function BrandResourceCategory({ productCategory }) {
	return (
		<Link
			to={`/shop?brand=${productCategory.brand.id}&category=${productCategory.id}`}
			className={`brand-resource-container ${!!productCategory.image_url ? 'has-image' : ''}`}
		>
			{productCategory.image_url ? (
				<img className="category-image" src={productCategory.image_url} alt={productCategory.name} />
			) : (
				<>
					<div className='brand-resource-icon-container'>
						{productCategory.getIcon ? productCategory.getIcon() : <img src={productCategory.icon_url} alt='' />}
					</div>
					<div className='brand-resource-title-container'>
						<p className='brand-resource-title'>{productCategory.name}</p>
					</div>
					<ArrowRightIcon className='brand-resource-arrow' />
				</>
			)}
		</Link>
	)
}

const mapStateToProps = (state) => ({
	brand: state.brands.currentBrand,
	isLoadingBrands: state.brands.isLoading,
	productCategories: orderBy(state.productCategories.data, ['order', 'id'], 'asc'),
	isLoadingCategories: state.productCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listProductCategories: (brandID) => dispatch(RESOURCES.productCategories.list({ brand_id: brandID })),
	retrieveBrands: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandProductCategories)
