/* eslint-disable no-loop-func */
import {
	ArchiveBoxIcon,
	DocumentPlusIcon,
	TruckIcon,
	UserGroupIcon,
	UsersIcon,
	Squares2X2Icon,
	TagIcon
} from '@heroicons/react/24/outline'

import { isArray, snakeCase, forEach, head, values, round, split, replace, set } from 'lodash'

import { history } from './redux/store'

export const navigate = (path) => history.push(path)

export const goBack = () => history.goBack()

export const objectKeysToSnakeCase = (object) => {
	let newObject = {}
	
	for (var key in object) {
		if (isArray(object[key])) {
			newObject[snakeCase(key)] = []

			forEach(object[key], (element, idx) => {
				let newSecondLevelObject = {}

				for (var secondKey in element) {
					newSecondLevelObject[snakeCase(secondKey)] = object[key][idx][secondKey]
				}

				newObject[snakeCase(key)].push(newSecondLevelObject)
			})
		} else {
			newObject[snakeCase(key)] = object[key]
		}
	}

	return newObject
}

export const formatBytes = (bytes, decimals = 2) => {
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	if (bytes === 0) return `0 ${head(sizes)}`

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getTotalPrice = (products) => {
	let total = 0

	forEach(values(products), (product) => {
		const productPrice = product.price * product.quantity.value

		total = total + productPrice
	})

	return round(total, 2)
}

export const brandsSidebarItems = [
	{
		title: 'Adăugare Resurse',
		link: '/add-resources',
		icon: () => <DocumentPlusIcon className='edy-optic-sidebar-link-icon' />
	},
	{
		title: 'Branduri',
		link: '/brands',
		icon: () => <TagIcon className='edy-optic-sidebar-link-icon' />
	}
]

export const usersSidebarItems = [
	{
		title: 'Utilizatori',
		link: '/users',
		icon: () => <UsersIcon className='edy-optic-sidebar-link-icon' />
	},
	{
		title: 'Categorii Utilizatori',
		link: '/user-categories',
		icon: () => <UserGroupIcon className='edy-optic-sidebar-link-icon' />
	}
]

export const shopAdminSidebarItems = [
	{
		title: 'Comenzi Shop',
		link: '/orders',
		icon: () => <TruckIcon className='edy-optic-sidebar-link-icon' />
	},
	{
		title: 'Produse Shop',
		link: '/products',
		icon: () => <ArchiveBoxIcon className='edy-optic-sidebar-link-icon' />
	},
	{
		title: 'Categorii Produse',
		link: '/product-categories',
		icon: () => <Squares2X2Icon className='edy-optic-sidebar-link-icon' />
	}
]

export const getPageParams = (location) => {
	const { search } = location

	const urlParams = split(replace(search, '?', ''), '&')
	const params = {}

	forEach(urlParams, (param) => {
		const paramSplit = split(param, '=')

		set(params, paramSplit[0], paramSplit[1])
	})

	return params
}

export const generateSlug = (text) => {
	return text ? text.toLowerCase()
		.replace(/[^\w-_ ]+/g, '') // replace with nothing
		.replace(/[_ ]+/g, '-') // replace _ and spaces with -
		.substring(0,99) : ''; // limit str length
}