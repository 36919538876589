import React, { useEffect } from 'react'
import { PrinterIcon } from '@heroicons/react/24/outline'

import { lightFormat } from 'date-fns'
import { isEmpty, values, isNull, sortBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { updateOrderStatus } from '../../redux/orders'

import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { PageHeader } from '../../components/PageHeader'
import { Loader } from '../../components/Loader'

import logo from '../../assets/images/logo-edyoptic-new.svg'

import './OrderDetails.scss'

export const OrderDetails = ({
	user,
	order,
	isLoading,
	retrieveOrder,
	orderStatuses,
	listOrderStatuses,
	updateStatus,
	match: {
		params: { orderID }
	}
}) => {
	useEffect(() => user.is_admin && listOrderStatuses(), [listOrderStatuses, user])
	useEffect(() => orderID && retrieveOrder(orderID), [orderID, retrieveOrder])

	return (
		<div className='order-details-page-container'>
			<div className='orders-page-header-container'>
				<PageHeader pageTitle='Detalii Comandă' parentRoute='/orders' disableShadow />
				{isLoading && <Loader />}
			</div>
			<div className='order-details-container'>
				{!isEmpty(order) && (
					<>
						<div className='order-details-header'>
							<div className='order-info'>
								<div className='logo-container'>
									<img src={logo} alt='EdyOptic Logo' />
								</div>
								<Button
									title='Printează comanda'
									onClick={window.print}
									icon={() => <PrinterIcon />}
									iconLeft
									size='small'
									className='print-button'
								/>
								<p className='order-number'>
									Număr comandă: <span>{order.id}</span>
								</p>
								<p className='order-date'>
									Data: <span>{lightFormat(new Date(order.created_at), 'dd/MM/yyyy HH:mm')}</span>
								</p>
								{user.is_admin ? (
									<div className='order-status-desktop'>
										<p className='order-status'>Status:</p>
										<Select
											value={order.status}
											options={orderStatuses}
											onChange={(option) => updateStatus(orderID, option.id)}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								) : (
									<div className='order-status-admin'>
										Staus: <span>{order.status.name}</span>
									</div>
								)}
								<div className='order-status-print'>
									Staus: <span>{order.status.name}</span>
								</div>
							</div>
							<div className='recipient-info'>
								<p className='invoice-to'>Destinatar:</p>
								<p className='recipient-name'>
									{order.user.first_name} {order.user.last_name}
								</p>
								<p className='recipient-email'>{order.user.email}</p>
								{order.user.phone && <p className='recipient-phone'>{order.user.phone}</p>}
								{order.user.address && <p className='recipient-address'>{order.user.address}</p>}
								{order.user.city && <p className='recipient-city'>{order.user.city}</p>}
								{order.user.optica && <p className='recipient-optica'>{order.user.optica}</p>}
							</div>
						</div>
						<div className='order-details-content'>
							<table className='order-products-table'>
								<thead>
									<tr>
										<th>Nr</th>
										<th className='name-column'>Produs</th>
										<th>Brand</th>
										<th>Preț</th>
										<th>Cantitate</th>
										<th>Subtotal</th>
									</tr>
								</thead>
								<tbody>
									{sortBy(order.products, (p) => p.product.category?.brand?.id).map(
										(product, productIdx) => (
											<tr key={product.id}>
												<td>{productIdx + 1}</td>
												<td className='name-column'>{product.product.name}</td>
												<td>{product.product.category?.brand?.name || '-'}</td>
												<td>
													{isNull(product.sale_price)
														? 'Solicită ofertă'
														: `${product.sale_price} RON`}
												</td>
												<td>{product.quantity}</td>
												<td>{product.sale_subtotal.toLocaleString()} RON</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
						<div className='order-details-footer'>
							<div className='order-price-container'>
								<p className='sub-total'>Subtotal: {order.total.toLocaleString()} RON</p>
								<p className='discount'>
									Discount: {order.discount_value} RON ({order.discount.toLocaleString() || '0'}%)
								</p>
								<p className='total'>Total: {order.sale_total.toLocaleString()} RON</p>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	order: state.orders.currentOrder,
	isLoading: state.orders.isLoading,
	orderStatuses: values(state.orderStatuses.data)
})

const mapDispatchToProps = (dispatch) => ({
	retrieveOrder: (orderID) => dispatch(RESOURCES.orders.retrieve(orderID)),
	listOrderStatuses: () => dispatch(RESOURCES.orderStatuses.list()),
	updateStatus: (orderID, statusID) => dispatch(updateOrderStatus(orderID, { status_id: statusID }))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
