import { REHYDRATE } from 'redux-persist'
import { put, select, takeEvery } from 'redux-saga/effects'

import { isEmpty } from 'lodash'

import { Action } from '.'
import { RESOURCES } from './spec'

export const ACTIONS = {
	INITIALIZE: new Action('INITIALIZE'),
	INITIALIZED: new Action('INITIALIZED')
}

const initialState = {}

export function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case ACTIONS.INITIALIZE.main:
			return state
		case ACTIONS.INITIALIZED.main:
			return state
		default:
			return state
	}
}

export function handleAppInitialization() {
	console.log('Do app init stuff...')
}

export function* handleRehydration(response) {
	console.log('Do app rehydrate  stuff...', response)

	const existingUser = yield select((state) => state.users.user)

	if (!isEmpty(existingUser)) {
		yield put(RESOURCES.users.retrieve(existingUser.id))
	}
}

export function* saga() {
	yield takeEvery(ACTIONS.INITIALIZE.main, handleAppInitialization)
	yield takeEvery(REHYDRATE, handleRehydration)
}

export const initialize = () => ({ type: ACTIONS.INITIALIZE.main })
