import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { login } from '../../redux/auth'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { Checkbox } from '../../components/Checkbox'

import EdyOpticLogo from '../../assets/images/logo-edyoptic-new-white.svg'
import AppIndicator from '../../assets/images/app-indicator-light.svg'

import './Login.scss'

export const Login = ({ login, isLoading }) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div id='login-container'>
			<div className='login-left'>
				<div className='mx-auto w-full max-w-sm lg:w-96'>
					<div>
						<p className='title-3xl'>Autentificare</p>
						<p className='need-account'>
							Ai nevoie de cont? <ContactLink /> echipa Edy Optic.
						</p>
					</div>
					<div className='mt-20'>
						<Formik
							initialValues={{ email: '', password: '', rememberMe: false }}
							onSubmit={(values) => login(values)}
						>
							{({ handleChange, setFieldValue, values, handleSubmit }) => (
								<Form className='space-y-6'>
									<Input
										label='Adresa de email'
										value={values.email}
										onChange={handleChange('email')}
										placeholder='Introdu adresa de email'
										autoComplete='email'
										type='email'
										fullWidth
									/>
									<div className='password-input-container'>
										<Input
											label='Parola'
											value={values.password}
											onChange={handleChange('password')}
											autoComplete='password'
											type={showPassword ? 'text' : 'password'}
											placeholder='Introdu parola'
											fullWidth
										/>
										<Checkbox
											label='Afișează parola'
											onChange={(e) => setShowPassword(e.target.checked)}
											checked={showPassword}
										/>
									</div>
									<div className='password-actions-container'>
										<Checkbox
											label='Ține-mă minte'
											onChange={handleChange('rememberMe')}
											checked={values.rememberMe}
										/>
										<Link to='/forgot-password' className='forgot-password-link'>
											Ai uitat parola?
										</Link>
									</div>
									<Button
										title='Autentificare'
										onClick={handleSubmit}
										type='submit'
										size='large'
										fullWidth
										disabled={isLoading}
									/>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
			<div className='login-right'>
				<img src={EdyOpticLogo} alt='Edy Optic Logo' className='edy-optic-logo' />
				<img src={AppIndicator} alt='Portal Clienți App Indicator' className='app-indicator-image' />
			</div>
		</div>
	)
}

function ContactLink() {
	return (
		<a href='https://edyoptic.ro/contacte/' target='_blank' rel='noopener noreferrer' className='contact-link'>
			Contactează
		</a>
	)
}

const mapStateToProps = (state) => ({
	isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	login: (credentials) => dispatch(login(credentials))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
