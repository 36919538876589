import { toast } from 'react-toastify'
import { put, takeEvery } from 'redux-saga/effects'

import { keyBy, omit } from 'lodash'

import { RESOURCES } from './spec'
import { ACTIONS as AUTH_ACTIONS } from './auth'
import { closeModal } from './modals'
import { getPages } from './utils'

const { users: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	user: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	token: null,
	isAuthenticated: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
		case resource.actions.create.main:
		case resource.actions.update.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.retrieve.main:
			// currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			const user = action.payload.data.id === state.user.id ? action.payload.data : state.user

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				user: user,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.retrieve.success:

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				user: action.payload.data.id === state.user.id ? action.payload.data : state.user,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.fail:
		case resource.actions.create.fail:
		case resource.actions.update.fail:
		case resource.actions.retrieve.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case AUTH_ACTIONS.ACTIVATE_ACCOUNT.success:
		case AUTH_ACTIONS.SIGNUP.success:
		case AUTH_ACTIONS.FORGOT_PASSWORD.success:
		case AUTH_ACTIONS.RESET_PASSWORD.success:
			return {
				...state,
				errors: {}
			}
		case AUTH_ACTIONS.LOGIN.success:
			return {
				...state,
				token: action.payload.token,
				user: action.payload.user,
				isAuthenticated: true,
				errors: {}
			}
		case AUTH_ACTIONS.LOGIN.fail:
			return {
				...state,
				isAuthenticated: false
			}
		case AUTH_ACTIONS.LOGOUT.main:
			return initialState
		default:
			return state
	}
}

function* handleCreateUserSuccess() {
	toast.success('Utilizatorul a fost adăugat cu succes!')
	yield put(closeModal())
}

function* closeModalOnSuccess() {
	yield put(closeModal())
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateUserSuccess)
	yield takeEvery(resource.actions.update.success, closeModalOnSuccess)
	yield takeEvery(resource.actions.destroy.success, closeModalOnSuccess)
}
