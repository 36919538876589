import React from 'react'
import { Switch } from '@headlessui/react'

import './Toggle.scss'

export default function Toggle({ label, checked, onChange }) {
	return (
		<div className='toggle-label-container'>
			{label ? (
				<label as='span' className='toggle-label'>
					{label}
				</label>
			) : null}
			<Switch checked={checked} onChange={onChange} className={`toggle-container ${checked ? 'checked' : ''}`}>
				<span className='toggle'>
					<span className='dot-container dot-disabled-container' aria-hidden='true'>
						<svg className='dot' fill='none' viewBox='0 0 12 12'>
							<path
								d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
								stroke='currentColor'
								strokeWidth={2}
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</span>
					<span className='dot-container dot-checked-container' aria-hidden='true'>
						<svg className='dot' fill='currentColor' viewBox='0 0 12 12'>
							<path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
						</svg>
					</span>
				</span>
			</Switch>
		</div>
	)
}
