import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

import './Sidebar.scss'

export default function Sidebar({ items }) {
	const getSelectedStatus = (item) => window.location.pathname === item.link

	return (
		<div className='edy-optic-sidebar-container'>
			{items.map((item, itemIdx) => {
				const isSelected = getSelectedStatus(item)

				return (
					<Link
						to={item.link}
						className={`edy-optic-sidebar-link-container ${isSelected ? 'selected' : ''}`}
						key={itemIdx}
					>
						<div className='edy-optic-sidebar-link-left'>
							{item.icon()}
							<p className='edy-optic-sidebar-link-title'>{item.title}</p>
						</div>
						<ChevronRightIcon className='edy-optic-sidebar-link-arrow-icon' />
					</Link>
				)
			})}
		</div>
	)
}
