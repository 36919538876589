import React from 'react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

import { isEmpty, camelCase, snakeCase, head } from 'lodash'

import { toNumber } from 'lodash'

import { Button } from '../Button'
import { InputError } from '../ErrorComponents'

import './NumberInput.scss'

export default function NumberInput({
	value,
	label,
	min,
	onChange,
	onBlur,
	fullWidth,
	name,
	errors,
	frontendErrors,
	touched,
	className
}) {
	const snakeCaseName = snakeCase(name)
	const camelCaseName = camelCase(name)

	const hasErrors =
		(errors && !isEmpty(errors[snakeCaseName])) ||
		(frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

	const getErrorMessage = () =>
		errors && !isEmpty(errors[snakeCaseName]) ? head(errors[snakeCaseName]) : frontendErrors[camelCaseName]

	const minValue = min ? min : 0

	return (
		<div className={`edy-optic-number-input-label-container ${fullWidth ? 'full-width' : ''} ${className || ''}`}>
			{label && <label className='edy-optic-label'>{label}</label>}
			<div className='edy-optic-number-input-container'>
				<Button
					icon={() => <MinusIcon />}
					id='edy-optic-number-input-button'
					onClick={() => value > minValue && onChange(toNumber(value) - 1)}
					color='white'
				/>
				<input
					value={value}
					className='edy-optic-number-input'
					onChange={(e) => onChange(toNumber(e.target.value))}
					onBlur={onBlur}
					type='number'
					min={minValue}
				/>
				<Button
					icon={() => <PlusIcon />}
					id='edy-optic-number-input-button'
					onClick={() => onChange(toNumber(value) + 1)}
					color='white'
				/>
			</div>
			{hasErrors && <InputError message={getErrorMessage()} />}
		</div>
	)
}
