import React, { Component } from 'react'
import FormData from 'form-data'
import { Form, Formik } from 'formik'

import { isEmpty } from 'lodash'

import { brandsSidebarItems, generateSlug } from '../../utils'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { Textarea } from '../../components/Textarea'
import { SingleImageDropzone } from '../../components/SingleImageDropzone'
import { ImageDropzone } from '../../components/ImageDropzone'

import { Sidebar } from '../../components/Sidebar'
import { PageHeader } from '../../components/PageHeader'

import { validations } from '../../assets/validations'

import './EditBrand.scss'
import { PageLoader } from '../../components/PageLoader'
import { Toggle } from '../../components/Toggle'

export class EditBrand extends Component {
	constructor() {
		super()

		this.state = {
			
		}
	}

	componentDidMount = () => {
		const {
			retrieveBrand,
			match: {
				params: { brandID }
			}
		} = this.props

		retrieveBrand(brandID)
	}

	render() {
		const {
			brandsErrors,
			isLoading,
			brand,
			updateBrand
		} = this.props

		console.log(brand)

		return (
			<div className='add-brand-page-container'>
				<Sidebar items={brandsSidebarItems} />
				<div className='add-brand-page-content-container'>
					<PageHeader pageTitle='Editează Brand' parentRoute='/brands' />
					{!isLoading ? (
						<Formik
							initialValues={{
								order: brand.order,
								name: brand.name,
								slug: brand.slug || '',
								subtitle: brand.subtitle || '',
								link: brand.link || '',
								link_text: brand.link_text || '',
								categories_description: brand.categories_description || '',
								short_description: brand.short_description || '',
								description1: brand.description1 || '',
								description2: brand.description2 || '',
								logo: {},
								image: {},
								logo_crop: '',
								image_crop: '',
								sales_image: {},
								resources_image: {},
								news_image: {},
								products_image: {},
								site_published: brand.site_published || false
							}}
							enableReinitialize
							validationSchema={validations.brands}
							onSubmit={async (values) => {
								let brandData

								if (!isEmpty(values.image) || !isEmpty(values.logo) 
									|| !isEmpty(values.sales_image) || !isEmpty(values.resources_image)
									|| !isEmpty(values.news_image) || !isEmpty(values.products_image)) {
									brandData = new FormData()

									brandData.append('name', values.name)
									brandData.append('order', values.order)
									brandData.append('slug', values.slug)
									brandData.append('subtitle', values.subtitle)
									brandData.append('link', values.link)
									brandData.append('link_text', values.link_text)
									brandData.append('categories_description', values.categories_description)
									brandData.append('short_description', values.short_description)
									brandData.append('description1', values.description1)
									brandData.append('description2', values.description2)
									brandData.append('site_published', values.site_published)

									if (!isEmpty(values.logo)) {
										brandData.append('logo', values.logo, values.logo.name)
										brandData.append('logo_crop', values.logo_crop)
									}

									if (!isEmpty(values.image)) {
										brandData.append('image', values.image, values.image.name)
										brandData.append('image_crop', values.image_crop)
									}

									if (!isEmpty(values.sales_image)) {
										brandData.append('sales_image', values.sales_image, values.sales_image.name)
									}

									if (!isEmpty(values.resources_image)) {
										brandData.append('resources_image', values.resources_image, values.resources_image.name)
									}

									if (!isEmpty(values.news_image)) {
										brandData.append('news_image', values.news_image, values.news_image.name)
									}

									if (!isEmpty(values.products_image)) {
										brandData.append('products_image', values.products_image, values.products_image.name)
									}
								} else {
									brandData = { 
										name: values.name,
										slug: values.slug,
										order: values.order,
										link: values.link,
										link_text: values.link_text,
										subtitle: values.subtitle,
										categories_description: values.categories_description,
										short_description: values.short_description,
										description1: values.description1,
										description2: values.description2,
										site_published: values.site_published
									}
								}

								updateBrand(brandData, brand.id)
							}}
						>
							{({ handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched }) => (
								<Form className='add-brand-form-container'>
									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Input
												label='Nr. Ordine'
												value={values.order}
												onChange={handleChange('order')}
												onBlur={handleBlur('order')}
												size='large'
												name='order'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.order}
												type='number'
												fullWidth
											/>
										</div>
									</div>
									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Input
												label='Nume'
												value={values.name}
												onChange={(e) => {
													setFieldValue('name', e.target.value)
													setFieldValue('slug', generateSlug(e.target.value))
												}}
												placeholder='Introdu numele brandului'
												onBlur={handleBlur('name')}
												size='large'
												name='name'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.name}
												fullWidth
											/>
										</div>
										<div className='w-1/2'>
											<Input
												label='Slug'
												value={values.slug}
												onChange={handleChange('slug')}
												placeholder='Introdu numele brandului'
												onBlur={handleBlur('slug')}
												size='large'
												name='slug'
												disabled
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.slug}
												fullWidth
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Input
												label='Subtitlu'
												value={values.subtitle}
												onChange={handleChange('subtitle')}
												placeholder='Introdu subtitlul brandului'
												onBlur={handleBlur('subtitle')}
												size='large'
												name='subtitle'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.subtitle}
												fullWidth
											/>
										</div>
										<div className='w-1/2 flex align-end pt-5'>
											<Toggle
												label='Afișare pe site'
												checked={values.site_published}
												onChange={(value) => {
													setFieldValue('site_published', value)
												}}
											/>
										</div>
									</div>
									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Textarea
												label='Descriere categorii'
												value={values.categories_description}
												onChange={handleChange('categories_description')}
												placeholder='Introdu descrirea categoriilor'
												onBlur={handleBlur('categories_description')}
												size='large'
												name='categories_description'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.categories_description}
												fullWidth
											/>
										</div>
										<div className='w-1/2'>
											<Textarea
												label='Descriere scurta'
												value={values.short_description}
												onChange={handleChange('short_description')}
												placeholder='Introdu descrirea scurta brandului'
												onBlur={handleBlur('short_description')}
												size='large'
												name='short_description'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.short_description}
												fullWidth
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Input
												label='Text link'
												value={values.link_text}
												onChange={handleChange('link_text')}
												placeholder='Introdu textul link-ul brandului'
												onBlur={handleBlur('link_text')}
												size='large'
												name='link_text'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.link_text}
												fullWidth
											/>
										</div>
										<div className='w-1/2'>
											<Input
												label='Link'
												value={values.link}
												onChange={handleChange('link')}
												placeholder='Introdu link-ul brandului'
												onBlur={handleBlur('link')}
												size='large'
												name='link'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.link}
												fullWidth
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<Textarea
												label='Descriere 1'
												value={values.description1}
												onChange={handleChange('description1')}
												placeholder='Introdu descrirea brandului'
												onBlur={handleBlur('description1')}
												size='large'
												name='description1'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.description1}
												fullWidth
											/>
										</div>
										<div className='w-1/2'>
											<Textarea
												label='Descriere 2'
												value={values.description2}
												onChange={handleChange('description2')}
												placeholder='Introdu descrirea brandului'
												onBlur={handleBlur('description2')}
												size='large'
												name='description2'
												errors={brandsErrors}
												frontendErrors={errors}
												touched={touched.description2}
												fullWidth
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<label className="edy-optic-label">Logo</label>
											<SingleImageDropzone
												label='Logo Brand'
												onChange={(file) => setFieldValue('logo', file)}
												onCrop={(crop) => setFieldValue('logo_crop', JSON.stringify(crop))}
												name="logo"
												initialImageUrl={brand.logo_url}							
											/>
										</div>
										<div className='w-1/2'>
											<label className="edy-optic-label">Imagine Homepage</label>
											<SingleImageDropzone
												label='Imagine Homepage'
												onChange={(file) => setFieldValue('image', file)}
												onCrop={(crop) => setFieldValue('image_crop', JSON.stringify(crop))}
												aspectRatio={0.85}
												name="image"
												initialImageUrl={brand.image_url}
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<label className="edy-optic-label">Imagine Noutati</label>
											<ImageDropzone
												label='Incarca imagine'
												onChangeFiles={(files) => setFieldValue('news_image', files[0])}
												initialImageUrl={brand.news_image_url}
											/>
										</div>

										<div className='w-1/2'>
											<label className="edy-optic-label">Imagine Oferte</label>
											<ImageDropzone
												label='Incarca imagine'
												onChangeFiles={(files) => setFieldValue('sales_image', files[0])}
												initialImageUrl={brand.sales_image_url}
											/>
										</div>
									</div>

									<div className='add-brand-form-row'>
										<div className='w-1/2'>
											<label className="edy-optic-label">Imagine Produse</label>
											<ImageDropzone
												label='Incarca imagine'
												onChangeFiles={(files) => setFieldValue('products_image', files[0])}
												initialImageUrl={brand.products_image_url}
											/>
										</div>
										<div className='w-1/2'>
											<label className="edy-optic-label">Imagine Resurse</label>
											<ImageDropzone
												label='Incarca imagine'
												onChangeFiles={(files) => setFieldValue('resources_image', files[0])}
												initialImageUrl={brand.resources_image_url}
											/>
										</div>
									</div>

									<Button
										title='Salvează brandul'
										onClick={handleSubmit}
										loading={isLoading}
										disabled={isEmpty(values.name) || isEmpty(values.slug)}
										type='submit'
										size='large'
										fullWidth
									/>
								</Form>
							)}
						</Formik>
					) : (
						<PageLoader />
					)}
				</div>				
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	brandsErrors: state.brands.errors,
	isLoading: state.brands.isLoading,
	brand: state.brands.currentBrand
})

const mapDispatchToProps = (dispatch) => ({
	retrieveBrand: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID)),
	updateBrand: (values, brandID) => dispatch(RESOURCES.brands.update(values, brandID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditBrand)
