import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { isEmpty, values, groupBy, forEach } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { removeFromCart, resetCart } from '../../redux/cart'

import { PageHeader } from '../../components/PageHeader'
import { Button } from '../../components/Button'

import { CartProductCard } from './partials'

import './Cart.scss'

export class Cart extends Component {
	// /---
	// Because of the brand grouping of products we did, the cart returns a blank screen.
	// This is removing the products that don't have a category/brand.
	// (before the grouping, the API did not include a category/brand for the products in the cart)
	componentDidMount = () => {
		const { cart, removeFromCart } = this.props

		if (!isEmpty(cart.products)) {
			forEach(cart.products, (cartProduct) => {
				if (isEmpty(cartProduct.product.category?.brand)) {
					removeFromCart(cartProduct)
				}
			})
		}
	}
	// ---/

	handlePlaceOrder = () => {
		const { cart, placeOrder } = this.props
		const orderData = cart.products.map((product) => ({ product_id: product.id, quantity: product.quantity }))

		placeOrder(orderData)
	}

	handleRemoveFromCart = (product) => {
		const { cart, resetCart, removeFromCart } = this.props

		if (values(cart.products).length <= 1) {
			resetCart()
		} else {
			removeFromCart(product)
		}
	}

	render() {
		const { cart, user } = this.props

		const productsByBrand = !isEmpty(cart.products)
			? groupBy(cart.products, (p) => p.product.category.brand.name)
			: {}

		return (
			<div className='cart-page-container'>
				<div className='cart-page-left'>
					<PageHeader pageTitle='Coșul Meu' />
					<div className='products-container'>
						{!isEmpty(cart.products) ? (
							<>
								{user.category.discount ? (
									<p className='discount-disclaimer'>
										Faceți parte din categoria de utilizatori <span>{user.category.name}</span> și
										beneficiați de o reducere de <span>{user.category.discount}%</span>
									</p>
								) : null}
								{Object.keys(productsByBrand).map((brandName) => (
									<div key={brandName}>
										<p className='brand-name'>{brandName}</p>
										{productsByBrand[brandName].map((product) => (
											<CartProductCard
												product={product}
												handleRemoveFromCart={this.handleRemoveFromCart}
												key={product.id}
											/>
										))}
									</div>
								))}
							</>
						) : (
							<div className='no-products-container'>
								<p className='no-products-text'>Coșul este gol</p>
								<Link to='/shop'>
									<Button title='Mergi la magazin' />
								</Link>
							</div>
						)}
					</div>
				</div>
				{!isEmpty(cart.products) && (
					<div className='cart-page-right'>
						<div className='cart-page-right-top'>
							<p className='section-title'>Sumar Comandă</p>
							<p className='total-products'>
								{cart.products.length} {cart.products.length === 1 ? 'Produs' : 'Produse'}
							</p>
							<div className='prices-container'>
								<div className='price-section-row'>
									<p className='total'>Total</p>
									<p className='total'>{cart.sale_total.toLocaleString()} RON</p>
								</div>
							</div>
						</div>
						<div className='cart-page-right-button-container'>
							<Button
								title='Trimite Comanda'
								onClick={this.handlePlaceOrder}
								size='extraLarge'
								fullWidth
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	cart: state.cart.data,
	user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
	placeOrder: (products) => dispatch(RESOURCES.orders.create({ products: products })),
	removeFromCart: (product) => dispatch(removeFromCart(product)),
	resetCart: (product) => dispatch(resetCart())
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
