import React from 'react'

import { isString } from 'lodash'

import './Checkbox.scss'

export default function Checkbox({ label, checked, onChange, disabled, size, fullWidth, name, className }) {
	return (
		<div
			className={
				'edy-optic-checkbox-container ' +
				`${size || 'medium'} ` +
				`${fullWidth ? 'full-width' : ''} ` +
				`${disabled ? 'disabled' : ''}` +
				`${className || ''}`
			}
		>
			<div className='edy-optic-checkbox-center'>
				
				<label htmlFor={name} className='edy-optic-checkbox-label'>
					<input
						id={name}
						name={name}
						checked={checked}
						type='checkbox'
						className='checkbox'
						onChange={onChange}
						disabled={disabled}
					/>
					{label &&isString(label) ? label : label()}
				</label>
			</div>
		</div>
	)
}
