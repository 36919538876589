import React, { Component } from 'react'
import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { isEmpty, values } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { columns } from './constants'

import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { DeleteArticleModal } from '../../components/DeleteArticleModal'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'

import './News.scss'
import { modalTypes, openModal } from '../../redux/modals'

export class News extends Component {
	constructor() {
		super()

		this.state = {
			articleToDelete: {}
		}
	}

	componentDidMount() {
		const { listNews } = this.props

		listNews()
	}

	render() {
		const { articleToDelete } = this.state
		const { news, isLoading, openDeleteModal } = this.props

		return (
			<div className='news-page-container'>
				<DeleteArticleModal article={articleToDelete} />
				<div className='news-page-header-container'>
					<PageHeader pageTitle='Admin Noutăți' parentRoute='/home' disableShadow />
					{!isLoading ? (
						<Link to='/add-news'>
							<Button
								title='Adaugă Noutate'
								icon={() => <PlusIcon />}
								className='add-button'
								size='large'
								iconLeft
							/>
						</Link>
					) : (
						<Loader />
					)}
				</div>
				{!isEmpty(news) && !isLoading ? (
					<div className='news-page-content'>
						<Datatable
							data={news}
							columns={[
								...columns,
								{
									Header: '',
									accessor: 'id',
									Cell: ({ row, value: id }) => (
										<div className='news-actions-column-container'>
											<Button
												onClick={() => {
													this.setState({ articleToDelete: row.original })
													openDeleteModal()
												}}
												icon={() => <TrashIcon />}
												color='red'
											/>
											<Link to={`/brands/${row.original.brand.id}/news/${id}/edit`}>
												<Button title='Modifică' icon={() => <PencilSquareIcon />} iconLeft />
											</Link>
										</div>
									)
								}
							]}
						/>
					</div>
				) : isEmpty(news) && !isLoading ? (
					<div className='no-news-container'>
						<p className='no-news-text'>Nu există noutăți</p>
						<Link to='/add-news' className='add-news-link'>
							Adaugă noutăți
						</Link>
					</div>
				) : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	news: values(state.news.data),
	isLoading: state.news.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listNews: () => dispatch(RESOURCES.news.list()),
	openDeleteModal: () => dispatch(openModal(modalTypes.DELETE_ARTICLE))
})

export default connect(mapStateToProps, mapDispatchToProps)(News)
