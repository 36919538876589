import React, { useEffect, useState } from 'react'
import { DocumentIcon, EyeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { slice, isEmpty } from 'lodash'

import { lightFormat } from 'date-fns'

import { Button } from '../../../../components/Button'

import './ArticleCard.scss'

const filesBreakpoints = [1300, 1100, 900]

export default function ArticleCard({
	article: { id: articleID, title, created_at, brand, content, resources, image_url }
}) {
	const [onlyText, setOnlyText] = useState(window.outerWidth < filesBreakpoints[2] ? true : false)
	const [files, setFiles] = useState(
		window.outerWidth < filesBreakpoints[0] ? 2 : window.outerWidth < filesBreakpoints[1] ? 1 : 3
	)

	useEffect(() => {
		function handleResize() {
			const { outerWidth } = window

			if (outerWidth < filesBreakpoints[0] && outerWidth > filesBreakpoints[1]) {
				setOnlyText(false)
				setFiles(2)
			} else if (outerWidth < filesBreakpoints[1] && outerWidth > filesBreakpoints[2]) {
				setOnlyText(false)
				setFiles(1)
			} else if (outerWidth < filesBreakpoints[2]) {
				setOnlyText(true)
			} else {
				setOnlyText(false)
				setFiles(3)
			}
		}

		window.addEventListener('resize', handleResize)
	}, [])

	const firstFiles = slice(resources, 0, files)
	const remainingFiles = resources.length - firstFiles.length

	return (
		<div className='article-card-container'>
			<div className='article-left'>
				<img src={image_url} className='mobile-article-image' alt={title} />
				<p className='date'>{lightFormat(new Date(created_at), 'dd/MM/yyyy HH:mm')}</p>
				<p className='title'>{title}</p>
				<p className='content' dangerouslySetInnerHTML={{ __html: content }} />
				{!isEmpty(resources) && (
					<div className='resources-container'>
						{!onlyText ? (
							<>
								<div className='resources-left'>
									{firstFiles.map((file) => (
										<a
											href={file.preview_url}
											className='file-name-container'
											target='_blank'
											rel='noreferrer'
											key={file.name}
										>
											<div className='icon-container'>
												<DocumentIcon className='document-icon' />
											</div>
											<p className='file-name'>{file.name}</p>
										</a>
									))}
								</div>
								{remainingFiles > 0 && (
									<p className='remaining-resources'>
										... și încă
										<span>{remainingFiles}</span>
										fișiere
									</p>
								)}
							</>
						) : (
							<p className='remaining-resources'>
								<span>{resources.length}</span>resurse conectate
							</p>
						)}
					</div>
				)}
			</div>
			<div className='article-right'>
				<div className='article-image-container' style={{ backgroundImage: `url(${image_url})` }} />
				<Link to={`/brands/${brand.id}/news/${articleID}`}>
					<Button icon={() => <EyeIcon />} title='Vezi Articolul' size='large' iconLeft />
				</Link>
			</div>
		</div>
	)
}
