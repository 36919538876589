import React, { useEffect, useRef } from 'react'
import Dropzone from 'dropzone'

import { lightFormat } from 'date-fns'
import { isNull, head, values as _values } from 'lodash'

import { connect } from 'react-redux'

import { REACT_APP_API_URL } from '../../../../settings'
import { apiDateFormat } from '../../../../constants'

import './UploadDropzone.scss'

function UploadDropzone({ token, values, setFileStatus }) {
	const myDropzone = useRef()

	useEffect(() => {
		if (Dropzone.instances.length) {
			myDropzone.current = Dropzone.instances[0]
		} else {
			myDropzone.current = new Dropzone('#custom-dropzone', {
				url: REACT_APP_API_URL + 'resources/upload',
				chunking: true,
				method: 'POST',
				maxFilesize: 10 * 1024 * 1024 * 1024,
				chunkSize: 5 * 1024 * 1024,
				parallelChunkUploads: false,
				retryChunks: 0,
				headers: { Authorization: 'Bearer ' + token },
				parallelUploads: 3,
				disablePreviews: true,
				createImageThumbnails: false,
				autoProcessQueue: true
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (values && values.files) {
			myDropzone.current?.removeAllFiles()

			myDropzone.current?.on('error', function (file, response) {
				const progress = Math.round(file.upload?.progress)
				const error = head(head(_values(response.errors)))
				setFileStatus(file.name, { isLoading: false, progress, error })
			})

			myDropzone.current?.on('sending', function (file, xhr, formData) {
				formData.append('brand_id', values.brand.id)
				formData.append('category_id', values.category.id)
				formData.append('folder_id', values.folderID)
				formData.append('publish_date_diff', values.publishDateDiff)
				formData.append('publish_date', lightFormat(new Date(values.publishDate), apiDateFormat))
				formData.append('file_name', file.name)

				if (values.showInterestDates && !isNull(values.interestStart)) {
					formData.append('interest_start', lightFormat(new Date(values.interestStart), apiDateFormat))
				}

				if (values.showInterestDates && !isNull(values.interestStop)) {
					formData.append('interest_stop', lightFormat(new Date(values.interestStop), apiDateFormat))
				}

				xhr.onprogress = function (xhr) {
					const { status } = xhr.target
					if (status === 200 || status === 201) {
						const progress = Math.round(file.upload?.progress)
						setFileStatus(file.name, { isLoading: progress !== 100, progress })
					}
				}
			})
			myDropzone.current?.handleFiles(values.files)
			myDropzone.current?.emit('addedfiles', values.files)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values])

	return <div id='custom-dropzone'></div>
}

const mapStateToProps = (state) => ({
	token: state.users.token
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UploadDropzone)
