import { toast } from 'react-toastify'
import { put, take, takeEvery } from 'redux-saga/effects'
import { goBack } from 'connected-react-router'

import { keyBy, omit } from 'lodash'

import { Action } from '.'
import { RESOURCES } from './spec'
import { closeModal } from './modals'
import { getPages } from './utils'

const { resources: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	responses: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	currentResource: {},
	isLoading: false
}

const ACTIONS = {
	CREATE_RESOURCE_AND_WAIT_RESPONSE: new Action('CREATE_RESOURCE_AND_WAIT_RESPONSE'),
	ADD_RESPONSE: new Action('ADD_RESPONSE'),
	REMOVE_RESPONSE: new Action('REMOVE_RESPONSE'),
	RESET_RESPONSES: new Action('RESET_RESPONSES')
}

export const ResponseTypes = {
	SUCCESS: 'SUCCESS',
	FAIL: 'FAIL'
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				currentResource: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.ADD_RESPONSE.main:
			const { responseData } = action.payload

			return {
				...state,
				responses: {
					...state.responses,
					[responseData.fileName]: responseData
				}
			}
		case ACTIONS.REMOVE_RESPONSE.main:
			const { fileName } = action.payload

			return {
				...state,
				responses: omit(state.responses, fileName)
			}
		case ACTIONS.RESET_RESPONSES.main:
			return {
				...state,
				responses: {}
			}
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateResourceAndWaitResponse({ payload }) {
	const { resourceData, fileName } = payload

	yield put(RESOURCES.resources.create(resourceData))

	const createResourceResponse = yield take(
		(action) =>
			(action.type === RESOURCES.resources.actions.create.success && action.payload.data.name === fileName) ||
			(action.type === RESOURCES.resources.actions.create.fail && action.payload.filename === fileName)
	)

	if (createResourceResponse.type === RESOURCES.resources.actions.create.success) {
		yield put(
			addResponse({
				fileName: fileName,
				response: createResourceResponse.payload.data,
				responseType: ResponseTypes.SUCCESS
			})
		)
	} else if (createResourceResponse.type === RESOURCES.resources.actions.create.fail) {
		yield put(
			addResponse({
				fileName: fileName,
				response: createResourceResponse.payload.errors,
				responseType: ResponseTypes.FAIL
			})
		)
	}
}

function* handleUpdateResourceSuccess(response) {
	yield put(goBack())
	toast.success('Resursa a fost actualizată cu succes!')
}

function handleUpdateResourceFail() {
	toast.error('Resursa NU a putut fi actualizată!')
}

function* handleDestroyResourceSuccess() {
	yield put(closeModal())
	toast.success('Resursa a fost ștersă cu succes!')
}

export function* saga() {
	yield takeEvery(ACTIONS.CREATE_RESOURCE_AND_WAIT_RESPONSE.main, handleCreateResourceAndWaitResponse)
	yield takeEvery(resource.actions.update.success, handleUpdateResourceSuccess)
	yield takeEvery(resource.actions.update.fail, handleUpdateResourceFail)
	yield takeEvery(resource.actions.destroy.success, handleDestroyResourceSuccess)
}

export const createResourceAndWaitResponse = (resourceData, fileName) => ({
	type: ACTIONS.CREATE_RESOURCE_AND_WAIT_RESPONSE.main,
	payload: { resourceData: resourceData, fileName: fileName, headers: { 'content-type': 'multipart/form-data' } }
})

export const addResponse = (responseData) => ({
	type: ACTIONS.ADD_RESPONSE.main,
	payload: { responseData: responseData }
})

export const removeResponse = (fileName) => ({
	type: ACTIONS.REMOVE_RESPONSE.main,
	payload: { fileName: fileName }
})

export const resetResponses = () => ({
	type: ACTIONS.RESET_RESPONSES.main
})
