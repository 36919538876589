import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
	ArrowRightIcon,
	CogIcon,
	DocumentIcon,
	NewspaperIcon,
	ShoppingCartIcon,
	UsersIcon
} from '@heroicons/react/24/outline'

import { orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Loader } from '../../components/Loader'

import { BrandResourcesCard } from './partials'

import './Home.scss'

export class Home extends Component {
	componentDidMount() {
		const { listBrands } = this.props

		listBrands()
	}

	render() {
		const { brands, isLoading, user } = this.props

		return (
			<div className='home-container'>
				<div className='links-container'>
					{isLoading ? (
						<Loader />
					) : (
						<div className='brands-container'>
							{brands.map((brand) => (
								<BrandResourcesCard brand={brand} key={brand.id} />
							))}
						</div>
					)}
					{/* <Link to='/shop' className='brand-resource-card-container shop-card-container'>
						<div className='top' />
						<div className='bottom'>
							<div className='texts-container'>
								<p className='helper-text'>Comandă din</p>
								<p className='brand-name-text'>SHOP</p>
							</div>
							<div className='resource-link'>
								<ArrowRightIcon className='link-icon' />
							</div>
						</div>
					</Link> */}
				</div>
				<div className='shop-folder-container'>
					<Link to='/personal-folder' className='personal-folder-link'>
						<div className='left'>
							<DocumentIcon className='left-icon' />
							<p className='link-title'>
								Resurse din <br />
								FOLDER PERSONAL
							</p>
						</div>
						<div className='link-icon-container'>
							<ArrowRightIcon className='link-icon' />
						</div>
					</Link>

					<Link to='/shop' className='shop-link'>
						<div className='left'>
							<ShoppingCartIcon className='left-icon' />
							<p className='link-title'>
								Comandă din <br />
								SHOP
							</p>
						</div>
						<div className='link-icon-container'>
							<ArrowRightIcon className='link-icon' />
						</div>
					</Link>
				</div>
				{user.is_admin ? (
					<div className='admin-section-container'>
						<div className='admin-section-inner'>
							<Link to='/add-resources' className='admin-option-container'>
								<div className='left'>
									<DocumentIcon className='admin-option-icon' />
									<p className='admin-option-title'>
										Administrare <br />
										Resurse
									</p>
								</div>
								<CogIcon className='options-icon' />
							</Link>
							<Link to='/news' className='admin-option-container'>
								<div className='left'>
									<NewspaperIcon className='admin-option-icon' />
									<p className='admin-option-title'>
										Administrare <br />
										Noutăți
									</p>
								</div>
								<CogIcon className='options-icon' />
							</Link>
							<Link to='/orders' className='admin-option-container'>
								<div className='left'>
									<ShoppingCartIcon className='admin-option-icon' />
									<p className='admin-option-title'>
										Administrare <br />
										Shop
									</p>
								</div>
								<CogIcon className='options-icon' />
							</Link>
							<Link to='/users' className='admin-option-container'>
								<div className='left'>
									<UsersIcon className='admin-option-icon' />
									<p className='admin-option-title'>
										Administrare <br />
										Utilizatori
									</p>
								</div>
								<CogIcon className='options-icon' />
							</Link>
						</div>
					</div>
				) : (
					<div className='placeholder-div' />
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	brands: orderBy(state.brands.data, ['order', 'name'], 'asc'),
	isLoading: state.brands.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
