import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
	email: Yup.string()
		.label('Adresă de email')
		.email('Adresa de email nu e validă!')
		.required('Adresa de email este obligatorie!'),
	password: Yup.string()
		.label('Parola')
		// .min(3, 'Parola trebuie să conţină cel puţin 3 caractere!')
		.required('Parola este obligatorie!'),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref('password'), ''], 'Parolele nu conincid!')
		.required('Confirmarea parolei e obligatorie!')
})
