import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

import './MyCart.scss'

export function Cart({ cart }) {
	return (
		<Link to='/cart' className='my-cart-container'>
			<div className='shoppintg-cart-icon-container'>
				<ShoppingCartIcon className='shopping-cart-icon' />
			</div>
			<div className='texts-container'>
				<div className='my-cart-text-container'>
					<p className='my-cart-text'>Coșul meu</p>
					<div className='cart-items-container'>
						<p className='cart-items-text'>{cart.products?.length || 0}</p>
					</div>
				</div>
				{cart.sale_total && (
					<div className='price-container'>
						<div className='price-text'>{cart.sale_total?.toLocaleString()} RON</div>
					</div>
				)}
			</div>
		</Link>
	)
}

const mapStateToProps = (state) => ({
	cart: state.cart.data
})

export default connect(mapStateToProps)(Cart)
