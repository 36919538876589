import React, { Component } from 'react'
import { MagnifyingGlassIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { values, debounce, isNull, isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { shopAdminSidebarItems } from '../../utils'

import { columns } from './constants'

import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { Sidebar } from '../../components/Sidebar'
import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Loader } from '../../components/Loader'

import { DeleteProductModal } from './partials'

import './Products.scss'

export class Products extends Component {
	constructor() {
		super()

		this.state = {
			selectedProduct: {},
			productQuery: '',
			brand: null,
			productCategory: null
		}
	}

	componentDidMount() {
		const { listBrands, listProducts } = this.props

		listBrands()
		listProducts()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { brand, productCategory } = this.state
		const { isLoadingBrands, isLoadingProductCategories, listProductCategories } = this.props

		if (!isNull(brand) && brand !== prevState.brand && !isLoadingProductCategories) {
			listProductCategories(brand.id)
		}

		if (
			(brand !== prevState.brand && !isLoadingBrands) ||
			(productCategory !== prevState.productCategory && !isLoadingProductCategories)
		) {
			this.handleListProducts()
		}
	}

	handleListProducts = (
		search = this.state.productQuery,
		page = 1,
		brand = this.state.brand,
		productCategory = this.state.productCategory
	) => {
		const { listProducts, isLoading } = this.props

		let params = {}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (page >= 1) {
			params = { ...params, page }
		}

		if (!isNull(brand)) {
			params = { ...params, brand_id: brand.id }
		}

		if (!isNull(productCategory)) {
			params = { ...params, category_id: productCategory.id }
		}

		if (!isLoading) {
			listProducts(params)
		}
	}

	debounceSearch = debounce((query) => this.handleListProducts(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ productQuery: query })
		this.debounceSearch(query)
	}

	render() {
		const { selectedProduct, productQuery, brand, productCategory } = this.state
		const {
			products,
			isLoading,
			pages,
			brands,
			isLoadingBrands,
			productCategories,
			isLoadingProductCategories,
			openModal
		} = this.props

		return (
			<div className='products-page-container'>
				<Sidebar items={shopAdminSidebarItems} />
				<DeleteProductModal product={selectedProduct} />
				<div className='products-page-content-container'>
					<div className='products-page-header-container'>
						<PageHeader pageTitle='Produse Shop' parentRoute='/home' disableShadow />
						{!isLoading ? (
							<Link to='/add-product' className='add-link-button'>
								<Button
									title='Adaugă Produs'
									icon={() => <PlusIcon />}
									onClick={() => openModal(modalTypes.ADD_PRODUCT)}
									size='large'
									iconLeft
								/>
							</Link>
						) : (
							<Loader />
						)}
					</div>
					<div className='filters-container'>
						<div className='filters-left'>
							<Input
								value={productQuery}
								placeholder='Caută după nume'
								onChange={(e) => this.handleChangeSearch(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
								size='large'
							/>
						</div>
						<div className='filters-right'>
							<Select
								label='Brand'
								value={brand}
								options={brands}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => this.setState({ brand: option, productCategory: null })}
								disabled={isLoadingBrands}
								labelInside
								isClearable
								fullWidth
							/>
							<Select
								label='Categorie'
								value={productCategory}
								options={productCategories}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => this.setState({ productCategory: option })}
								disabled={isNull(brand) || isLoadingProductCategories}
								labelInside
								isClearable
								fullWidth
							/>
						</div>
					</div>
					{!isLoading && (
						<div className='products-page-content'>
							<Datatable
								data={products}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'id',
										Cell: ({ row, value: productID }) => (
											<div className='buttons-row'>
												<Button
													icon={() => <TrashIcon />}
													onClick={() => {
														this.setState({ selectedProduct: row.original })
														openModal(modalTypes.DELETE_PRODUCT)
													}}
													color='red'
													size='small'
													iconLeft
												/>
												<Link to={`/products/${productID}/edit`}>
													<Button
														title='Modifică'
														icon={() => <PencilIcon />}
														size='small'
														iconLeft
													/>
												</Link>
											</div>
										)
									}
								]}
								hasNextPage={!isNull(pages.next)}
								loadMoreText='Încarcă mai multe produse'
								onLoadMore={() => this.handleListProducts(productQuery, pages.next)}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	products: values(state.products.data),
	isLoading: state.products.isLoading,
	pages: state.products.pages,
	brands: values(state.brands.data),
	isLoadingBrands: state.brands.isLoading,
	productCategories: values(state.productCategories.data),
	isLoadingProductCategories: state.productCategories.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	openModal: (type) => dispatch(openModal(type)),
	listProducts: (params) => dispatch(RESOURCES.products.list(params)),
	listProductCategories: (brandID) => dispatch(RESOURCES.productCategories.list({ brand_id: brandID })),
	listBrands: () => dispatch(RESOURCES.brands.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
