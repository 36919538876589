import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { keyBy, omit } from 'lodash'

import { closeModal } from './modals'
import { RESOURCES } from './spec'
import { getPages } from './utils'

const { products: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	currentProduct: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	isLoading: false,
	isGettingFirstPage: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.main:
			currentPendingRequests = state.pendingRequests + 1

			const isGettingFirstPage = !action.payload.request.params.page ? true : false

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0,
				isGettingFirstPage: isGettingFirstPage
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			const order = state.data[action.payload.data.id]?.order
			// if(action.meta?.callback && typeof action.meta.callback === 'function') {
			// 	action.meta.callback()
			// }
			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: {
						...action.payload.data,
						order
					}
				},
				currentProduct: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateProductSuccess() {
	yield(toast.success('Produsul a fost adăugat cu succes!'))
	// yield put(push('/products'))
}

function* handleDestroyProductSuccess() {
	toast.success('Produsul a fost șters cu succes!')
	yield put(closeModal())
}

function* handleUpdateProductSuccess() {
	yield(toast.success('Modificările au fost salvate!'))
	// yield put(push('/products'))
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateProductSuccess)
	yield takeEvery(resource.actions.update.success, handleUpdateProductSuccess)
	yield takeEvery(resource.actions.destroy.success, handleDestroyProductSuccess)
}
