export const columns = [
	{
		Header: 'Nume categorie',
		accessor: 'name'
	},
	{
		Header: 'Discount shop',
		accessor: 'discount',
		Cell: ({ value: discount }) => `${discount}%`
	},
	{
		Header: 'Zile prioritare acces',
		accessor: 'days_before_access',
		Cell: ({ value: days_before_access }) => `${days_before_access}  ${days_before_access === 1 ? 'zi' : 'zile'}`
	}
]
