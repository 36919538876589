import React from 'react'
import { useTable, useSortBy } from 'react-table'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid'

import { sortingDirections } from '../../types'

import { Button } from '../Button'

import './Datatable.scss'

export default function Table({ columns, data, sortable, hasNextPage, loadMoreText, onLoadMore, onHeaderClick }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data, disableSortBy: !sortable },
		useSortBy
	)

	return (
		<div className='px-4 sm:px-6 lg:px-8'>
			<div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-auto'>
				<div className='inline-block min-w-full py-2 align-middle'>
					<div className='edy-optic-table-container'>
						<table className='edy-optic-table' {...getTableProps()}>
							<thead className='edy-optic-table-head'>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => {
													if (sortable && !column.disableSortBy) {
														onHeaderClick(column)
													}
												}}
												className='edy-optic-th'
												scope='col'
											>
												<div className='edy-optic-table-th-content-container'>
													{column.render('Header')}
													<div className='sorting-icon-container'>
														{column.sortDirection === sortingDirections.ASC ? (
															<ArrowUpIcon />
														) : column.sortDirection === sortingDirections.DESC ? (
															<ArrowDownIcon />
														) : null}
													</div>
												</div>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className='edy-optic-table-body'>
								{rows.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()} className='edy-optic-tr'>
											{row.cells.map((cell, idx) => {
												return (
													<td {...cell.getCellProps()} className='edy-optic-td'>
														{cell.render('Cell')}
													</td>
												)
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					{hasNextPage && (
						<div className='load-more-content-container'>
							<Button
								title={loadMoreText || 'Încarcă mai multe'}
								icon={() => <ArrowDownIcon />}
								variant='outlined'
								onClick={onLoadMore}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
