import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'

import { Button } from '../Button'

import './PageHeader.scss'

export function PageHeader({ pageTitle, parentRoute, disableShadow, goBack }) {
	return (
		<div className={`page-header-container ${disableShadow ? 'shadow-none' : 'shadow-sm'}`}>
			<div className='page-header-left'>
				{!isEmpty(parentRoute) ? (
					<Link to={parentRoute}>
						<Button
							icon={() => <ArrowLeftIcon className='back-button-icon' />}
							size='large'
							color='primaryLight'
							className='back-button'
						/>
					</Link>
				) : (
					<Button
						onClick={goBack}
						icon={() => <ArrowLeftIcon className='back-button-icon' />}
						size='large'
						color='primaryLight'
						className='back-button'
					/>
				)}
				<p className='page-title'>{pageTitle}</p>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	goBack: () => dispatch(goBack())
})

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
