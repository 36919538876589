import * as qs from 'qs'
import axios from 'axios'
import { toast } from 'react-toastify'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { forEach, has } from 'lodash'

import { Action, ERROR_SUFFIX, SUCCESS_SUFFIX } from './index'
import { handleUserUnauthenticated } from './auth'

import { REACT_APP_API_URL } from '../settings'

const ACTIONS = {
	REQUEST_STARTED: new Action('REQUEST_STARTED'),
	REQUEST_FINISHED: new Action('REQUEST_FINISHED'),
	API_IS_OFFLINE: new Action('API_IS_OFFLINE'),
	RELOAD: new Action('RELOAD')
}

const initialState = {
	pendingRequests: 0,
	isLoading: false,
	isOffline: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests

	switch (action.type) {
		case ACTIONS.REQUEST_STARTED.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}

		case ACTIONS.REQUEST_FINISHED.main:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0,
				isOffline: false
			}
		case ACTIONS.API_IS_OFFLINE.main:
			return {
				...state,
				isOffline: true
			}
		default:
			return state
	}
}

export const client = axios.create({
	baseURL: REACT_APP_API_URL,
	responseType: 'json',
	withCredentials: true,
	// headers: {
	// 	'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json'
	// },
	paramsSerializer: function (params) {
		return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
	}
})

client.interceptors.request.use(
	(config) => {
		// store.dispatch({type: ACTIONS.REQUEST_STARTED.main})
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

client.interceptors.response.use(
	(response) => {
		// store.dispatch({type: ACTIONS.REQUEST_FINISHED.main})
		return response
	},
	(error) => {
		// store.dispatch({type: ACTIONS.REQUEST_FINISHED.main})
		return Promise.reject(error)
	}
)

export function setHeaders(headers) {
	forEach(headers, (value, name) => {
		client.defaults.headers.common[name] = value
	})
}

function handleReload() {
	console.log('Reloading...')
}

function* handleRequest(action) {
	const {
		type,
		payload: { request },
		meta
	} = action
	const url = request.url
	const method = request.method ? request.method : 'GET'
	const data = request.data ? request.data : null
	let params = request.params ? request.params : {}
	let headers = request.headers ? request.headers : {}

	const userToken = yield select((state) => state.users?.token)

	if (userToken) {
		client.defaults.headers['Authorization'] = `Bearer ${userToken}`
	}

	try {
		const response = yield call(client, url, { params, method, data, headers })

		yield put({
			type: type + SUCCESS_SUFFIX,
			payload: response.data,
			status: response.status,
			meta
		})
	} catch (errorResponse) {
		console.log('API error', errorResponse)

		if (!errorResponse.response) {
			console.log('It should be a network error.')

			yield put({
				type: ACTIONS.API_IS_OFFLINE.main,
				meta
			})
		} else {
			yield put({
				type: type + ERROR_SUFFIX,
				payload: errorResponse.response.data,
				status: errorResponse.response.status,
				meta
			})

			if (errorResponse.response.status === 401) {
				yield handleUserUnauthenticated()
			}

			if (errorResponse.response.status === 403) {
				toast.error(errorResponse.response.data.message)
				// yield put(push('/home'))
			}
		}
	}
}

export function* saga() {
	yield takeEvery((action) => has(action.payload, 'request'), handleRequest)
	yield takeEvery(ACTIONS.RELOAD.main, handleReload)
}

export const reload = () => ({
	type: ACTIONS.RELOAD.main
})
