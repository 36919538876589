import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
	lastName: Yup.string().label('Numele').required('Numele este obligatoriu!'),
	firstName: Yup.string().label('Prenume').required('Prenumele este obligatoriu!'),
	email: Yup.string()
		.label('Adresă de email')
		.email('Adresa de email nu e validă!')
		.required('Adresa de email este obligatorie!'),
	category: Yup.object().label('Categorie').required('Categoria este obligatorie!').nullable()
})
