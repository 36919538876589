import React, { Component } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { differenceInDays } from 'date-fns/esm'
import { toUpper, isEmpty, isNull, debounce, orderBy } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'

import { Input } from '../../components/Input'
import { PageHeader } from '../../components/PageHeader'
import LoadMoreComponent from '../../components/LoadMoreComponent/LoadMoreComponent'
import { Loader } from '../../components/Loader'

import { RecentResources, ArticleCard } from './partials'

import './BrandNews.scss'

export class BrandNews extends Component {
	constructor() {
		super()

		this.state = {
			searchQuery: ''
		}
	}

	componentDidMount() {
		const {
			retrieveBrand,
			match: { params }
		} = this.props

		const { brandID } = params

		this.handleListNews()
		retrieveBrand(brandID)
	}

	handleListNews = (search = this.state.searchQuery, page = 1) => {
		const {
			isLoading,
			listNews,
			match: { params }
		} = this.props

		const { brandID } = params

		let reqParams = { brand_id: brandID, page: page }

		if (!isEmpty(search)) {
			reqParams = { ...params, search }
		}

		if (!isLoading) {
			listNews(reqParams)
		}
	}

	debounceSearch = debounce((query) => this.handleListNews(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ searchQuery: query })
		this.debounceSearch(query)
	}

	render() {
		const { searchQuery } = this.state
		const {
			user,
			brand,
			news,
			isLoading,
			isGettingFirstPage,
			pages,
			match: { params }
		} = this.props

		const { brandID } = params

		return (
			<div className='brand-news-page-container'>
				<div className='brand-news-page-left'>
					<PageHeader
						pageTitle={`Noutăți ${toUpper(brand.name)}`}
						parentRoute={`/brands/${brand.id}`}
						disableShadow
					/>
					<div className='search-container'>
						<Input
							value={searchQuery}
							onChange={(e) => this.handleChangeSearch(e.target.value)}
							placeholder='Caută noutate'
							icon={() => <MagnifyingGlassIcon />}
							fullWidth
						/>
					</div>
					<div className='brand-news-container'>
						{!isEmpty(news) && (!isLoading || (isLoading && !isGettingFirstPage)) ? (
							<>
								{news.map((article) => (
									<ArticleCard article={article} key={article.id} />
								))}
								{!isNull(pages.next) && (
									<LoadMoreComponent
										loadingMoreText='Se încarcă mai multe noutăți...'
										isLoading={isLoading}
										onLoadMore={() => this.handleListNews(searchQuery, pages.next)}
									/>
								)}
							</>
						) : isLoading ? (
							<div className='loader-container'>
								<Loader />
							</div>
						) : isEmpty(news) ? (
							<div className='no-news-container'>
								<p className='no-news-text'>Nu există noutăți</p>
								{user.is_admin && (
									<Link to='/add-news' className='add-news-link'>
										Adaugă noutăți
									</Link>
								)}
							</div>
						) : null}
					</div>
				</div>
				<div className='brand-news-page-right'>
					<RecentResources brandID={brandID} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const orderedNews = orderBy(state.news.data, (art) => differenceInDays(new Date(art.created), new Date()), 'desc')

	return {
		user: state.users.user,
		brand: state.brands.currentBrand,
		news: orderedNews,
		isLoading: state.news.isLoading,
		isGettingFirstPage: state.news.isGettingFirstPage,
		pages: state.news.pages
	}
}

const mapDispatchToProps = (dispatch) => ({
	retrieveBrand: (brandID) => dispatch(RESOURCES.brands.retrieve(brandID)),
	listNews: (params) => dispatch(RESOURCES.news.list(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandNews)
