import React, { Component } from 'react'
import { MagnifyingGlassIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { orderBy, debounce, isNull, isEmpty, values } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'

import { shopAdminSidebarItems } from '../../utils'

import { columns } from './constants'

import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { Sidebar } from '../../components/Sidebar'
import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'

import { AddProductCategoryModal, EditProductCategoryModal, DeleteProductCategoryModal } from './partials'

import './ProductCategories.scss'
import { Input } from '../../components/Input'
import { Select } from '../../components/Select'

export class ProductCategories extends Component {
	constructor() {
		super()

		this.state = {
			selectedProductCategory: {},
			categoryQuery: '',
			brand: null,
		}
	}

	componentDidMount() {
		const { listProductCategories, listBrands } = this.props

		listBrands()
		listProductCategories()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { brand } = this.state
		const { isLoadingBrands } = this.props

		if (brand !== prevState.brand && !isLoadingBrands) {
			this.handleListProductCategories()
		}
	}

	handleListProductCategories = (
		search = this.state.categoryQuery,
		page = 1,
		brand = this.state.brand
	) => {
		const { listProductCategories, isLoading } = this.props

		let params = {}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (page >= 1) {
			params = { ...params, page }
		}

		if (!isNull(brand)) {
			params = { ...params, brand_id: brand.id }
		}

		if (!isLoading) {
			listProductCategories(params)
		}
	}

	debounceSearch = debounce((query) => this.handleListProductCategories(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ categoryQuery: query })
		this.debounceSearch(query)
	}

	render() {
		const { selectedProductCategory, categoryQuery, brand } = this.state
		const { productCategories, isLoading, openModal, isLoadingBrands, brands } = this.props

		return (
			<div className='product-categories-page-container'>
				<Sidebar items={shopAdminSidebarItems} />
				<AddProductCategoryModal />
				<EditProductCategoryModal productCategory={selectedProductCategory} />
				<DeleteProductCategoryModal productCategory={selectedProductCategory} />
				<div className='product-categories-page-content-container'>
					<div className='product-categories-page-header-container'>
						<PageHeader pageTitle='Categorii Produse' parentRoute='/home' disableShadow />
						{!isLoading ? (
							<Button
								title='Adaugă Categorie'
								icon={() => <PlusIcon />}
								onClick={() => openModal(modalTypes.ADD_PRODUCT_CATEGORY)}
								className='add-button'
								size='large'
								iconLeft
							/>
						) : (
							<Loader />
						)}
					</div>
					<div className='filters-container'>
						<div className='filters-left'>
							<Input
								value={categoryQuery}
								placeholder='Caută după nume'
								onChange={(e) => this.handleChangeSearch(e.target.value)}
								icon={() => <MagnifyingGlassIcon />}
								fullWidth
								size='large'
							/>
						</div>
						<div className='filters-right'>
							<Select
								label='Brand'
								value={brand}
								options={brands}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								onChange={(option) => this.setState({ brand: option, productCategory: null })}
								disabled={isLoadingBrands}
								labelInside
								isClearable
								fullWidth
							/>
						</div>
					</div>
					{!isLoading && (
						<div className='product-categories-page-content'>
							<Datatable
								data={productCategories}
								columns={[
									...columns,
									{
										Header: '',
										accessor: 'id',
										Cell: ({ row }) => (
											<div className='buttons-row'>
												<Button
													icon={() => <TrashIcon />}
													onClick={() => {
														this.setState({ selectedProductCategory: row.original })
														openModal(modalTypes.DELETE_PRODUCT_CATEGORY)
													}}
													color='red'
													size='small'
													iconLeft
												/>
												<Button
													title='Modifică'
													icon={() => <PencilIcon />}
													onClick={() => {
														this.setState({ selectedProductCategory: row.original })
														openModal(modalTypes.EDIT_PRODUCT_CATEGORY)
													}}
													size='small'
													iconLeft
												/>
											</div>
										)
									}
								]}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	productCategories: orderBy(state.productCategories.data, ['order', 'id'], 'asc'),
	isLoading: state.productCategories.isLoading,
	brands: values(state.brands.data),
	pages: state.products.pages,
	isLoadingBrands: state.brands.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
	listProductCategories: (params) => dispatch(RESOURCES.productCategories.list(params)),
	openModal: (type) => dispatch(openModal(type)),
	listBrands: () => dispatch(RESOURCES.brands.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories)
