import React, { Component } from 'react'
import {
	DocumentIcon,
	ArrowDownTrayIcon,
	FolderIcon,
	PencilSquareIcon,
	MagnifyingGlassIcon,
	TrashIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { lightFormat, parseISO } from 'date-fns'
import { isEmpty, values, concat, orderBy, toString, debounce, toUpper, isNull } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { toastError } from '../../redux/utils'
import { modalTypes, openModal } from '../../redux/modals'
import { resetResponses } from '../../redux/resources'
import { getFolder } from '../../redux/folders'

import { resourceTypes } from './constants'
import { sortingDirections } from '../../types'
import { formatBytes, navigate } from '../../utils'

import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { Datatable } from '../../components/Datatable'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'
import { FileTypeBadge } from '../../components/FileTypeBadge'
import { Loader } from '../../components/Loader'

import { DeleteResourceModal } from './partials'

import './BrandResource.scss'

export class BrandResource extends Component {
	constructor() {
		super()

		const query = new URLSearchParams(window.location.search)
		const accessor = query.get('orderBy') ? query.get('orderBy') : null
		const direction = query.get('order') ? query.get('order') : null

		this.state = {
			category: {},
			datatableData: [],
			resourceToDelete: {},
			resourceQuery: '',
			sort: { direction, accessor }
		}
	}

	componentDidMount = () => {
		const {
			resetResponses,
			getFolder,
			retrieveResourceCategory,
			match: { params }
		} = this.props

		const { resourceCategoryID, folderID } = params

		retrieveResourceCategory(resourceCategoryID)

		this.handleListResources()
		getFolder(resourceCategoryID, folderID)
		resetResponses()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { sort } = this.state
		const {
			folders,
			currentParentID,
			isLoadingFolders,
			getFolder,
			foldersStatus,
			foldersMessage,
			resources,
			isLoadingResources,
			match: { params }
		} = this.props

		const { resourceCategoryID, folderID } = params

		if (foldersStatus === 404) {
			toastError(foldersMessage)

			navigate('/home')
		}

		if (
			(sort.accesor !== prevState.sort.accesor || sort.direction !== prevState.sort.direction) &&
			!isLoadingResources
		) {
			this.handleListResources()
		}

		if (toString(folderID) !== toString(currentParentID) && !isLoadingFolders) {
			this.handleListResources()
			getFolder(resourceCategoryID, folderID)
		}

		if (
			(folders !== prevProps.folders && !isLoadingFolders) ||
			(resources !== prevProps.resources && !isLoadingResources)
		) {
			this.getDatatableData()
		}
	}

	handleListResources = (search = this.state.resourceQuery) => {
		const { sort } = this.state
		const {
			listResources,
			isLoading,
			isLoadingResources,
			match: { params: pageParams }
		} = this.props
		const { brandID, resourceCategoryID, folderID } = pageParams

		let params = {
			brand_id: brandID,
			category_id: resourceCategoryID,
			folder_id: folderID,
			order_by: sort.accessor,
			order: sort.direction
		}

		if (!isEmpty(search)) {
			params = { ...params, search }
		}

		if (!isLoading && !isLoadingResources) {
			listResources(params)
		}
	}

	debounceSearch = debounce((query) => this.handleListResources(query), 300)

	handleChangeSearch = (query) => {
		this.setState({ resourceQuery: query })
		this.debounceSearch(query)
	}

	getDatatableData = () => {
		const { sort } = this.state
		const { resources, folders } = this.props

		let datatableFolders = orderBy(
			folders.map((folder) => ({
				id: folder.id,
				name: folder.name,
				type: resourceTypes.FOLDER,
				extension: 'folder',
				size: folder.resources_count
					? folder.resources_count + (folder.resources_count === 1 ? ' resursă' : ' resurse')
					: '0 resurse'
			})),
			sort.accessor,
			sort.direction
		)

		let resourcesWithType = orderBy(
			resources.map((res) => ({ ...res, type: resourceTypes.DOCUMENT })),
			sort.accessor,
			sort.direction
		)

		this.setState({ datatableData: concat(datatableFolders, resourcesWithType) })
	}

	columnHeaderClick = (column) => {
		const { sort } = this.state

		if (sort.accessor === column.id) {
			switch (sort.direction) {
				case sortingDirections.ASC:
					this.handleChangeSorting(column.id, sortingDirections.DESC)
					break
				case sortingDirections.DESC:
					this.handleChangeSorting(null, null)
					break
				default:
					this.handleChangeSorting(column.id, sortingDirections.ASC)
					break
			}
		} else {
			this.handleChangeSorting(column.id, sortingDirections.ASC)
		}
	}

	handleChangeSorting = (accessor, direction) => {
		const { location, history } = this.props

		this.setState({ sort: { accessor: accessor, direction: direction } })

		let searchParams = new URLSearchParams(location.search)

		if (!isNull(accessor) && !isNull(direction)) {
			searchParams.set('orderBy', accessor)
			searchParams.set('order', direction)
		} else {
			searchParams.delete('orderBy')
			searchParams.delete('order')
		}

		history.push({
			pathname: location.pathname,
			search: searchParams.toString()
		})
	}

	render() {
		const { datatableData, resourceToDelete, resourceQuery, sort } = this.state
		const {
			user,
			category,
			isLoadingResources,
			isLoadingFolders,
			openDeleteModal,
			currentFolder,
			match: {
				params: { brandID, resourceCategoryID }
			}
		} = this.props

		// const getParentRoute = () => {
		// 	if (toString(category?.folder?.parent_id) === toString(currentFolder?.parent_id))
		// 		return `/brands/${brandID}/resource-categories`
		// 	if (currentFolder?.parent_id)
		// 		return `/brands/${brandID}/resource-categories/${resourceCategoryID}/${currentFolder?.parent_id}`
		// 	return `/brands/${brandID}`
		// }

		return (
			<>
				{!isEmpty(category) && !isLoadingFolders ? (
					<div className='brand-resource-details-container'>
						<div className='brand-resource-page-header-container'>
							<PageHeader
								pageTitle={
									currentFolder ? currentFolder.path : `${category?.brand?.name} / ${category?.name}`
								}
								disableShadow
								// parentRoute={getParentRoute()}
							/>
							{isLoadingResources && <Loader />}
						</div>
						<DeleteResourceModal resource={resourceToDelete} />
						{!isEmpty(datatableData) ? (
							<>
								<div className='search-container'>
									<Input
										value={resourceQuery}
										placeholder='Caută după nume'
										onChange={(e) => this.handleChangeSearch(e.target.value)}
										icon={() => <MagnifyingGlassIcon />}
										fullWidth
									/>
								</div>
								<div className='brand-resource-details-content'>
									<Datatable
										data={!isLoadingResources ? datatableData : []}
										sortable
										onHeaderClick={(col) => this.columnHeaderClick(col)}
										columns={[
											{
												Header: 'Nume',
												accessor: 'name',
												sortDirection: sort.accessor === 'name' ? sort.direction : null,
												Cell: ({ value: name, row: { original: resource } }) =>
													resource.type === resourceTypes.DOCUMENT ? (
														<a
															href={resource.preview_url}
															className='name-icon-cell-container'
															target='_blank'
															rel='noreferrer'
														>
															<div className='icon-container'>
																<DocumentIcon className='icon' />
															</div>
															<p className='name'>{name}</p>
														</a>
													) : (
														<Link
															to={`/brands/${brandID}/resource-categories/${resourceCategoryID}/${resource.id}`}
															className='name-icon-cell-container'
														>
															<div className='icon-container'>
																<FolderIcon className='icon' />
															</div>
															<p className='name'>{name}</p>
														</Link>
													)
											},
											{
												Header: 'Tip',
												accessor: 'extension',
												disableSortBy: true,
												Cell: ({ value: extension }) =>
													extension ? (
														<div className='file-type-badge-cell-container'>
															<FileTypeBadge fileType={toUpper(extension)} />
														</div>
													) : (
														''
													)
											},
											{
												Header: 'Dimensiune',
												accessor: 'size',
												sortDirection: sort.accessor === 'size' ? sort.direction : null,
												Cell: ({ value: size }) =>
													typeof size == 'number' ? formatBytes(size) : size
											},
											{
												Header: 'Dată postare',
												accessor: 'publish_date',
												sortDirection: sort.accessor === 'publish_date' ? sort.direction : null,
												Cell: ({ value: publish_date }) =>
													publish_date
														? lightFormat(new Date(parseISO(publish_date)), 'dd/MM/yyyy')
														: ''
											},
											{
												Header: 'De interes până la',
												accessor: 'interest_stop',
												sortDirection:
													sort.accessor === 'interest_stop' ? sort.direction : null,
												Cell: ({ value: interest_stop }) =>
													interest_stop
														? lightFormat(new Date(parseISO(interest_stop)), 'dd/MM/yyyy')
														: ''
											},
											{
												Header: '',
												accessor: 'id',
												disableSortBy: true,
												Cell: ({ row: { original: resource }, value: id }) => (
													<div className='brand-resources-actions-column-container'>
														{resource.type !== resourceTypes.FOLDER && (
															<>
																<a
																	href={resource.preview_url}
																	target='_blank'
																	rel='noreferrer'
																>
																	<Button title='Vizualizează' color='white' />
																</a>
																<a
																	href={resource.download_url}
																	target='_blank'
																	rel='noreferrer'
																>
																	<Button
																		title='Descarcă'
																		icon={() => <ArrowDownTrayIcon />}
																		color='secondary'
																	/>
																</a>
																{user.is_admin && (
																	<>
																		<Button
																			onClick={() => {
																				this.setState({
																					resourceToDelete: resource
																				})
																				openDeleteModal()
																			}}
																			icon={() => <TrashIcon />}
																			color='red'
																		/>
																		<Link to={`/resources/${id}/edit`}>
																			<Button
																				title='Modifică'
																				icon={() => <PencilSquareIcon />}
																				iconLeft
																			/>
																		</Link>
																	</>
																)}
															</>
														)}
													</div>
												)
											}
										]}
									/>
								</div>
							</>
						) : !isLoadingResources ? (
							<div className='no-resources-container'>
								<p className='no-resources-text'>{category.catalog ? 'Nu există cataloage.' : 'Nu există resurse în acest folder.'}</p>
								{user.is_admin && !category.catalog && (
									<Link to='/add-resources' className='add-resources-link'>
										Adaugă resurse
									</Link>
								)}
							</div>
						) : null}
					</div>
				) : (
					<PageLoader />
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	category: state.resourceCategories.currentResourceCategory,
	resources: values(state.resources.data),
	isLoadingResources: state.resources.isLoading,
	folders: values(state.folders.data),
	foldersStatus: state.folders.status,
	foldersMessage: state.folders.message,
	currentParentID: state.folders.currentParentID,
	currentFolder: state.folders.currentFolder,
	isLoadingFolders: state.folders.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	retrieveResourceCategory: (resourceCategoryID) =>
		dispatch(RESOURCES.resourceCategories.retrieve(resourceCategoryID)),
	listResources: (params) => dispatch(RESOURCES.resources.list(params)),
	getFolder: (resourceCategoryID, params) => dispatch(getFolder(resourceCategoryID, params)),
	openDeleteModal: () => dispatch(openModal(modalTypes.DELETE_RESOURCE)),
	resetResponses: () => dispatch(resetResponses())
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandResource)
