export const columns = [
	{
		Header: 'Nume',
		accessor: 'last_name'
	},
	{
		Header: 'Prenume',
		accessor: 'first_name'
	},
	{
		Header: 'Adresă email',
		accessor: 'email'
	},
	{
		Header: 'Categorie',
		accessor: 'category',
		Cell: ({ value: category }) => (category ? category.name : '-')
	}
]
