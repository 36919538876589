import { toast } from 'react-toastify'
import { put, takeEvery } from 'redux-saga/effects'
import URITemplate from 'urijs/src/URITemplate'

import { keyBy, omit } from 'lodash'

import { APIAction } from '.'
import { FOLDERS_ENDPOINTS } from './spec'
import { closeModal } from './modals'
import { cleanURL } from './utils'

const ACTIONS = {
	CREATE_RESOURCE_CATEGORY_FOLDER: new APIAction('CREATE_RESOURCE_CATEGORY_FOLDER'),
	LIST_RESOURCE_CATEGORY_FOLDERS: new APIAction('LIST_RESOURCE_CATEGORY_FOLDERS'),
	GET_RESOURCE_CATEGORY_FOLDER: new APIAction('GET_RESOURCE_CATEGORY_FOLDER'),
	UPDATE_RESOURCE_CATEGORY_FOLDER: new APIAction('UPDATE_RESOURCE_CATEGORY_FOLDER'),
	MOVE_RESOURCE_CATEGORY_FOLDER: new APIAction('MOVE_RESOURCE_CATEGORY_FOLDER'),
	DESTROY_RESOURCE_CATEGORY_FOLDER: new APIAction('DESTROY_RESOURCE_CATEGORY_FOLDER')
}

const initialState = {
	pendingRequests: 0,
	errors: {},
	status: null,
	message: '',
	data: {},
	isLoading: false,
	currentParentID: null,
	currentFolder: null
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case ACTIONS.CREATE_RESOURCE_CATEGORY_FOLDER.main:
		case ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.main:
		case ACTIONS.UPDATE_RESOURCE_CATEGORY_FOLDER.main:
		case ACTIONS.GET_RESOURCE_CATEGORY_FOLDER.main:
		case ACTIONS.DESTROY_RESOURCE_CATEGORY_FOLDER.main:
		case ACTIONS.MOVE_RESOURCE_CATEGORY_FOLDER.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.success:
			currentPendingRequests = state.pendingRequests - 1

			const resultsByKey = keyBy(action.payload.data, 'id')

			return {
				...state,
				errors: {},
				data: resultsByKey,
				currentParentID: action.meta.object.parent_id || null,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.GET_RESOURCE_CATEGORY_FOLDER.success:
			currentPendingRequests = state.pendingRequests - 1

			const childrenByKey = keyBy(action.payload.data.children, 'id')

			return {
				...state,
				errors: {},
				data: childrenByKey,
				currentParentID: action.payload.data.id || null,
				currentFolder: action.payload.data,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.CREATE_RESOURCE_CATEGORY_FOLDER.success:
		case ACTIONS.UPDATE_RESOURCE_CATEGORY_FOLDER.success:
		case ACTIONS.MOVE_RESOURCE_CATEGORY_FOLDER.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				currentFolder: action.payload.data,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case ACTIONS.DESTROY_RESOURCE_CATEGORY_FOLDER.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}

		case ACTIONS.CREATE_RESOURCE_CATEGORY_FOLDER.fail:
		case ACTIONS.GET_RESOURCE_CATEGORY_FOLDER.fail:
		case ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.fail:
		case ACTIONS.DESTROY_RESOURCE_CATEGORY_FOLDER.fail:
		case ACTIONS.MOVE_RESOURCE_CATEGORY_FOLDER.fail:
		case ACTIONS.UPDATE_RESOURCE_CATEGORY_FOLDER.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				message: action.payload.message,
				status: action.status,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateFolderSuccess(response) {
	const { resourceCategoryID } = response.meta.object

	yield put(listFolders(resourceCategoryID))
	yield put(closeModal())

	toast.success('Folderul a fost adăugat cu succes!')
}

function* handleUpdateFolderSuccess(response) {
	const { resourceCategoryID } = response.meta.object

	yield put(listFolders(resourceCategoryID))
	yield put(closeModal())

	toast.success('Modificările au fost salvate cu succes!')
}

function* handleDestroyFolderSuccess(response) {
	const { resourceCategoryID } = response.meta.object

	yield put(listFolders(resourceCategoryID))
	yield put(closeModal())

	toast.success('Folderul a fost șters cu succes!')
}

function* handleMoveFolderSuccess(response) {
	const { resourceCategoryID } = response.meta.object

	yield put(listFolders(resourceCategoryID))
	yield put(closeModal())

	toast.success('Folderul a fost mutat cu succes!')
}

export function* saga() {
	yield takeEvery(ACTIONS.CREATE_RESOURCE_CATEGORY_FOLDER.success, handleCreateFolderSuccess)
	yield takeEvery(ACTIONS.UPDATE_RESOURCE_CATEGORY_FOLDER.success, handleUpdateFolderSuccess)
	yield takeEvery(ACTIONS.DESTROY_RESOURCE_CATEGORY_FOLDER.success, handleDestroyFolderSuccess)
	yield takeEvery(ACTIONS.MOVE_RESOURCE_CATEGORY_FOLDER.success, handleMoveFolderSuccess)
}

export const listBrandFolders = (brandID, params = {}) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.BRAND)
	const url = endpoint.expand({ brandID: brandID })
	console.log(url)
	return {
		type: ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'GET',
				params: params
			}
		},
		meta: {
			object: params
		}
	}
}

export const listFolders = (resourceCategoryID, params = {}) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.MAIN)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID })

	return {
		type: ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'GET',
				params: params
			}
		},
		meta: {
			object: params
		}
	}
}

export const listPersonalFolders = (params = {}) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.PERSONAL)
	const url = endpoint.expand({})
	return {
		type: ACTIONS.LIST_RESOURCE_CATEGORY_FOLDERS.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'GET',
				params: params
			}
		},
		meta: {
			object: params
		}
	}
}

export const createFolder = (resourceCategoryID, data) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.MAIN)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID })

	return {
		type: ACTIONS.CREATE_RESOURCE_CATEGORY_FOLDER.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'POST',
				data: data
			}
		},
		meta: {
			object: { resourceCategoryID, data }
		}
	}
}

export const getFolder = (resourceCategoryID, id) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.DESTROY)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID, folderID: id })

	return {
		type: ACTIONS.GET_RESOURCE_CATEGORY_FOLDER.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'GET'
			}
		},
		meta: {
			object: { resourceCategoryID, id }
		}
	}
}

export const updateFolder = (resourceCategoryID, id, data) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.DESTROY)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID, folderID: id })

	return {
		type: ACTIONS.UPDATE_RESOURCE_CATEGORY_FOLDER.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'POST',
				data: data
			}
		},
		meta: {
			object: { resourceCategoryID, data }
		}
	}
}

export const moveFolder = (resourceCategoryID, id, parentID) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.MOVE)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID, folderID: id })

	return {
		type: ACTIONS.MOVE_RESOURCE_CATEGORY_FOLDER.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'POST',
				data: {
					parent_id: parentID
				}
			}
		},
		meta: {
			object: { resourceCategoryID, data: { parent_id: parentID } }
		}
	}
}

export const destroyFolder = (resourceCategoryID, id) => {
	const endpoint = URITemplate(FOLDERS_ENDPOINTS.DESTROY)
	const url = endpoint.expand({ resourceCategoryID: resourceCategoryID, folderID: id })

	return {
		type: ACTIONS.DESTROY_RESOURCE_CATEGORY_FOLDER.main,
		payload: {
			request: {
				url: cleanURL(url),
				method: 'DELETE'
			}
		},
		meta: {
			object: { resourceCategoryID, id }
		}
	}
}
