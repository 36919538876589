import React, { useCallback, useState } from 'react'
import { CloudArrowUpIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'

import { isEmpty, head } from 'lodash'

import { Button } from '../Button'

import './ArticleImageDropzone.scss'

export default function ArticleImageDropzone({ onChangeFiles, initialImageUrl }) {
	const [files, setFiles] = useState([])

	const handleDrop = (acceptedFiles) => {
		onChangeFiles(acceptedFiles)

		setFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			)
		)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: onDrop,
		noClick: true,
		noKeyboard: true, 
	})

	return (
		<div {...getRootProps({ className: 'edy-optic-article-dropzone' })}>
			<input {...getInputProps()} />
			{!isEmpty(files) ? (
				<>
					<div className='edy-optic-article-dropzone-image-preview'>
						<img src={head(files).preview} alt='' className='preview-image' />
						<div className='texts-container'>
							<p className='preview-label'>Imagine Articol</p>
							<p className='image-file-name'>{head(files).name}</p>
						</div>
					</div>
					<Button icon={() => <TrashIcon />} color='red' size='small' onClick={() => onDrop([])} />
				</>
			) : initialImageUrl ? (
				<>
					<div className='edy-optic-article-dropzone-image-preview'>
						<img src={initialImageUrl} alt='' className='preview-image' />
					</div>
					<Button
						title='Schimbă imaginea'
						onClick={open}
						icon={() => <PencilSquareIcon />}
						iconLeft
						type='button'
					/>
				</>
			) : (
				<>
					<div className='article-dropzone-left'>
						<CloudArrowUpIcon className='upload-icon' />
						<div className='texts-container'>
							<p className='dropzone-label'>Imagine Articol</p>
							<p className='secondary-text'>JPG sau PNG, max 10MB</p>
						</div>
					</div>
					<Button title='Adaugă' onClick={open} size='small' icon={() => <PlusIcon />} iconLeft />
				</>
			)}
		</div>
	)
}
