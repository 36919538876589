import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { isEmpty, isNull, values as _values, orderBy, head, omitBy } from 'lodash'
import { addDays, sub } from 'date-fns'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { modalTypes, openModal } from '../../redux/modals'
import { createResourceAndWaitResponse } from '../../redux/resources'
import { listFolders } from '../../redux/folders'

import { brandsSidebarItems } from '../../utils'

import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { Toggle } from '../../components/Toggle'
import { DatePicker } from '../../components/DatePicker'
import { FoldersTree } from '../../components/FoldersTree'
import { AddFolderModal } from '../../components/AddFolderModal'
import { GroupAccessDate } from '../../components/GroupAccessDate'
import { InputError } from '../../components/ErrorComponents'
import { UploadingResourcesModal } from '../../components/UploadingResourcesModal'
import { Sidebar } from '../../components/Sidebar'
import { Loader } from '../../components/Loader'
import { PageHeader } from '../../components/PageHeader'
import { PageLoader } from '../../components/PageLoader'

import { CustomDropzone, UploadDropzone } from './partials'

import { validations } from '../../assets/validations'

import './AddResources.scss'

export class AddResources extends Component {
	constructor() {
		super()

		this.state = {
			newFolderSelected: false,
			selectedBrandID: null,
			selectedCategoryID: null,
			selectedFolderID: null,
			showInterestDates: false,
			filesStatuses: {},
			valuesToSend: {},
			typeOptions: [
				{value: 'general', label: 'General'},
				{value: 'catalog', label: 'Catalog produse'},
				{value: 'fisa-tehnica', label: 'Fisa tehnica produs'},
			]
		}

		this.myDropzone = React.createRef()
	}

	componentDidMount() {
		const { listBrands, listUserCategories } = this.props

		listBrands()
		listUserCategories()
	}

	componentDidUpdate = (prevProps) => {
		const { currentFolder } = this.props

		if (!isNull(currentFolder) && currentFolder !== prevProps.currentFolder) {
			this.foldersCallback('folderID', currentFolder.id)
			this.setState({ selectedFolderID: currentFolder.id, newFolderSelected: true })
		}
	}

	getPublishDate = () => {
		const { userCategories } = this.props

		if (!isEmpty(userCategories)) {
			const maxDaysBefore = head(userCategories).days_before_access

			return addDays(new Date(), maxDaysBefore)
		}

		return new Date()
	}

	setFoldersCallback = (setFieldValue) => {
		this.foldersCallback = setFieldValue
	}

	render() {
		const {
			resourcesErrors,
			brands,
			isLoadingBrands,
			resourceCategories,
			isLoadingResourceCategories,
			listResourceCategories,
			openAddBrandModal,
			folders,
			currentFolder,
			isLoadingFolders,
			listFolders,
			userCategories,
			isLoadingUserCategories,
			openUploadingResourcesModal
		} = this.props

		const {
			newFolderSelected,
			showInterestDates,
			selectedBrandID,
			selectedCategoryID,
			selectedFolderID,
			filesStatuses,
			valuesToSend,
			typeOptions
		} = this.state

		return (
			<div className='add-resource-page-container'>
				<Sidebar items={brandsSidebarItems} />
				<div className='add-resource-page-content-container'>
					<PageHeader pageTitle='Adaugă Resurse' parentRoute='/home' />
					{!isEmpty(brands) && !isLoadingBrands && !isLoadingUserCategories ? (
						<Formik
							initialValues={{
								brand: null,
								category: null,
								folderID: null,
								publishDate: this.getPublishDate(),
								publishDateDiff: true,
								interestStart: null,
								interestStop: null,
								files: [],
								site_published: false,
								type: '',
								type_object: null
							}}
							validationSchema={validations.resources}
							onSubmit={(values) => {
								this.setState((prevState) => {
									return { valuesToSend: { ...prevState.valuesToSend, ...values, showInterestDates } }
								})

								const length = values.files.length
								values.files.forEach((file, index) => {
									this.setState(
										(prevState) => {
											return {
												filesStatuses: {
													...prevState.filesStatuses,
													[file.name]: { isLoading: true, progress: 0 }
												}
											}
										},
										() => {
											if (index === length - 1) {
												openUploadingResourcesModal()
											}
										}
									)
								})
							}}
						>
							{({ setFieldValue, handleBlur, values, handleSubmit, errors, touched, isValid }) => (
								<Form className='add-resource-form'>
									<div className='add-resource-card'>
										<div className='add-resource-card-header-container'>
											<div className='header-left'>
												<div className='add-resource-card-number-container'>
													<p className='add-resource-card-number'>1</p>
												</div>
												<p className='add-resource-card-title'>Brand și Categorie</p>
											</div>
											{isLoadingBrands || isLoadingResourceCategories ? <Loader /> : null}
										</div>
										<div className='add-resource-card-content-container'>
											<div className='add-resource-card-content-row'>
												<Select
													label='Brand'
													placeholder='Selectează brand-ul'
													value={values.brand}
													options={brands}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													onChange={(option) => {
														setFieldValue('brand', option)
														setFieldValue('category', null)

														this.setState({ selectedBrandID: option.id })

														if (!isNull(option)) {
															listResourceCategories(option.id)
														}
													}}
													onBlur={handleBlur('brand')}
													name='brand'
													errors={resourcesErrors}
													frontendErrors={errors}
													touched={touched.brand}
													isClearable
													fullWidth
												/>
												<Select
													label='Categorie'
													placeholder='Selectează categoria'
													value={values.category}
													options={resourceCategories}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													onChange={(option) => {
														setFieldValue('category', option)

														this.setState({ selectedCategoryID: option.id })

														listFolders(option.id)
													}}
													onBlur={handleBlur('category')}
													disabled={isNull(values.brand) || isLoadingResourceCategories}
													name='category'
													errors={resourcesErrors}
													frontendErrors={errors}
													touched={touched.category}
													isClearable
													fullWidth
												/>
											</div>
										</div>
									</div>
									<div className='add-resource-card'>
										<div className='add-resource-card-header-container'>
											<div className='header-left'>
												<div className='add-resource-card-number-container'>
													<p className='add-resource-card-number'>2</p>
												</div>
												<p className='add-resource-card-title'>Folder și tip</p>
												{resourcesErrors && resourcesErrors['folder_id'] && (
													<InputError message={head(resourcesErrors['folder_id'])} />
												)}
											</div>
										</div>
										<div className='add-resource-card-content-container'>
											<div className='add-resource-card-content-row'>
												{!newFolderSelected && !isEmpty(folders) && !isNull(values.category) ? (
													<div className='flex flex-col'>
														<FoldersTree
															folders={folders}
															isLoading={isLoadingFolders}
															onSelect={(selectedFolderID) => {
																setFieldValue('folderID', selectedFolderID)
																this.setState({ selectedFolderID: selectedFolderID })
															}}
															onOpenAddFolderModal={() =>
																this.setFoldersCallback(setFieldValue)
															}
														/>
														<AddFolderModal selectedCategory={values.category} />
													</div>
												) : isEmpty(folders) || isNull(values.category) ? (
													<p className='select-category-text flex items-center'>Selectați o categorie</p>
												) : newFolderSelected ? (
													<div className='current-folder-container'>
														<p className='current-folder-text'>
															Folderul selectat: <span>"{currentFolder.name}"</span>
														</p>
														<Button
															title='Schimbă folderul'
															onClick={() => {
																setFieldValue('folderID', null)
																this.setState({
																	newFolderSelected: false,
																	selectedFolderID: null
																})
															}}
															size='small'
														/>
													</div>
												) : null}

												<Select	
													label='Tip resursa'
													placeholder='Selectează tip-ul'
													value={values.type_object}
													options={typeOptions}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.value}
													onChange={(option) => {
														setFieldValue('type_object', option)
														setFieldValue('type', option.value)
														console.log(option)
													}}
													onBlur={handleBlur('type')}
													name='type'
													errors={resourcesErrors}
													frontendErrors={errors}
													touched={touched.type}
													fullWidth
												/>
											</div>
										</div>
									</div>
									<div className='add-resource-card'>
										<div className='add-resource-card-header-container'>
											<div className='header-left'>
												<div className='add-resource-card-number-container'>
													<p className='add-resource-card-number'>3</p>
												</div>
												<p className='add-resource-card-title'>Date de Acces</p>
											</div>
										</div>
										<div className='add-resource-date-card-content-container'>
											<div className='left'>
												<DatePicker
													label='Dată postare'
													value={values.publishDate}
													onChange={(value) => setFieldValue('publishDate', new Date(value))}
													onBlur={handleBlur('publishDate')}
													name='publishDate'
													errors={resourcesErrors}
													frontendErrors={errors}
													touched={touched.publishDate}
												/>
												<Toggle
													checked={values.publishDateDiff}
													label='Dată access diferențiată'
													onChange={(value) => setFieldValue('publishDateDiff', value)}
												/>
											</div>
											<div className='right'>
												{!isEmpty(userCategories) &&
													userCategories.map((userCategory) => {
														const accessDate = sub(new Date(values.publishDate), {
															days: values.publishDateDiff
																? userCategory.days_before_access
																: 0
														})

														return (
															<GroupAccessDate
																label={`Dată acces ${userCategory.name}`}
																date={accessDate}
																key={userCategory.id}
															/>
														)
													})}
												<GroupAccessDate
													label='Dată acces toți clienții'
													date={values.publishDate}
												/>
											</div>
										</div>
									</div>
									<div className='add-resource-card'>
										<div className='add-resource-card-header-container'>
											<div className='header-left'>
												<div className='add-resource-card-number-container'>
													<p className='add-resource-card-number'>4</p>
												</div>
												<p className='add-resource-card-title'>Afișare</p>
											</div>
										</div>
										<div className='add-resource-card-content-container'>
											<div className='add-resource-card-content-row mb-4'>
												<Toggle
													label='Afișare pe site'
													checked={values.site_published}
													onChange={(value) => {
														setFieldValue('site_published', value)
													}}
												/>
											</div>
											<div className='add-resource-card-content-row'>
												<Toggle
													checked={showInterestDates}
													label='Interval de Afișare'
													onChange={(value) => {
														this.setState({ showInterestDates: value })
														setFieldValue('interestStart', null)
														setFieldValue('interestStop', null)
													}}
												/>
											</div>
											{showInterestDates ? (
												<div className='add-resource-card-content-row'>
													<DatePicker
														label='Start Afișare'
														value={values.interestStart}
														onChange={(value) =>
															setFieldValue(
																'interestStart',
																value ? new Date(value) : null
															)
														}
														onBlur={handleBlur('interestStart')}
														name='interestStart'
														errors={resourcesErrors}
														frontendErrors={errors}
														touched={touched.interestStart}
													/>
													<DatePicker
														label='Final Afișare'
														value={values.interestStop}
														onChange={(value) =>
															setFieldValue(
																'interestStop',
																value ? new Date(value) : null
															)
														}
														onBlur={handleBlur('interestStop')}
														name='interestStop'
														errors={resourcesErrors}
														frontendErrors={errors}
														touched={touched.interestStop}
													/>
												</div>
											) : (
												''
											)}
										</div>
									</div>
									<div className='add-resource-card'>
										<div className='add-resource-card-header-container'>
											<div className='header-left'>
												<div className='add-resource-card-number-container'>
													<p className='add-resource-card-number'>5</p>
												</div>
												<p className='add-resource-card-title'>Încărcare Fișiere</p>
											</div>
										</div>
										<div className='add-resource-card-content-container'>
											<CustomDropzone onChangeFiles={(files) => setFieldValue('files', files)} />
										</div>
									</div>
									<UploadingResourcesModal
										files={values.files}
										filesStatuses={filesStatuses}
										selectedBrandID={selectedBrandID}
										selectedCategoryID={selectedCategoryID}
										selectedFolderID={selectedFolderID}
									/>
									<UploadDropzone
										values={valuesToSend}
										filesStatuses={filesStatuses}
										setFileStatus={(fileName, status) => {
											this.setState((prevState) => {
												return {
													filesStatuses: { ...prevState.filesStatuses, [fileName]: status }
												}
											})
										}}
									/>
									<Button
										title='Salvează'
										onClick={handleSubmit}
										disabled={!isValid || isNull(values.folderID)}
										type='submit'
										size='extraLarge'
										fullWidth
									/>
								</Form>
							)}
						</Formik>
					) : isEmpty(brands) && !isLoadingBrands ? (
						<p className='no-brands-text'>
							Nu există niciun brand adăugat.{' '}
							<Link to='/brands' onClick={openAddBrandModal} className='add-brand-link'>
								Adaugă brand
							</Link>
						</p>
					) : isLoadingBrands || isLoadingUserCategories ? (
						<PageLoader />
					) : null}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const userCategories = orderBy(omitBy(state.userCategories.data, 'is_admin'), 'days_before_access', 'desc')

	return {
		resourcesErrors: state.resources.errors,
		resourcesResponses: state.resources.responses,
		brands: _values(state.brands.data),
		isLoadingBrands: state.brands.isLoading,
		userCategories: userCategories,
		isLoadingUserCategories: state.userCategories.isLoading,
		resourceCategories: _values(state.resourceCategories.data),
		isLoadingResourceCategories: state.resourceCategories.isLoading,
		folders: _values(state.folders.data),
		currentFolder: state.folders.currentFolder,
		isLoadingFolders: state.folders.isLoading
	}
}

const mapDispatchToProps = (dispatch) => ({
	listBrands: () => dispatch(RESOURCES.brands.list()),
	listUserCategories: () => dispatch(RESOURCES.userCategories.list()),
	listResourceCategories: (brandID) => dispatch(RESOURCES.resourceCategories.list({ brand_id: brandID })),
	listFolders: (resourceCategoryID) => dispatch(listFolders(resourceCategoryID)),
	createResources: (values) => dispatch(RESOURCES.resources.create(values)),
	openAddBrandModal: () => dispatch(openModal(modalTypes.ADD_BRAND)),
	openUploadingResourcesModal: () => dispatch(openModal(modalTypes.UPLOADING_RESOURCES)),
	createResourceAndWaitResponse: (resourceData, fileName) =>
		dispatch(createResourceAndWaitResponse(resourceData, fileName))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddResources)
