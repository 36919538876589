import React from 'react'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../../../redux/spec'
import { closeModal, modalTypes } from '../../../../redux/modals'

import { Modal } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'

import '../../../../assets/styles/DeleteModal.scss'

export const DeleteUserCategoryModal = ({ open, userCategory, deleteUser, closeModal }) => {
	return (
		<Modal open={open && !isEmpty(userCategory)} title='Șterge utilizator'>
			<p className='delete-modal-text'>
				Ești sigur că vrei să ștergi categoria de utilizatori <span>"{userCategory.name}"</span>?
				<br />
				<span>Toți utilizatorii acesteia vor fi șterse împreună cu ea!</span>
			</p>
			<div className='delete-modal-buttons-container'>
				<Button title='Nu, renunță' onClick={closeModal} color='secondary' />
				<Button title='Da, șterge' onClick={() => deleteUser(userCategory)} color='red' />
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.DELETE_USER_CATEGORY
})

const mapDispatchToProps = (dispatch) => ({
	deleteUser: (userCategory) => dispatch(RESOURCES.userCategories.destroy(userCategory)),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserCategoryModal)
