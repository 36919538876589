import React, { Component } from 'react'
import {
	ArrowLeftIcon,
	DocumentIcon,
	ArrowDownTrayIcon,
	PencilSquareIcon,
	TrashIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { isEmpty } from 'lodash'
import { lightFormat } from 'date-fns'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { closeModal, modalTypes, openModal } from '../../redux/modals'

import { Button } from '../../components/Button'
import { DeleteArticleModal } from '../../components/DeleteArticleModal'
import { ProductDetailsModal } from '../../components/ProductDetailsModal'
import { PageLoader } from '../../components/PageLoader'

import { ConnectedResourcesModal } from './partials'

import './BrandNewsArticle.scss'

export class BrandNewsArticle extends Component {
	constructor() {
		super()

		this.state = {
			selectedProduct: null
		}
	}

	componentDidMount = () => {
		const {
			retrieveArticle,
			match: { params }
		} = this.props

		const { articleID } = params

		retrieveArticle(articleID)
	}

	print = () => window.print()

	render() {
		const { selectedProduct } = this.state

		const {
			user,
			article,
			isLoadingNews,
			isLoadingProducts,
			openDeleteArticleModal,
			openViewResourcesModal,
			openProductDetailsModal,
			closeModal,
			match: { params }
		} = this.props

		const { brandID, articleID } = params

		return (
			<div className='article-page-container'>
				{!isEmpty(article) && !isLoadingNews ? (
					<>
						<div className='article-content-container'>
							<div className='article-container'>
								<div className='buttons-container'>
									<Link to={`/brands/${brandID}/news`}>
										<Button title='Înapoi' size='small' icon={() => <ArrowLeftIcon />} iconLeft />
									</Link>
									<div className='right-buttons-container'>
										{user.is_admin && (
											<>
												<Link to={`/brands/${brandID}/news/${articleID}/edit`}>
													<Button
														title='Editează'
														icon={() => <PencilSquareIcon />}
														iconLeft
														color='secondary'
														size='small'
														className='admin-button'
													/>
												</Link>
												<Button
													title='Șterge'
													onClick={openDeleteArticleModal}
													icon={() => <TrashIcon />}
													iconLeft
													color='red'
													size='small'
													className='admin-button'
												/>
												<DeleteArticleModal article={article} />
											</>
										)}
									</div>
								</div>
								<div className='article-info'>
									<div className='article-info-row'>
										<p className='article-date'>
											{lightFormat(new Date(article.created_at), 'dd/MM/yyyy HH:mm')}
										</p>
										<p className='article-brand'>{article.brand.name}</p>
									</div>
									<div className='article-title-row'>
										<p className='article-title'>{article.title}</p>
										{!isEmpty(article.resources) && (
											<div className='view-resources-button-container'>
												<Button
													title={`Vezi resurse (${article.resources.length})`}
													onClick={openViewResourcesModal}
													size='small'
													color='white'
													className='view-resources-button'
												/>
											</div>
										)}
									</div>
								</div>
								<ConnectedResourcesModal resources={article.resources} />
								<div
									className='article-content'
									dangerouslySetInnerHTML={{ __html: article.content }}
								/>
								<div className='article-image'>
									<img src={article.image_url} alt={article.title} />
								</div>
							</div>
							<div className='article-connected-resources'>
								<div className='article-connected-resources-card'>
									<p className='title-2xl px-6'>Resurse Conectate</p>
									{!isEmpty(article.resources) ? (
										<div className='connected-resources-list-container'>
											{article.resources.map((resource) => (
												<div className='resource-row' key={resource.id}>
													<a
														href={resource.preview_url}
														target='_blank'
														rel='noreferrer'
														className='resource-row-left'
													>
														<div className='resource-name-cotnainer'>
															<DocumentIcon className='resource-icon' />
															<p className='resource-name'>{resource?.name}</p>
														</div>
														<p className='resource-location'>{resource?.folder?.path}</p>
													</a>
													<a
														href={resource.preview_url}
														download
														target='_blank'
														rel='noreferrer'
													>
														<Button title='Vizualizează' size='small' />
													</a>
													<a
														href={resource.download_url}
														download
														target='_blank'
														rel='noreferrer'
													>
														<Button
															title='Descarcă'
															icon={() => <ArrowDownTrayIcon />}
															size='small'
														/>
													</a>
												</div>
											))}
										</div>
									) : (
										<p className='no-resources'>Fară resurse conectate</p>
									)}
								</div>
								<div className='article-connected-products-card'>
									<p className='title-2xl px-6'>Produse Conectate</p>
									{!isEmpty(article.products) ? (
										<div className='connected-products-list-container'>
											{article.products.map((product) => (
												<div className='product-row' key={product.id}>
													<div className='product-info-container'>
														<img
															src={product.image_url}
															alt={product.name}
															className='product-image'
														/>
														<div className='product-texts'>
															<p className='product-name'>{product?.name}</p>
															<p className='product-category'>
																{product?.category?.name}
															</p>
														</div>
													</div>
													<Button
														title='Vezi produs'
														onClick={() => {
															this.setState({ selectedProduct: product })
															openProductDetailsModal()
														}}
														loading={selectedProduct === product && isLoadingProducts}
														size='small'
													/>
												</div>
											))}
										</div>
									) : (
										<p className='no-products'>Fară resurse conectate</p>
									)}
								</div>
								<ProductDetailsModal
									productID={selectedProduct?.id || null}
									onClose={() => {
										closeModal()
										this.setState({ selectedProduct: null })
									}}
								/>
							</div>
						</div>
					</>
				) : isLoadingNews ? (
					<PageLoader />
				) : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.users.user,
	article: state.news.currentArticle,
	isLoadingNews: state.news.isLoading,
	isLoadingProducts: state.products.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(closeModal()),
	retrieveArticle: (articleID) => dispatch(RESOURCES.news.retrieve(articleID)),
	openDeleteArticleModal: () => dispatch(openModal(modalTypes.DELETE_ARTICLE)),
	openViewResourcesModal: () => dispatch(openModal(modalTypes.VIEW_CONNECTED_RESOURCES)),
	openProductDetailsModal: () => dispatch(openModal(modalTypes.PRODUCT_DETAILS))
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandNewsArticle)
