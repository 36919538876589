import { toast } from 'react-toastify'
import { push } from 'connected-react-router'
import { put, takeEvery } from 'redux-saga/effects'

import { keyBy, omit } from 'lodash'

import { RESOURCES } from './spec'
import { closeModal } from './modals'
import { getPages } from './utils'

const { brands: resource } = RESOURCES

const initialState = {
	pendingRequests: 0,
	errors: {},
	data: {},
	currentBrand: {},
	pages: {
		first: null,
		previous: null,
		current: null,
		next: null,
		last: null
	},
	isLoading: false
}

export function reducer(state = initialState, action = {}) {
	let currentPendingRequests
	switch (action.type) {
		case resource.actions.list.main:
		case resource.actions.create.main:
		case resource.actions.retrieve.main:
		case resource.actions.update.main:
		case resource.actions.destroy.main:
			currentPendingRequests = state.pendingRequests + 1

			return {
				...state,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.list.success:
			currentPendingRequests = state.pendingRequests - 1

			const pages = getPages(action.payload.meta)
			const resultsByKey = keyBy(action.payload.data, 'id')
			const data = pages.current === 1 ? resultsByKey : { ...state.data, ...resultsByKey }

			return {
				...state,
				data: data,
				pages: pages,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.success:
		case resource.actions.retrieve.success:
		case resource.actions.update.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				data: {
					...state.data,
					[action.payload.data.id]: action.payload.data
				},
				currentBrand: action.payload.data,
				errors: {},
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.destroy.success:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: {},
				data: omit(state.data, action.meta.object.id),
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		case resource.actions.create.fail:
		case resource.actions.retrieve.fail:
		case resource.actions.update.fail:
		case resource.actions.destroy.fail:
		case resource.actions.list.fail:
			currentPendingRequests = state.pendingRequests - 1

			return {
				...state,
				errors: action.payload.errors,
				pendingRequests: currentPendingRequests,
				isLoading: currentPendingRequests > 0
			}
		default:
			return state
	}
}

function* handleCreateBrandSuccess() {
	toast.success('Brandul a fost adăugat cu succes!')
	yield put(push('/brands'))
}

function* handleUpdateBrandSuccess() {
	yield put(closeModal())
	toast.success('Modificările au fost salvate!')
}

function* handleDestroyBrandSuccess() {
	yield put(closeModal())
	toast.success('Brandul a fost șters cu succes!')
}

export function* saga() {
	yield takeEvery(resource.actions.create.success, handleCreateBrandSuccess)
	yield takeEvery(resource.actions.update.success, handleUpdateBrandSuccess)
	yield takeEvery(resource.actions.destroy.success, handleDestroyBrandSuccess)
}
