import React from 'react'

import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { RESOURCES } from '../../redux/spec'
import { closeModal, modalTypes } from '../../redux/modals'

import { Modal } from '../Modal'
import { Button } from '../Button'

import '../../assets/styles/DeleteModal.scss'

export const DeleteArticleModal = ({ open, article, deleteArticle, closeModal }) => {
	return (
		<Modal open={open && !isEmpty(article)} title='Șterge articol'>
			<p className='delete-modal-text'>
				Ești sigur că vrei să ștergi articolul <span>"{article.title}"</span>?
			</p>
			<div className='delete-modal-buttons-container'>
				<Button title='Nu, renunță' onClick={closeModal} color='secondary' />
				<Button title='Da, șterge' onClick={() => deleteArticle(article)} color='red' />
			</div>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
	open: state.modals.type === modalTypes.DELETE_ARTICLE
})

const mapDispatchToProps = (dispatch) => ({
	deleteArticle: (article) => dispatch(RESOURCES.news.destroy(article)),
	closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteArticleModal)
